import ArrowUp from './arrow-up.svg';
import ArrowDown from './arrow-down.svg';
import CheckboxOn from './checkbox-on.svg';
import CheckboxOff from './checkbox-off.svg';
import CrossToClose from './cross-to-close.svg';
import DragAndDrop from './drag-n-drop.svg';
import Edit from './edit.svg';
import EyeOpen from './eye-open.svg';
import EyeClosed from './eye-closed.svg';
import Plus from './plus.svg';
import RouteArrow from './route-arrow.svg';
import ToggleOn from './toggle-on.svg';
import ToggleOff from './toggle-off.svg';
import Alert from './alert.svg';
import Info from './info.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import Activated from './activated.svg';
import Deactivated from './deactivated.svg';
import ArrowLeftWithoutCircle from './arrow-left-without-circle.svg';
import SquareWithPlus from './square-with-plus.svg';
import Calendar from './calendar.svg';
import Trash from './trash.svg';
import View from './view.svg';
import File from './file.svg';
import Attachment from './attachment.svg';

export default {
  ArrowUp,
  ArrowDown,
  CheckboxOn,
  CheckboxOff,
  CrossToClose,
  DragAndDrop,
  Edit,
  EyeOpen,
  EyeClosed,
  Plus,
  RouteArrow,
  ToggleOn,
  ToggleOff,
  Alert,
  Info,
  ArrowLeft,
  ArrowRight,
  Activated,
  Deactivated,
  ArrowLeftWithoutCircle,
  SquareWithPlus,
  Calendar,
  Trash,
  View,
  File,
  Attachment,
};
