import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { QueryType, PaginationResponseType } from '../../types/general';
import {
  getPhysicians,
  setPhysician,
  updatePhysician,
  deletePhysician,
} from '../../services/PhysiciansServices';
import { PhysicianType } from '../../types/physicians';
import store from '../index';

type InitialStateType<Examination> = {
  data: PaginationResponseType<Examination>;
  query: QueryType;
};

const initialState: InitialStateType<PhysicianType> = {
  data: {
    count: 0,
    results: [],
  },
  query: {
    pageNumber: 1,
    pageSize: 20,
  },
};

const physicians = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setTable(state, data: PaginationResponseType<PhysicianType>) {
      return {
        ...state,
        data,
      };
    },
    setQuery(state, query: QueryType) {
      return {
        ...state,
        query,
      };
    },
    setClearPhysicians() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async getPhysicians() {
      try {
        const { query } = store.getState().physicians;
        const response = await getPhysicians(query);

        dispatch.physicians.setTable(response.data);
      } catch (error) {
        throw error;
      }
    },
    async setPhysician(data: PhysicianType) {
      try {
        const response = await setPhysician(data);

        dispatch.physicians.getPhysicians();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updatePhysician(data: PhysicianType) {
      try {
        const response = await updatePhysician(data);

        dispatch.physicians.getPhysicians();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async deletePhysician(id: number) {
      try {
        await deletePhysician(id);

        dispatch.physicians.getPhysicians();
      } catch (error) {
        throw error;
      }
    },
  }),
});

export default physicians;
