import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ProfilePatchType, ProfileType, UserType } from '../../types/user';
import {
  logIn,
  checkAuth,
  LogInType,
  setAccessToken,
  setRefreshToken,
  removeTokens,
  changePassword,
  ChangePasswordType,
} from '../../services/AuthServices';
import {
  getUsers,
  getCurrentUser,
  getUser,
  setUser,
  updateUser,
  updateCurrentUser,
  deleteUser,
} from '../../services/UserServices';
import store from '../index';
import { QueryType } from '../../types/general';

type InitialStateType = {
  data: UserType | null;
};

const initialState: InitialStateType = {
  data: null,
};

const user = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser(state, data: UserType) {
      return {
        ...state,
        data,
      };
    },
  },
  effects: (dispatch) => ({
    async LogIn(data: LogInType) {
      try {
        const response = await logIn(data);
        setAccessToken(response.data.access);
        setRefreshToken(response.data.refresh);
        dispatch.user.getCurrentUser();
      } catch (error) {
        throw error;
      }
    },
    async LogOut() {
      removeTokens();
    },
    async checkAuth() {
      try {
        const response = await checkAuth();
        setAccessToken(response.data.access);
      } catch (error) {
        removeTokens();
      }
    },
    async changePassword(data: ChangePasswordType) {
      try {
        await changePassword(data);
        dispatch.user.getCurrentUser();
      } catch (error) {
        throw error;
      }
    },
    async getUsers(query: QueryType) {
      try {
        const response = await getUsers(query);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getCurrentUser() {
      try {
        const response = await getCurrentUser();
        dispatch.user.setUser(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getUser(id: number) {
      try {
        const response = await getUser(id);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async addUser(profile: ProfileType) {
      try {
        const response = await setUser(profile);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateUser({ id, profile }: { id: number; profile: ProfilePatchType }) {
      try {
        const response = await updateUser(id, profile);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateCurrentUser(profile: ProfilePatchType) {
      try {
        const { data } = store.getState().user;
        const response = await updateCurrentUser(profile);

        const updatedData = {
          ...data,
          ...response.data,
        };

        dispatch.user.setUser(updatedData);
      } catch (error) {
        throw error;
      }
    },
    async deleteUser(id: number) {
      try {
        await deleteUser(id);
      } catch (error) {
        throw error;
      }
    },
  }),
});

export default user;
