import React, { FC } from 'react';
import { Global, css } from '@emotion/react';

const generalStyles = css`
  * {
    box-sizing: border-box;
  }

  html,
  button,
  input,
  textarea {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
  }

  body {
    margin: 0;
  }
`;

const GeneralVariables: FC = () => (
  <>
    <Global styles={generalStyles} />
  </>
);

export default GeneralVariables;
