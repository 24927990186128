import React, { FC, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Icon } from '../atoms';
import Icons from '../../assets/icons';
import { useOnClickOutside } from '../../hooks';

type Props = {
  isActivated: boolean;
  setStatusTable: (arg: boolean) => void;
  className?: string;
};

const DropdownStatus: FC<Props> = ({ className, isActivated, setStatusTable }) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));
  const [isOpen, setIsOpen] = useState(false);

  const statusTableHandler = (status: boolean) => {
    setStatusTable(status);
    setIsOpen(false);
  };

  return (
    <Wrapper className={className} ref={ref}>
      <Field isActivated={isActivated} onClick={() => setIsOpen(!isOpen)}>
        {isActivated ? 'Activated' : 'Deactivated'}

        <Arrow src={isOpen ? Icons.ArrowUp : Icons.ArrowDown} />
      </Field>

      {isOpen && (
        <Dropdown>
          <Option onClick={() => statusTableHandler(true)}>Activate</Option>
          <Option onClick={() => statusTableHandler(false)}>Deactivate</Option>
        </Dropdown>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Field = styled.div<WrapperType>`
  display: flex;
  color: ${({ isActivated }) => (isActivated ? 'var(--color-green)' : 'var(--color-error)')};
  cursor: pointer;

  svg path {
    ${({ isActivated }) =>
      isActivated ? 'stroke: var(--color-green);' : 'stroke: var(--color-error);'}
  }
`;

const Dropdown = styled.div`
  position: absolute;
  max-height: 158px;

  background-color: var(--color-white);
  border: 1px solid var(--color-lines);

  overflow: auto;
  z-index: 2;
`;

const Option = styled.div`
  padding: 6px 12px;
  cursor: pointer;
`;

const Arrow = styled(Icon)`
  margin-left: 7px;
`;

type WrapperType = {
  isActivated: boolean;
};

export default DropdownStatus;
