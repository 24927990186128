import React, { FC, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks';
import { Dispatch, RootState } from '../../store';
import { PageWrapper, Table, Pagination, ViewMode as ViewModeBase } from '../features';
import { RouteBlock, Title, Button, Icon, Header } from '../atoms';
import { url } from '../../router/routes';
import Icons from '../../assets/icons';
import { PatientType, ProcedureDataType, FileType } from '../../types/patients';
import { ModalWindowConfirm, ModalWindow, Select, Loader } from '../molecules';
import { ModalAddProcedureData, ModalPatientTable } from '../organisms';
import { convertToListOfStringsFromExamination } from '../organisms/ModalPatientTable';
import { ErrorType, PaginationResponseType, QueryType } from '../../types/general';
import { getNameByUrl } from '../molecules/ServerFiles';
import { convertListToString } from '../../utils';
import Notification, { notify } from '../molecules/Notification';
import { userRoles } from './Profile';
import { viewModeModals } from '../features/ViewMode';

const patientActions = {
  DATE_OF_BIRTH: 'bornAt',
  GENDER: 'gender',
  COMMENTS: 'comments',
  ARTERIAL_HYPERTENSION: 'arterialHypertension',
  DIABETES: 'diabetes',
  PREVIOUS_STROKE: 'previousStroke',
  CABG: 'coronaryArteryBypassGraft',
  EF: 'ef',
  LA_DIAMETER: 'laDiameter',
  LA_AREA: 'laArea',
  VASCULAR_DISEASE: 'vascularDisease',
  BETABLOCKER: 'betablocker',
  MARCUMAR: 'marcumar',
  ASPIRIN: 'aspirin',
  CAD: 'coronaryArteryDiseaseValue',
  MITRAL_REGURITATION: 'mitralReguritationValue',
  ANTIARRHYTMIC_DRUGS: 'antiarrhytmicDrugsValue',
  CREATE_TIME: 'createTime',
  CREATE_USER: 'createUser',
  FULL_NAME: 'fullName',
  ACTIONS: 'actions',
};

const procedureActions = {
  PROCEDURE_TYPE: 'type',
  DATE: 'date',
  PHYSICIAN: 'physician',
  CHANGE_DATE: 'changeCreateTime',
  CHANGE_USER: 'changeUser',
  CREATE_DATE: 'createTime',
  CREATE_USER: 'createUser',
  FILES: 'files',
  ACTIONS: 'actions',
};

const modals = {
  DELETE_PATIENT: 'deletePatient',
  EDIT_PATIENT: 'editPatient',
  ADD_PROCEDURE_TYPE: 'addProcedureType',
  ADD_PROCEDURE_DATA: 'addProcedureData',
  EDIT_PROCEDURE_DATA: 'editProcedureData',
  DELITE_PROCEDURE_DATA: 'deliteProcedureData',
};

const Patient: FC = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch<Dispatch>();
  const studies = useSelector((state: RootState) => state.studies.data.results);
  const patients = useSelector((state: RootState) => state.patients);
  const procedureTypes = useSelector((state: RootState) => state.procedures.data.results);
  const onlyActiveProcedureTypes = procedureTypes.filter((procedure) => procedure.isActive);

  const user = useSelector((state: RootState) => state.user.data?.group);
  const editor = user === userRoles.editor;
  const admin = user === userRoles.admin;

  const patientId = Number(query.get('id'));
  const pageNumber = Number(query.get('page')) || 1;

  const [activeModal, setActiveModal] = useState('');
  const [activePageMasterData, setActivePageMasterData] = useState(1);
  const [loading, setLoading] = useState(false);

  const [masterDataIsOpen, setMasterDataIsOpen] = useState(true);
  const [activeViewModal, setActiveViewModal] = useState('');

  const [files, setFiles] = useState<FileType[]>([]);
  const [masterData, setMasterData] = useState<PatientType | null>(null);
  const [procedures, setProcedures] = useState<ProcedureDataType[]>([]);
  const [procedureType, setProcedureType] = useState('');
  const [procedureTypeId, setProcedureTypeId] = useState<number | null>(null);
  const [procedureId, setProcedureId] = useState<number | null>(null);
  const [procedureInfo, setProcedureInfo] = useState<ProcedureDataType | null>(null);
  const [errors, setErrors] = useState<ErrorType | null>(null);

  const [paginationData, setPaginationData] = useState<PaginationResponseType<ProcedureDataType>>({
    count: 0,
    results: [],
  });
  const [queryData, setQueryData] = useState({
    pageSize: 20,
    pageNumber,
  });

  const proceduresWithFilename = procedures.map((field) => {
    const listOfFilesName = field.files.map((file: FileType) => getNameByUrl(file.file)) || [''];

    return {
      ...field,
      files: convertListToString(listOfFilesName),
    };
  });

  const patientData = useMemo(() => (masterData ? [masterData] : []), [masterData]);
  const patientColumnsPage1 = useMemo(
    () => [
      {
        Header: 'Date of birth',
        accessor: patientActions.DATE_OF_BIRTH,
      },
      {
        Header: 'Gender',
        accessor: patientActions.GENDER,
      },
      {
        Header: 'Comments',
        accessor: patientActions.COMMENTS,
      },
      {
        Header: 'Patient Name',
        accessor: patientActions.FULL_NAME,
      },
      {
        Header: 'Arterial Hypertension',
        accessor: patientActions.ARTERIAL_HYPERTENSION,
      },
      {
        Header: 'Diabetes',
        accessor: patientActions.DIABETES,
      },
      {
        Header: 'Previous Stroke',
        accessor: patientActions.PREVIOUS_STROKE,
      },
      {
        Header: 'Coronary Artery Disease',
        accessor: patientActions.CAD,
      },
      {
        Header: 'Coronary Artery Bypass Graft',
        accessor: patientActions.CABG,
      },
      {
        Header: 'Actions',
        accessor: patientActions.ACTIONS,
      },
    ],
    []
  );
  const patientColumnsPage2 = useMemo(
    () => [
      {
        Header: 'Ef',
        accessor: patientActions.EF,
      },
      {
        Header: 'La Diameter',
        accessor: patientActions.LA_DIAMETER,
      },
      {
        Header: 'La Area',
        accessor: patientActions.LA_AREA,
      },
      {
        Header: 'Mitral Reguritation',
        accessor: patientActions.MITRAL_REGURITATION,
      },
      {
        Header: 'Vascular disease',
        accessor: patientActions.VASCULAR_DISEASE,
      },
      {
        Header: 'Betablocker',
        accessor: patientActions.BETABLOCKER,
      },
      {
        Header: 'Antiarrhythmic drugs',
        accessor: patientActions.ANTIARRHYTMIC_DRUGS,
      },
      {
        Header: 'Marcumar',
        accessor: patientActions.MARCUMAR,
      },
      {
        Header: 'Actions',
        accessor: patientActions.ACTIONS,
      },
    ],
    []
  );
  const patientColumnsPage3 = useMemo(
    () => [
      {
        Header: 'Mitral Reguritation',
        accessor: patientActions.MITRAL_REGURITATION,
      },
      {
        Header: 'Vascular disease',
        accessor: patientActions.VASCULAR_DISEASE,
      },
      {
        Header: 'Betablocker',
        accessor: patientActions.BETABLOCKER,
      },
      {
        Header: 'Antiarrhythmic drugs',
        accessor: patientActions.ANTIARRHYTMIC_DRUGS,
      },
      {
        Header: 'Marcumar',
        accessor: patientActions.MARCUMAR,
      },
      {
        Header: 'Aspirin',
        accessor: patientActions.ASPIRIN,
      },
      {
        Header: 'Create Date',
        accessor: patientActions.CREATE_TIME,
      },
      {
        Header: 'Create User',
        accessor: patientActions.CREATE_USER,
      },
      {
        Header: 'Actions',
        accessor: patientActions.ACTIONS,
      },
    ],
    []
  );

  const procedureData = useMemo(() => proceduresWithFilename, [proceduresWithFilename]);
  const procedureColumns = useMemo(
    () => [
      {
        Header: 'Procedure type',
        accessor: procedureActions.PROCEDURE_TYPE,
      },
      {
        Header: 'Procedure date',
        accessor: procedureActions.DATE,
      },
      {
        Header: 'Physician',
        accessor: procedureActions.PHYSICIAN,
      },
      {
        Header: 'Change date',
        accessor: procedureActions.CHANGE_DATE,
      },
      {
        Header: 'Change user',
        accessor: procedureActions.CHANGE_USER,
      },
      {
        Header: 'Create date',
        accessor: procedureActions.CREATE_DATE,
      },
      {
        Header: 'Create user',
        accessor: procedureActions.CREATE_USER,
      },
      {
        Header: 'Files',
        accessor: procedureActions.FILES,
      },
      {
        Header: 'Actions',
        accessor: procedureActions.ACTIONS,
      },
    ],
    []
  );

  const masterDataPage = () => {
    if (activePageMasterData === 1) {
      return patientColumnsPage1;
    }
    if (activePageMasterData === 2) {
      return patientColumnsPage2;
    }
    if (activePageMasterData === 3) {
      return patientColumnsPage3;
    }
    return patientColumnsPage1;
  };

  const handleModalProcedureType = () => {
    if (procedureType.trim() === '') {
      const errorDescription = 'Please fill all mandatory fields';

      setErrors({ procedureType: [errorDescription] });
      return;
    }

    delete errors?.procedureType;
    setActiveModal(modals.ADD_PROCEDURE_DATA);
  };

  const handleModalDelete = () => {
    if (patientId) {
      dispatch.patients.deletePatient(patientId).then(() => dispatch.patients.getTable());
      history.push(`${url.PATIENTS}/?page=${patients.query.pageNumber}`);
    }
  };

  const handleViewButton = (name: string) => (id: number) => {
    setProcedureInfo(null);
    setProcedureId(null);
    setFiles([]);

    if (name === viewModeModals.PATIENT) {
      const modalToOpen = activeViewModal === viewModeModals.PATIENT ? '' : viewModeModals.PATIENT;

      setActiveViewModal(modalToOpen);
    }

    if (name === viewModeModals.PROCEDURE) {
      const idsAreEquil = procedureId === id;

      const modalToOpen =
        activeViewModal === viewModeModals.PROCEDURE && idsAreEquil ? '' : viewModeModals.PROCEDURE;

      if (!idsAreEquil) {
        getProcedure(id);
      }

      setProcedureId(id);
      setActiveViewModal(modalToOpen);
    }

    if (name === viewModeModals.PICTURES) {
      const findedProcedure = procedures.find((procedure) => procedure?.files[0]?.procedure === id);

      const modalToOpen =
        findedProcedure?.files[0]?.procedure === files[0]?.procedure ? '' : viewModeModals.PICTURES;

      const filesToLoad = modalToOpen ? findedProcedure?.files : [];

      if (filesToLoad) {
        setFiles(filesToLoad);
        setActiveViewModal(modalToOpen);
      }
    }
  };

  const handleCloseModal = () => {
    setActiveModal('');
    setProcedureType('');
    setProcedureId(null);
    setProcedureTypeId(null);
    setErrors(null);
  };

  const getProcedureTypeById = (id: number) => {
    const findedName = procedures.find((item) => item.id === id)?.[procedureActions.PROCEDURE_TYPE];

    if (findedName) {
      dispatch.examination.getExaminationByName(findedName).then((res) => {
        const findedId = res.results[0].id;

        if (findedId) {
          setProcedureTypeId(findedId);
        }
      });
    }
  };

  const handleAction = (action: string) => (id: number) => {
    if (action === 'edit') {
      setProcedureId(id);
      getProcedureTypeById(id);
      setActiveModal(modals.EDIT_PROCEDURE_DATA);
    } else if (action === 'delete') {
      setProcedureId(id);
      getProcedureTypeById(id);
      setActiveModal(modals.DELITE_PROCEDURE_DATA);
    }
  };

  const handleDeleteProcedureData = () => {
    if (procedureId && procedureTypeId) {
      dispatch.patients
        .deleteProcedureData({
          procedureTypeId,
          procedureId,
        })
        .then(() => getProcedures(queryData));
    }
  };

  const handleSelectProcedureType = (name: string) => {
    const id = onlyActiveProcedureTypes.find((procedure) => procedure.name === name)?.id;

    if (id) {
      setProcedureTypeId(id);
      setProcedureType(name);
    }
  };

  const handleClickMasterDataArrow = (page: number) => {
    if (page > 0 && page <= 3) {
      setActivePageMasterData(page);
    }
  };

  const handlePageClick = (selectedPage: number) => {
    history.push(`${url.PATIENT}/?page=${selectedPage}&id=${patientId}`);

    const updatedQuery = {
      ...queryData,
      pageNumber: selectedPage,
    };

    setQueryData(updatedQuery);
    getProcedures(updatedQuery);
  };

  const getProcedure = (id: number) => {
    const findedId = procedures.find((item) => item.id === id)?.[procedureActions.PROCEDURE_TYPE];

    if (findedId) {
      dispatch.examination.getExaminationByName(findedId).then((res) => {
        const findedProcedureTypeId = res.results[0].id;

        if (findedProcedureTypeId) {
          dispatch.patients
            .getProcedureData({
              procedureId: id,
              procedureTypeId: findedProcedureTypeId,
            })
            .then((fields) => {
              delete fields.patient;
              delete fields.date;
              delete fields.id;

              dispatch.patients.getSelections('physician').then((physician) => {
                const data = {
                  ...fields,
                  physician: physician.results.find((item) => item.id === fields.physician)?.value,
                  studies: studies
                    .filter((study) => fields.studies.includes(study.id))
                    .reduce((acc, study) => `${acc}, ${study.name}`, '')
                    .slice(2),
                };

                setProcedureInfo(data);
              });
            });
        }
      });
    }
  };

  const getProcedures = (pageQuery: QueryType) => {
    if (procedures.length === 0) {
      setLoading(true);
    }

    const dataForSending = {
      patientId,
      query: pageQuery,
    };

    dispatch.patients
      .getAllProceduresForPatient(dataForSending)
      .then((response) => {
        setPaginationData(response);
        setProcedures(response.results);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);

    dispatch.patients
      .getPatient(patientId)
      .then((response) =>
        setMasterData({ ...response, fullName: `${response.firstName} ${response.name}` })
      )
      .finally(() => setLoading(false));

    getProcedures(queryData);
  }, []);

  return (
    <PageWrapper>
      <RouteBlock
        previousURL={url.PATIENTS}
        previousQuery={patients.query}
        previous="Patients"
        current={`Patient ${patientId}`}
      />

      <Header>
        <Title>{`Patient ${patientId}`}</Title>

        {(admin || editor) && (
          <Block>
            <ButtonAdd onClick={() => setActiveModal(modals.ADD_PROCEDURE_TYPE)}>
              Add procedure data
            </ButtonAdd>

            {admin && (
              <ButtonDelete onClick={() => setActiveModal(modals.DELETE_PATIENT)}>
                Delete patient
              </ButtonDelete>
            )}
          </Block>
        )}
      </Header>

      <MainInformationAndViewMode>
        <Block>
          <MasterData isOpen={masterDataIsOpen}>
            <MasterDataTitle onClick={() => setMasterDataIsOpen(!masterDataIsOpen)}>
              Master data
              <MasterDataIcon src={masterDataIsOpen ? Icons.ArrowUp : Icons.ArrowDown} />
            </MasterDataTitle>

            <ArrowsBlock isOpen={masterDataIsOpen}>
              <ArrowLeft
                pageIsOpen={activePageMasterData === 1}
                onClick={() => handleClickMasterDataArrow(activePageMasterData - 1)}
                src={Icons.ArrowLeft}
              />

              <ArrowRight
                pageIsOpen={activePageMasterData === 3}
                onClick={() => handleClickMasterDataArrow(activePageMasterData + 1)}
                src={Icons.ArrowRight}
              />
            </ArrowsBlock>
          </MasterData>

          {loading ? (
            <Loader />
          ) : (
            <>
              {masterDataIsOpen && (
                <Table
                  data={patientData}
                  columns={masterDataPage()}
                  handleEdit={() => setActiveModal(modals.EDIT_PATIENT)}
                  handleDelete={() => setActiveModal(modals.DELETE_PATIENT)}
                  handleViewButton={handleViewButton(viewModeModals.PATIENT)}
                />
              )}

              {procedures.length > 0 && (
                <ProcedureTable
                  data={procedureData}
                  columns={procedureColumns}
                  handleEdit={handleAction('edit')}
                  handleDelete={handleAction('delete')}
                  handleViewButton={handleViewButton(viewModeModals.PROCEDURE)}
                  handleAttachedFiles={handleViewButton(viewModeModals.PICTURES)}
                />
              )}

              <Pagination
                dataTable={paginationData}
                pageSize={queryData.pageSize}
                activePage={queryData.pageNumber}
                onPageChange={handlePageClick}
              />
            </>
          )}
        </Block>

        {activeViewModal && (
          <ViewMode
            title={activeViewModal === viewModeModals.PICTURES ? 'Pictures' : 'Data'}
            modal={activeViewModal}
            masterData={masterData}
            procedure={procedureInfo}
            pictures={files}
          />
        )}
      </MainInformationAndViewMode>

      <ModalWindow
        title="Add procedure data"
        modalIsOpen={activeModal === modals.ADD_PROCEDURE_TYPE}
        closeModal={handleCloseModal}
        saveInfo={handleModalProcedureType}
        error={errors?.procedureType}
        buttonTitle="Next"
      >
        <CenteringWrapper>
          <Content>
            <Select
              value={procedureType}
              onChange={handleSelectProcedureType}
              options={convertToListOfStringsFromExamination(onlyActiveProcedureTypes)}
              placeholder="Procedure type"
              errorBorder={!!errors?.procedureType}
              additionalInfo="The type of procedure that is performed"
              notAbsoluteDropdown
              required
            />
          </Content>
        </CenteringWrapper>
      </ModalWindow>

      <ModalPatientTable
        title="Edit patient"
        modalIsOpen={activeModal === modals.EDIT_PATIENT}
        closeModal={handleCloseModal}
        selectedPatient={masterData}
        setSelectedPatient={setMasterData}
        notify={notify}
        updateSelectedPatient
      />

      <ModalAddProcedureData
        title="Add procedure data"
        modalIsOpen={activeModal === modals.ADD_PROCEDURE_DATA}
        procedureTypeId={procedureTypeId}
        closeModal={handleCloseModal}
        updateList={() => getProcedures(queryData)}
        patientId={patientId}
        goBack={() => setActiveModal(modals.ADD_PROCEDURE_TYPE)}
      />

      <ModalAddProcedureData
        title="Edit procedure data"
        modalIsOpen={activeModal === modals.EDIT_PROCEDURE_DATA}
        closeModal={handleCloseModal}
        updateList={() => getProcedures(queryData)}
        procedureId={procedureId}
        procedureTypeId={procedureTypeId}
        patientId={patientId}
        editMode
      />

      <ModalWindowConfirm
        title="Delete procedure"
        modalIsOpen={activeModal === modals.DELITE_PROCEDURE_DATA}
        closeModal={handleCloseModal}
        saveInfo={handleDeleteProcedureData}
      >
        Are you sure you want to delete these procedure data?
      </ModalWindowConfirm>

      <ModalWindowConfirm
        title="Delete patient"
        modalIsOpen={activeModal === modals.DELETE_PATIENT}
        closeModal={handleCloseModal}
        saveInfo={handleModalDelete}
      >
        Are you sure you want to delete these patient data and all related procedure data
      </ModalWindowConfirm>

      <Notification />
    </PageWrapper>
  );
};

const ViewMode = styled(ViewModeBase)`
  margin-top: 48px;
  margin-left: 20px;
`;

const MainInformationAndViewMode = styled.div`
  display: flex;
`;

const Block = styled.div``;

const ButtonAdd = styled(Button)`
  width: 170px;
`;

const ButtonDelete = styled(Button)`
  margin-left: 16px;
  width: 137px;
`;

const MasterData = styled.div<MasterDataIsOpenType>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 16px;

  ${({ isOpen }) =>
    !isOpen &&
    `
    margin-bottom: 0;
  `}
`;

const ProcedureTable = styled(Table)`
  margin-top: 80px;
`;

const MasterDataTitle = styled.div`
  display: flex;
  color: var(--color-grey);
  cursor: pointer;
`;

const MasterDataIcon = styled(Icon)`
  margin-left: 7px;
`;

const ArrowsBlock = styled.div<MasterDataIsOpenType>`
  display: flex;

  ${({ isOpen }) =>
    !isOpen &&
    `
    display: none;
  `}
`;

const ArrowLeft = styled(Icon)<ArrowType>`
  ${({ pageIsOpen }) =>
    pageIsOpen &&
    `
    opacity: 0.5;
  `}
`;

const ArrowRight = styled(Icon)<ArrowType>`
  margin-left: 12px;

  ${({ pageIsOpen }) =>
    pageIsOpen &&
    `
    opacity: 0.5;
  `}
`;

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 340px;
`;

type MasterDataIsOpenType = {
  isOpen: boolean;
};

type ArrowType = {
  pageIsOpen: boolean;
};

export default Patient;
