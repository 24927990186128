import React, { FC } from 'react';
import styled from '@emotion/styled';

type Props = {
  error: string[];
  className?: string;
};

const Error: FC<Props> = ({ className, error }) => {
  return (
    <Wrapper className={className}>
      {error.map((name) => (
        <ErrorName key={name}>{name}</ErrorName>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export const ErrorName = styled.div`
  font-size: 10px;
  color: var(--color-error);
`;

export default Error;
