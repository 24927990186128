import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { StudyType, StudyRequestType } from '../../types/studies';
import { QueryType, PaginationResponseType } from '../../types/general';
import {
  getStudies,
  getStudy,
  setStudy,
  updateStudy,
  deleteStudy,
  getProcedures,
} from '../../services/StudiesServices';
import store from '../index';

type InitialStateType<Studies> = {
  data: PaginationResponseType<Studies>;
  query: QueryType;
};

const initialState: InitialStateType<StudyType> = {
  data: {
    count: 0,
    results: [],
  },
  query: {
    pageNumber: 1,
    pageSize: 20,
  },
};

const studies = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setTable(state, data: PaginationResponseType<StudyType>) {
      return {
        ...state,
        data,
      };
    },
    setQuery(state, query: QueryType) {
      return {
        ...state,
        query,
      };
    },
    setClearStudies() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async getTable() {
      try {
        const { query } = store.getState().studies;
        const response = await getStudies(query);

        dispatch.studies.setTable(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getStudies(query: QueryType) {
      try {
        const response = await getStudies(query);

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getStudy(id: number) {
      try {
        const response = await getStudy(id);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async addStudy(data: StudyRequestType) {
      try {
        const response = await setStudy(data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateStudy({ id, data }: { id: number; data: StudyRequestType }) {
      try {
        const response = await updateStudy(id, data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async deleteStudy(id: number) {
      try {
        await deleteStudy(id);
      } catch (error) {
        throw error;
      }
    },
    async getProcedures(data: { id: number; pageNumber?: number; pageSize?: number }) {
      try {
        const response = await getProcedures(data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  }),
});

export default studies;
