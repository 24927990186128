import { AxiosResponse } from 'axios';
import { UserType, ProfileType, ProfilePatchType } from '../types/user';
import api from '../api/api';
import { PaginationResponseType, QueryType } from '../types/general';

export const getUsers = (
  query: QueryType
): Promise<AxiosResponse<PaginationResponseType<UserType>>> => {
  return api.get<PaginationResponseType<UserType>>(
    `/users/?page=${query.pageNumber}&page_size=${query.pageSize}`
  );
};

export const getCurrentUser = (): Promise<AxiosResponse<UserType>> => {
  return api.get<UserType>('/users/me/');
};

export const getUser = (id: number): Promise<AxiosResponse<UserType>> => {
  return api.get<UserType>(`/users/${id}/`);
};

export const setUser = (profile: ProfileType): Promise<AxiosResponse<UserType>> => {
  return api.post<UserType>(`/users/`, profile);
};

export const updateUser = (
  id: number,
  profile: ProfilePatchType
): Promise<AxiosResponse<UserType>> => {
  return api.patch<UserType>(`/users/${id}/`, profile);
};

export const updateCurrentUser = (profile: ProfilePatchType): Promise<AxiosResponse<UserType>> => {
  return api.patch<UserType>(`/users/me/update_profile/`, profile);
};

export const deleteUser = (id: number): Promise<void> => {
  return api.delete(`/users/${id}/`);
};
