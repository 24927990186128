import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { Field, Error as ErrorBase, InfoBlock } from '../atoms';
import Icons from '../../assets/icons';

type Props = {
  value: string;
  onChange: (arg: string) => void;
  errorBorder?: boolean;
  error?: string[] | null;
  additionalInfo?: string;
  readOnly?: boolean;
  required?: boolean;
  password?: boolean;
  type?: string;
  placeholder?: string;
  className?: string;
};

const Input: FC<Props> = ({
  className,
  value,
  errorBorder,
  error,
  additionalInfo,
  readOnly,
  required,
  password,
  placeholder,
  onChange,
  type = 'text',
}) => {
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const isFilled = !!value;

  return (
    <Wrapper className={className} error={!!error || !!errorBorder}>
      <Placeholder isFilled={isFilled}>
        {placeholder}
        {required && <Star> *</Star>}
      </Placeholder>

      {password ? (
        <InputPasswordWrapper>
          <InputField
            value={value}
            password={password}
            onChange={(e) => onChange(e.target.value)}
            type={isEyeOpen ? 'text' : 'password'}
          />

          <Eye
            src={isEyeOpen ? Icons.EyeOpen : Icons.EyeClosed}
            onClick={() => setIsEyeOpen(!isEyeOpen)}
          />
        </InputPasswordWrapper>
      ) : (
        <>
          <InputField
            value={value}
            readOnly={readOnly}
            onChange={(e) => onChange(e.target.value)}
            type={type}
          />

          {additionalInfo && <InfoBlock info={additionalInfo} />}
        </>
      )}

      {error && <Error error={error} />}
    </Wrapper>
  );
};

const Wrapper = styled(Field)<WrapperType>`
  position: relative;
  flex-direction: column;
  justify-content: center;

  ${({ error }) =>
    error &&
    `
    border: 1px solid var(--color-error);
  `}
`;

const InputPasswordWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputField = styled.input<InputFieldType>`
  padding: 0;
  height: 20px;
  line-height: 20px;

  color: var(--color-dark);
  background-color: var(--color-light);

  border: none;
  outline: none;

  ${({ password }) =>
    password &&
    `
    padding-right: 16px;
  `}

  // hide arrows for input type number
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Placeholder = styled.div<PlaceholderType>`
  position: absolute;
  left: 16px;

  color: var(--color-grey);
  pointer-events: none;
  cursor: text;
  z-index: 1;

  ${({ isFilled }) =>
    isFilled &&
    `
    position: relative;
    left: 0;
    padding-bottom: 6px;

    font-weight: 600;
    font-size: 12px;
  `}
`;

const Star = styled.span`
  color: var(--color-error);
`;

const Eye = styled.img`
  position: absolute;
  right: 0;

  width: 14px;
  height: 14px;

  cursor: pointer;
`;

const Error = styled(ErrorBase)`
  position: absolute;
  top: 50px;
`;

type WrapperType = {
  error: boolean;
};

type PlaceholderType = {
  isFilled: boolean;
};

type InputFieldType = {
  password?: boolean;
};

export default Input;
