import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import Icons from '../../assets/icons';

type Props = {
  className?: string;
};

const Checkbox: FC<Props> = ({ className }) => {
  const [checked, setChecked] = useState(false);

  return (
    <CheckboxLogo
      className={className}
      onClick={() => setChecked(!checked)}
      src={checked ? Icons.CheckboxOn : Icons.CheckboxOff}
    />
  );
};

const CheckboxLogo = styled.img`
  width: 24px;
  height: 24px;
`;

export default Checkbox;
