import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { userRoles } from '../pages/Profile';
import { Dispatch, RootState } from '../../store';
import Icons from '../../assets/icons';
import { FileType } from '../../types/patients';
import { Checkbox as CheckboxBase } from '.';
import { Button } from '../atoms';
import { linkHandler } from '../../utils';

type Props = {
  serverFiles: FileType[];
  onClick: (arg: number) => void;
  procedureTypeId?: number | null;
  procedureId?: number | null;
};

export const getNameByUrl = (url: string): string => {
  const startIndex = url.lastIndexOf('/') + 1;
  const name = url.slice(startIndex);

  return name;
};

const convertIdToUrlPart = (list: number[]): string => {
  if (Object.keys(list).length === 0) {
    return '';
  }

  const urlPart = list.reduce((acc, item) => `${acc}&file_id=${item}`, '');
  const query = `?${urlPart.slice(1)}`;

  return query;
};

const ServerFiles: FC<Props> = ({ serverFiles, onClick, procedureTypeId, procedureId }) => {
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.user.data?.group);
  const editor = user === userRoles.editor;
  const admin = user === userRoles.admin;

  const [downloadUrl, setDownloadUrl] = useState('');
  const [filesIds, setFilesIds] = useState<number[]>([]);

  const deleteFile = (id?: number) => {
    if (id) {
      onClick(id);
    }
  };

  const handleClickCheckbox = (id?: number) => {
    if (id && !filesIds.includes(id)) {
      const urlPart = convertIdToUrlPart([...filesIds, id]);

      setFilesIds([...filesIds, id]);
      downloadZip(urlPart);
    } else if (id && filesIds.includes(id)) {
      const updatedFilesIds = filesIds.filter((fileId) => fileId !== id);
      const urlPart = convertIdToUrlPart(updatedFilesIds);

      setFilesIds(updatedFilesIds);
      downloadZip(urlPart);
    }
  };

  const downloadZip = (urlPart?: string) => {
    if (procedureTypeId && procedureId) {
      dispatch.patients
        .getZipWithPrucedureFiles({
          stringWithFilesIds: urlPart || '',
          procedureTypeId,
          procedureId,
        })
        .then((response) => {
          if (response.taskStatus === 'SUCCESS') {
            setDownloadUrl(response.file);
          }
        });
    }
  };

  useEffect(() => {
    downloadZip();
  }, [procedureTypeId, procedureId]);

  return (
    <Wrapper>
      {serverFiles.map((file) => (
        <File key={file.file}>
          <VerticalCentering>
            {(admin || editor) && (
              <VerticalCentering onClick={() => handleClickCheckbox(file.id)}>
                <Checkbox />
              </VerticalCentering>
            )}

            <FileLink onClick={() => linkHandler(file.file)}>{getNameByUrl(file.file)}</FileLink>
          </VerticalCentering>

          {(admin || editor) && (
            <Cross src={Icons.CrossToClose} onClick={() => deleteFile(file.id)} />
          )}
        </File>
      ))}

      <CenteringInRight>
        {downloadUrl && (admin || editor) && (
          <Download type="button" onClick={() => linkHandler(downloadUrl)}>
            Download
          </Download>
        )}
      </CenteringInRight>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 4px;
  margin-bottom: 16px;
  min-height: 24px;

  background-color: var(--color-light);
`;

const VerticalCentering = styled.div`
  display: flex;
  align-items: center;
`;

const CenteringInRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Checkbox = styled(CheckboxBase)`
  margin-right: 4px;
`;

const Cross = styled.img`
  cursor: pointer;
`;

const FileLink = styled.div`
  cursor: pointer;
`;

const Download = styled(Button)`
  width: 100px;
  height: 24px;
  margin-bottom: 16px;
`;

export default ServerFiles;
