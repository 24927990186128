export const url = {
  HOMEPAGE: '/',
  LOGIN: '/login',
  PROCEDURES: '/procedures',
  STUDIES: '/studies',
  USERS: '/users',
  LANDINGPAGE: '/landing-page',
  PATIENTS: '/patients',
  PHYSICIANS: '/physicians',
  PROFILE: '/profile',
  LOGOUT: '/logout',
  EXAMINATION: '/procedures/examination-table',
  PATIENT: '/patients/patient',
  LANDINGPAGE_QUERY_RESULTS: '/landing-page/query-results',
  SEARCH: '/search',
  QUERY_DATA: '/query-data',
  PAGE_NOT_FOUND: '/page-not-found',
};
