import { Models } from '@rematch/core';
import user from './user';
import examination from './examination';
import procedures from './procedures';
import studies from './studies';
import patients from './patients';
import physicians from './physicians';
import landingPage from './landingPage';

export interface RootModel extends Models<RootModel> {
  user: typeof user;
  examination: typeof examination;
  procedures: typeof procedures;
  studies: typeof studies;
  patients: typeof patients;
  physicians: typeof physicians;
  landingPage: typeof landingPage;
}

export const models: RootModel = {
  user,
  examination,
  procedures,
  studies,
  patients,
  physicians,
  landingPage,
};
