import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Title } from '.';

type Props = {
  path: string;
  onClick: () => void;
  children: string;
  className?: string;
};

const Heading: FC<Props> = ({ path, onClick, children, className }) => {
  return (
    <Wrapper to={path} className={className} onClick={onClick}>
      <Title>{children}</Title>
    </Wrapper>
  );
};

const Wrapper = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export default Heading;
