import { AxiosResponse } from 'axios';
import api from '../api/api';
import { PhysicianType } from '../types/physicians';
import { QueryType, PaginationResponseType } from '../types/general';

export const getPhysicians = ({
  pageNumber,
  pageSize,
}: QueryType): Promise<AxiosResponse<PaginationResponseType<PhysicianType>>> => {
  return api.get<PaginationResponseType<PhysicianType>>(
    `/selections/physician/?page=${pageNumber}&page_size=${pageSize}/`
  );
};

export const setPhysician = (data: PhysicianType): Promise<AxiosResponse<PhysicianType>> => {
  return api.post<PhysicianType>(`/selections/physician/`, data);
};

export const updatePhysician = (data: PhysicianType): Promise<AxiosResponse<PhysicianType>> => {
  return api.put<PhysicianType>(`/selections/physician/${data.id}/`, data);
};

export const deletePhysician = (id: number): Promise<void> => {
  return api.delete(`/selections/physician/${id}/`);
};
