import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { ModalWindow, Input as InputBase, Select as SelectBase, Loader } from '../molecules';
import { ErrorType } from '../../types/general';
import { ProfilePatchType, ProfileType } from '../../types/user';
import { uniqueErrors } from '../../utils';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  title: string;
  updateTable: () => void;
  openModalResetPassword?: () => void;
  userId?: number | null;
};

const groups = ['Administrators', 'Editors', 'Viewers'];

const ModalUser: FC<Props> = ({
  closeModal,
  updateTable,
  openModalResetPassword: openResetModal,
  userId,
  ...props
}) => {
  const dispatch = useDispatch<Dispatch>();

  const [loading, setLoading] = useState(false);
  const [buttonIsActive, setButtonIsActive] = useState(false);
  const [errors, setErrors] = useState<ErrorType | null>(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [group, setGroup] = useState('');
  const [password, setPassword] = useState('');

  const handleSaveInfo = () => {
    if (!buttonIsActive) {
      setButtonIsActive(true);

      if (userId) {
        const data: ProfilePatchType = {
          firstName,
          lastName,
          email,
          group,
        };

        const profile = {
          profile: data,
          id: userId,
        };

        dispatch.user
          .updateUser(profile)
          .then(() => {
            updateTable();
            clearData();
            closeModal();
          })
          .catch((err) => setErrors(err.response.data))
          .finally(() => setButtonIsActive(false));
      } else {
        const data: ProfileType = {
          firstName,
          lastName,
          email,
          group,
          password,
        };

        dispatch.user
          .addUser(data)
          .then(() => {
            updateTable();
            clearData();
            closeModal();
          })
          .catch((err) => setErrors(err.response.data))
          .finally(() => setButtonIsActive(false));
      }
    }
  };

  const clearData = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setGroup('');
    setPassword('');
    setErrors(null);
  };

  useEffect(() => {
    if (userId) {
      setLoading(true);

      dispatch.user
        .getUser(userId)
        .then((response) => {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setEmail(response.email);
          setGroup(response.group);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [userId]);

  return (
    <ModalWindow
      saveInfo={handleSaveInfo}
      closeModal={() => {
        clearData();
        closeModal();
      }}
      error={uniqueErrors(errors)}
      buttonDisabled={buttonIsActive}
      {...props}
    >
      <CenteringWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Content>
            <Input
              value={firstName}
              onChange={setFirstName}
              errorBorder={!!errors?.firstName}
              placeholder="Name"
              required
            />

            <Input
              value={lastName}
              onChange={setLastName}
              placeholder="Last name"
              errorBorder={!!errors?.lastName}
              required
            />

            <Input
              value={email}
              onChange={setEmail}
              placeholder="Email"
              errorBorder={!!errors?.email}
              required
            />

            <Select
              value={group}
              onChange={setGroup}
              placeholder="Role"
              errorBorder={!!errors?.group}
              options={groups}
              required
            />

            {!userId && (
              <Input
                value={password}
                onChange={setPassword}
                placeholder="Initial password"
                errorBorder={!!errors?.password}
                required
              />
            )}

            {userId && <ResetPassword onClick={openResetModal}>Reset password</ResetPassword>}
          </Content>
        )}
      </CenteringWrapper>
    </ModalWindow>
  );
};

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 340px;
`;

const Input = styled(InputBase)`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Select = styled(SelectBase)`
  margin-bottom: 16px;
`;

const ResetPassword = styled.div`
  margin-top: 16px;
  color: var(--color-black);
  cursor: pointer;
`;

export default ModalUser;
