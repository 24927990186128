import { QueryParametersType, RangeDateType } from '../types/patients';
import { toSnakeCase } from '.';

const queryParametersGenerator = (data: QueryParametersType[], date?: RangeDateType): string => {
  const base = data.reduce(
    (acc, item) =>
      `${acc}&operator=${item.operator}&table=${item.table}&field=${toSnakeCase(
        item.field
      )}&condition=${item.condition}&value=${item.value}&valueType=${
        item.valueType
      }&conditionType=${item.conditionType}&id=${item.id}`,
    ''
  );

  if (date) {
    const rangeDate = `&date-from=${date.dateFrom}&date-to=${date.dateTo}`;
    return base + rangeDate;
  }

  return base;
};

export default queryParametersGenerator;
