import React from 'react';
import styled from '@emotion/styled';
import { HeaderGroup } from 'react-table';
import Icons from '../../assets/icons';

type Props<T extends {}> = {
  headerGroups: HeaderGroup<T>[];
  dragOverHandler: (e: React.DragEvent<HTMLTableRowElement>) => void;
  dragStartHandler: () => void;
  dropHandler: (e: React.DragEvent<HTMLTableRowElement>) => void;
};

const TheadSection = <T extends {}>({
  headerGroups,
  dragOverHandler,
  dragStartHandler,
  dropHandler,
}: Props<T>): JSX.Element => {
  return (
    <TheadWrapper>
      {headerGroups.map((headerGroup) => (
        <TableRow
          onDragOver={(e) => dragOverHandler(e)}
          onDragStart={() => dragStartHandler()}
          onDrop={(e) => dropHandler(e)}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => (
            <HeaderCell {...column.getHeaderProps()}>
              <DragAndDrop className="dragndrop" src={Icons.DragAndDrop} />
              {column.render('Header')}
            </HeaderCell>
          ))}
        </TableRow>
      ))}
    </TheadWrapper>
  );
};

const TheadWrapper = styled.thead``;

const TableRow = styled.tr`
  position: relative;
  border: 1px solid var(--color-lines);
  border-top: none;

  &:hover .dragndrop {
    display: block;
  }
`;

const HeaderCell = styled.th`
  padding-left: 16px;
  width: 200px;
  height: 30px;

  font-size: 13px;
  font-weight: 400;
  text-align: start;

  color: var(--color-dark);
  background-color: var(--color-light);
  border: none;

  &:first-of-type {
    padding-left: 24px;
  }
`;

const DragAndDrop = styled.img`
  display: none;
  position: absolute;
  top: 7px;
  left: 0;

  cursor: pointer;
`;

export default TheadSection;
