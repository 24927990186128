import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { url } from '../../router/routes';
import { userIsAuth } from '../../services/AuthServices';
import { userRoles } from '../pages/Profile';

type Props = {
  component: FC;
  path: string;
};

const PublicRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const user = useSelector((state: RootState) => state.user.data?.group);
  const admin = user === userRoles.admin;

  return (
    <>
      {admin && (
        <Route
          {...rest}
          render={() => (userIsAuth() ? <Redirect to={url.PROCEDURES} /> : <Component />)}
        />
      )}

      {!admin && (
        <Route
          {...rest}
          render={() => (userIsAuth() ? <Redirect to={url.LANDINGPAGE} /> : <Component />)}
        />
      )}
    </>
  );
};

export default PublicRoute;
