import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { nanoid } from 'nanoid';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { ModalWindow, Select as SelectBase, Input as InputBase } from '../molecules';
import { Icon, AddButton, AndOrButton } from '../atoms';
import Icons from '../../assets/icons';
import { ExaminationFieldType, ExaminationType } from '../../types/procedures';
import { conversionOptions } from './ModalExaminationTable';
import { queryParametersGenerator, uniqueErrors } from '../../utils';
import { operatorVariants } from '../atoms/AndOrButton';
import { QueryParametersType } from '../../types/patients';
import { url } from '../../router/routes';
import { QueryType, ErrorType } from '../../types/general';

type Props = {
  errors?: ErrorType | null;
  date?: { dateFrom: string; dateTo: string };
  filterParameters?: QueryParametersType[];
  prevPage?: string;
  modalIsOpen: boolean;
  closeModal: () => void;
  updateTable?: (query: QueryType, parameters: string) => void;
};

export const filters = {
  TABLE: 'table',
  FIELD: 'field',
  CONDITION: 'condition',
  VALUE: 'value',
  OPERATOR: 'operator',
  VALUE_TYPE: 'valueType',
  CONDITION_TYPE: 'conditionType',
  ID: 'id',
  DATE_FROM: 'date-from',
  DATE_TO: 'date-to',
};

const conditions = [
  'exact',
  'contains',
  'in',
  'gt',
  'gte',
  'lt',
  'lte',
  'startswith',
  'endswith',
  'range',
];

const iconditions = [
  'iexact',
  'contains',
  'in',
  'gt',
  'gte',
  'lt',
  'lte',
  'istartswith',
  'iendswith',
  'range',
];

const patientCriteria = [
  'born at',
  'gender',
  'comments',
  'arterial hypertension',
  'diabetes',
  'previous stroke',
  'coronary artery disease',
  'coronary artery bypass graft',
  'ef',
  'la diameter',
  'la area',
  'vascular disease',
  'betablocker',
  'marcumar',
  'aspirin',
  'mitral reguritation',
  'antiarrhytmic drugs',
  'name',
  'first name',
];

const fieldVariants = {
  INPUT: 'input',
  SELECT: 'select',
  BOOLEAN: 'boolean',
};

const conditionVariants = {
  CONDITIONS: 'conditions',
  ICONDITIONS: 'iconditions',
};

const symbolsInId = 5;

const initialData = {
  table: '',
  field: '',
  condition: '',
  value: '',
  operator: operatorVariants.AND,
  id: nanoid(symbolsInId),
  valueType: fieldVariants.INPUT,
  conditionType: conditionVariants.CONDITIONS,
};

const patient = 'Patient';

const convertToSnakeCase = (name: string) => {
  return name.toLowerCase().replaceAll(' ', '_');
};

const convertToCamelCase = (name: string) => {
  return name.slice(0, 1).toUpperCase() + name.slice(1).toLowerCase().replaceAll('_', ' ');
};

const convertToListOfStringsFromExamination = (list: ExaminationType[]): string[] => {
  const result = list.map((item) => item.name);
  result.unshift(patient);
  return result;
};

const convertToListOfStringsFromExaminationFields = (list: ExaminationFieldType[]) => {
  return list.map((item) => convertToCamelCase(item.label));
};

const checkEmptyFields = (list: QueryParametersType[]) => {
  const allFieldsFilled = list.every((item) => {
    const array = Object.values(item);
    return array.every((element) => element !== '');
  });

  return !allFieldsFilled;
};

const ModalQueryData: FC<Props> = ({
  errors,
  date,
  filterParameters,
  prevPage,
  modalIsOpen,
  closeModal,
  updateTable,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const procedures = useSelector((state: RootState) => state.procedures);
  const examinationFields = useSelector((state: RootState) => state.examination.table);

  const [procedureType, setProcedureType] = useState<ExaminationType[]>([]);
  const [procedureFields, setProcedureFields] = useState<ExaminationFieldType[]>([]);
  const [procedureField, setProcedureField] = useState<ExaminationFieldType | null>(null);

  const [isCleared, setIsCleared] = useState(false);

  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [selectedCriteria, setSelectedCriteria] = useState('');

  const [dataList, setDataList] = useState<QueryParametersType[]>([initialData]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const inputData = (index: number, name: string) => (value: string) => {
    if (name === filters.FIELD) {
      setSelectedCriteria(value);
    }

    const selectedData = dataList[index];
    const updatedDataList = [...dataList];

    if (name === filters.TABLE) {
      updatedDataList[index] = { ...initialData, id: nanoid(symbolsInId), [name]: value };
    } else {
      updatedDataList[index] = { ...selectedData, [name]: value };
    }

    if (name === filters.CONDITION && value === 'in') {
      updatedDataList[index].valueType = fieldVariants.INPUT;
    }

    setSelectedFilterIndex(index);
    setDataList(updatedDataList);
  };

  const handleAddFilter = () => {
    const newData = {
      table: '',
      field: '',
      condition: '',
      value: '',
      operator: operatorVariants.AND,
      id: nanoid(symbolsInId),
      valueType: fieldVariants.INPUT,
      conditionType: conditionVariants.CONDITIONS,
    };

    const updatedDataList = [...dataList, newData];
    setDataList(updatedDataList);
  };

  const handleDeleteFilter = (selectedIndex: number) => {
    const updatedDataList = dataList.filter((item, index) => index !== selectedIndex);
    setDataList(updatedDataList);
  };

  const handleSaveInfo = () => {
    if (checkEmptyFields(dataList)) {
      return;
    }

    const query = {
      pageNumber: 1,
      pageSize: 20,
    };

    const dateRange = {
      dateFrom,
      dateTo,
    };

    const updatedDataList = dataList.map((filter) => {
      if (filter.table === patient) {
        return filter;
      }

      return {
        ...filter,
        table: procedureType.find((table) => table.name === filter.table)?.modelName || '',
        field: convertToSnakeCase(filter.field),
      };
    });

    const parameters =
      dateFrom && dateTo
        ? queryParametersGenerator(updatedDataList, dateRange)
        : queryParametersGenerator(updatedDataList);

    const pathname = prevPage || location.pathname;

    history.push(`${url.QUERY_DATA}/?page=${query.pageNumber}&prevPage=${pathname}${parameters}`);

    closeModal();

    if (updateTable) {
      updateTable(query, parameters);
    }
  };

  const clearModal = () => {
    setDataList([initialData]);
    setDateFrom('');
    setDateTo('');
    setIsCleared(true);
  };

  const loadCriteriaData = (index: number) => (status: boolean) => {
    if (status) {
      const category = dataList[index].table;
      dispatch.examination.getExaminationByName(category).then((examination) => {
        dispatch.examination.setExamination(examination.results[0]);
        dispatch.examination.getTable();
      });
    } else {
      setProcedureFields([]);
    }
  };

  const loadValue = (index: number) => (status: boolean) => {
    setProcedureField(null);

    if (status) {
      const category = dataList[index].table;

      dispatch.examination.getExaminationByName(category).then((examination) => {
        const { id } = examination.results[0];

        if (id) {
          dispatch.examination.getTableById({ id }).then((response) => {
            if (response) {
              const findedField = response.find(
                (item) =>
                  convertToCamelCase(item.label) === convertToCamelCase(dataList[index].field)
              );
              setProcedureField(findedField || null);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    // filter loading after opening modal window
    if (modalIsOpen && filterParameters) {
      const index = 0;
      const status = true;
      loadCriteriaData(index)(status);
      setIsCleared(false);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (selectedCriteria) {
      const findedField = procedureFields.find(
        (item) => convertToCamelCase(item.label) === convertToCamelCase(selectedCriteria)
      );

      const updatedDataList = [...dataList];
      const selectedData = dataList[selectedFilterIndex];

      selectedData.value = '';
      selectedData.condition = '';

      if (findedField) {
        if (findedField.dataType === conversionOptions.Bool) {
          updatedDataList[selectedFilterIndex] = {
            ...selectedData,
            valueType: 'boolean',
            conditionType:
              findedField.dataType === conversionOptions.String
                ? conditionVariants.ICONDITIONS
                : conditionVariants.CONDITIONS,
          };
        } else if (findedField.dataType === conversionOptions['Selection list']) {
          updatedDataList[selectedFilterIndex] = {
            ...selectedData,
            valueType: fieldVariants.SELECT,
            conditionType:
              findedField.dataType === conversionOptions.String
                ? conditionVariants.ICONDITIONS
                : conditionVariants.CONDITIONS,
          };
        } else {
          updatedDataList[selectedFilterIndex] = {
            ...selectedData,
            valueType: fieldVariants.INPUT,
            conditionType:
              findedField.dataType === conversionOptions.String
                ? conditionVariants.ICONDITIONS
                : conditionVariants.CONDITIONS,
          };
        }
      } else {
        updatedDataList[selectedFilterIndex] = { ...selectedData, valueType: fieldVariants.INPUT };
      }

      setDataList(updatedDataList);
      setSelectedCriteria('');
    }
  }, [selectedCriteria]);

  useEffect(() => {
    setProcedureType(procedures.data.results);
    setProcedureFields(examinationFields);
  }, [procedures, examinationFields]);

  useEffect(() => {
    const dataListIsNotFilled = [...dataList][0]?.value === '';
    const procedureTypeIsLoaded = procedureType.length !== 0;
    const procedureFieldsIsLoaded = procedureFields.length !== 0;

    if (
      filterParameters &&
      procedureTypeIsLoaded &&
      procedureFieldsIsLoaded &&
      dataListIsNotFilled &&
      !isCleared
    ) {
      const updatedFilterParameters = filterParameters.map((filter) => {
        if (filter.table === patient) {
          return filter;
        }

        return {
          ...filter,
          table: procedureType.find((table) => table.modelName === filter.table)?.name || '',
          field: convertToCamelCase(filter.field),
        };
      });

      setDataList(updatedFilterParameters);
    }
  }, [filterParameters, procedureType, procedureFields]);

  useEffect(() => {
    dispatch.procedures.getTable();

    if (date) {
      setDateFrom(date.dateFrom);
      setDateTo(date.dateTo);
    }
  }, []);

  return (
    <ModalWindow
      title="Query data"
      saveInfo={handleSaveInfo}
      modalIsOpen={modalIsOpen}
      clearModal={clearModal}
      closeModal={closeModal}
      error={uniqueErrors(errors)}
      {...props}
    >
      <Content>
        {dataList.map((item, index) => (
          <Filters key={item.id}>
            <Select
              value={dataList[index].table || ''}
              onChange={inputData(index, filters.TABLE)}
              placeholder="Select category"
              options={convertToListOfStringsFromExamination(procedureType)}
            />

            {dataList[index].table !== patient && (
              <Select
                value={dataList[index].field || ''}
                onChange={inputData(index, filters.FIELD)}
                placeholder="Select criteria"
                options={convertToListOfStringsFromExaminationFields(procedureFields)}
                dropdownIsOpen={loadCriteriaData(index)}
              />
            )}

            {dataList[index].table === patient && (
              <Select
                value={dataList[index].field.replaceAll(/_/g, ' ') || ''}
                onChange={inputData(index, filters.FIELD)}
                placeholder="Select criteria"
                options={patientCriteria}
              />
            )}

            <Select
              value={dataList[index].condition || ''}
              onChange={inputData(index, filters.CONDITION)}
              placeholder="Select condition"
              options={
                dataList[index].conditionType === conditionVariants.ICONDITIONS
                  ? iconditions
                  : conditions
              }
            />

            {dataList[index].valueType === fieldVariants.BOOLEAN && (
              <Select
                value={dataList[index].value || ''}
                onChange={inputData(index, filters.VALUE)}
                placeholder="Select value"
                options={['true', 'false']}
              />
            )}

            {dataList[index].valueType === fieldVariants.SELECT && (
              <Select
                value={dataList[index].value || ''}
                onChange={inputData(index, filters.VALUE)}
                placeholder="Select value"
                options={procedureField?.choices || []}
                dropdownIsOpen={loadValue(index)}
              />
            )}

            {dataList[index].valueType === fieldVariants.INPUT && (
              <Input
                value={dataList[index].value || ''}
                onChange={inputData(index, filters.VALUE)}
                placeholder="Select value"
              />
            )}

            {index === 0 ? (
              <EmptyBlock />
            ) : (
              <AndOrButton
                value={dataList[index].operator || ''}
                onClick={inputData(index, filters.OPERATOR)}
              />
            )}

            <Icon src={Icons.CrossToClose} onClick={() => handleDeleteFilter(index)} />
          </Filters>
        ))}

        <Button primary onClick={handleAddFilter}>
          Add filter
        </Button>

        <DateRange>
          <DateRangeTitle>Date Range</DateRangeTitle>

          <RangeWrapper>
            <input type="date" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />

            <Hyphen>-</Hyphen>

            <input type="date" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
          </RangeWrapper>
        </DateRange>
      </Content>
    </ModalWindow>
  );
};

const Content = styled.div`
  padding: 0 24px;
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Select = styled(SelectBase)`
  width: 146px;
`;

const Input = styled(InputBase)`
  width: 146px;
`;

const Button = styled(AddButton)`
  width: 146px;
  margin-bottom: 24px;
`;

const DateRange = styled.div``;

const DateRangeTitle = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
`;

const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Hyphen = styled.div`
  margin: 0 16px;
`;

const EmptyBlock = styled.div`
  width: 76px;
`;

export default ModalQueryData;
