import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { PageWrapper, Table } from '../features';
import { ModalExaminationTable } from '../organisms';
import {
  Dropdown as DropdownBase,
  DropdownStatus as DropdownStatusBase,
  ModalWindowConfirm,
  ModalWindowInput,
  Tsections,
  Loader,
} from '../molecules';
import { Button as ButtonBase, AddButton as AddButtonBase, RouteBlock, Header } from '../atoms';
import {
  ExaminationFieldType,
  ExaminationFieldRequestType,
  ChoiceType,
  SectionType,
  SwitchType,
} from '../../types/procedures';
import { url } from '../../router/routes';
import Icons from '../../assets/icons';
import { useQuery } from '../../hooks';
import { ErrorType } from '../../types/general';
import { StudyType } from '../../types/studies';

const convertToString = (choices: ChoiceType) => {
  // combine all options into a string and remove the comma at the end
  return Object.values(choices)
    .reduce((acc, item) => `${acc} ${item},`, '')
    .slice(1, -1);
};

export const headerActions = {
  LABEL: 'label',
  DATA_TYPE: 'dataType',
  NULL: 'null',
  HELP_TEXT: 'helpText',
  CHOICES: 'choices',
  ACTIONS: 'actions',
};

const modals = {
  CHANGE_NAME: 'changeName',
  DELETE_EXAMINATION: 'deleteExamination',
  ADD_FIELD: 'addField',
  EDIT_FIELD: 'editField',
  DELETE_FIELD: 'deleteField',
  ADD_SECTION: 'addSection',
  EDIT_SECTION: 'editSection',
  DELETE_SECTION: 'deleteSection',
};

const Examination: FC = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch<Dispatch>();

  const examination = useSelector((state: RootState) => state.examination.data);
  const examinationQuery = useSelector((state: RootState) => state.examination.query);
  const proceduresQuery = useSelector((state: RootState) => state.procedures.query);
  const table = useSelector((state: RootState) => state.examination.table);
  const [loading, setLoading] = useState(false);

  const pageNumber = Number(query.get('page')) || 1;
  const pageId = Number(query.get('id'));

  const [activeModal, setActiveModal] = useState('');
  const [buttonIsActive, setButtonIsActive] = useState(false);

  const [error, setError] = useState<ErrorType | null>(null);
  const [newTableName, setNewTableName] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [selectedSectionName, setSelectedSectionName] = useState('');
  const [selectedFieldId, setSelectedFieldId] = useState<number | null>(null);
  const [currentFieldId, setCurrentFieldId] = useState<number | null>(null);
  const [currentSection, setCurrentSection] = useState<SectionType>();
  const [selectedField, setSelectedField] = useState<ExaminationFieldType>();

  const [tableName, setTableName] = useState(examination.name);
  const [isActive, setIsActive] = useState(examination.isActive);
  const [isCase, setIsCase] = useState(examination.isCase);
  const [isStudyCase, setIsStudyCase] = useState(examination.isStudyCase);
  const [isFileUploadAvailable, setIsFileUploadAvailable] = useState(
    examination.isFileUploadAvailable
  );
  const [dataTable, setDataTable] = useState(table);
  const [selectedStudies, setSelectedStudies] = useState(examination.studies);
  const [partStudies, setPartStudies] = useState<StudyType[]>([]);
  const [studies, setStudies] = useState<StudyType[]>([]);
  const [sections, setSections] = useState<SectionType[]>();

  // convert Selection list column from Object to String
  const convertData = dataTable.map((field) => {
    if (field.choices) {
      return {
        ...field,
        choices: convertToString(field.choices),
      };
    }

    return field;
  });

  const allFieldsInSections = sections
    ? sections.reduce((acc: number[], item) => acc.concat(item.fields), [])
    : [];

  const filteredData = convertData.filter((field) => !allFieldsInSections.includes(field.id));

  const dataForTable = useMemo(() => filteredData, [filteredData]);
  const dataForSection = useMemo(() => convertData, [convertData]);
  const columns = useMemo(
    () => [
      {
        Header: 'Field name',
        accessor: headerActions.LABEL,
      },
      {
        Header: 'Data type',
        accessor: headerActions.DATA_TYPE,
      },
      {
        Header: 'IsOptional',
        accessor: headerActions.NULL,
      },
      {
        Header: 'Description',
        accessor: headerActions.HELP_TEXT,
      },
      {
        Header: 'Selection list',
        accessor: headerActions.CHOICES,
      },
      {
        Header: 'Actions',
        accessor: headerActions.ACTIONS,
      },
    ],
    []
  );

  const filteredStudies =
    studies.length === 0
      ? partStudies.filter((item) => item.isActive === true)
      : studies.filter((item) => item.isActive === true);

  const studiesForDropdown = filteredStudies.map((item) => {
    const includesId = selectedStudies?.some((number) => number === item.id);

    if (includesId) {
      return {
        ...item,
        isActive: true,
      };
    } else {
      return {
        ...item,
        isActive: false,
      };
    }
  });

  const dragAndDropField = (hoverFieldId: number) => {
    if (!currentFieldId) {
      // if you drag a section onto the field
      return;
    }

    if (currentFieldId && hoverFieldId) {
      const switchFields: SwitchType = {
        object1Id: currentFieldId,
        object2Id: hoverFieldId,
      };

      dispatch.examination.switchExaminationField(switchFields);
    }

    if (sections) {
      const current = sections.find((section) => section.fields.includes(currentFieldId));
      const hover = sections.find((section) => section.fields.includes(hoverFieldId));

      if (current?.name === hover?.name) {
        setCurrentFieldId(null);
        return;
      }

      const updatedSections = sections.map((section) => {
        if (current?.name === section.name) {
          return {
            ...section,
            fields: [...section.fields.filter((item) => item !== currentFieldId), hoverFieldId],
          };
        }

        if (hover?.name === section.name) {
          return {
            ...section,
            fields: [...section.fields.filter((item) => item !== hoverFieldId), currentFieldId],
          };
        }

        return section;
      });

      dispatch.examination.updateSection(updatedSections).then(() => setSections(updatedSections));
    }

    setCurrentFieldId(null);
    setCurrentSection(undefined);
  };

  const dragAndDropSection = (hoverSection: SectionType) => {
    if (sections && !currentSection && currentFieldId) {
      // used to remove a field from section
      if (hoverSection.fields.includes(currentFieldId)) {
        const updatedSections = sections.map((section) => {
          if (section.fields.includes(currentFieldId)) {
            return {
              ...section,
              fields: section.fields.filter((item) => item !== currentFieldId),
            };
          }

          return section;
        });

        dispatch.examination
          .updateSection(updatedSections)
          .then(() => setSections(updatedSections));

        setCurrentFieldId(null);
        return;
      }

      // used to drag and drop a field into a section
      const updatedSections = sections.map((section) => {
        if (section.name === hoverSection.name) {
          return {
            ...section,
            fields: [...section.fields, currentFieldId],
          };
        }

        if (section.fields.includes(currentFieldId)) {
          return {
            ...section,
            fields: section.fields.filter((item) => item !== currentFieldId),
          };
        }

        return section;
      });

      dispatch.examination.updateSection(updatedSections).then(() => setSections(updatedSections));
    }

    if (sections && currentSection) {
      const updatedSections = sections.map((section) => {
        if (section.name === currentSection.name) {
          return hoverSection;
        }

        if (section.name === hoverSection.name) {
          return currentSection;
        }

        return section;
      });

      dispatch.examination.updateSection(updatedSections).then(() => setSections(updatedSections));
    }

    setCurrentFieldId(null);
    setCurrentSection(undefined);
  };

  const handleAddSection = () => {
    if (sectionName === '') {
      const sectionError = {
        section: ['Section with this name already exists'],
      };

      setError(sectionError);
      return;
    }

    const section = {
      id: nanoid(),
      name: sectionName,
      fields: selectedField ? [selectedField.id] : [],
    };

    const nameIsRepeated = sections?.some((item) => item.name === sectionName);

    // add a selected field in the section after clicking
    // an Edit button and writing the name section
    if (nameIsRepeated && selectedField && sections) {
      const data = sections.map((item) => {
        if (item.fields.includes(selectedField.id)) {
          return {
            ...item,
            fields: item.fields.filter((id) => id !== selectedField.id),
          };
        }

        if (item.name === sectionName) {
          return {
            ...item,
            fields: [...item.fields, selectedField.id],
          };
        }

        return item;
      });

      dispatch.examination.updateSection(data).then(() => setSections(data));
      return;
    }

    if (nameIsRepeated) {
      const sectionError = {
        section: ['Section with this name already exists'],
      };

      setError(sectionError);
      return;
    }

    const data = sections ? [...sections, section] : [section];

    dispatch.examination.updateSection(data).then(() => setSections(data));
    handleCloseFieldsModal();
  };

  const handleDeleteSection = () => {
    if (sections) {
      const data = sections.filter((section) => section.name !== selectedSectionName);

      dispatch.examination.updateSection(data).then(() => setSections(data));
    }
  };

  const handleChangeNameSection = () => {
    const nameIsRepeated = sections?.some((item) => item.name === sectionName);

    if (nameIsRepeated) {
      const sectionError = {
        section: ['Section with this name already exists'],
      };

      setError(sectionError);
      return;
    }

    if (sections) {
      const data = sections.map((section) => {
        if (section.name === selectedSectionName) {
          return {
            ...section,
            name: sectionName,
          };
        }

        return section;
      });

      dispatch.examination.updateSection(data).then(() => setSections(data));
      handleCloseFieldsModal();
    }
  };

  const handleAddField = (field: ExaminationFieldRequestType) => {
    if (!buttonIsActive) {
      setButtonIsActive(true);

      dispatch.examination
        .addField(field)
        .then(() => {
          setActiveModal('');
        })
        .catch((e) => {
          if (e.response?.data) {
            setError(e.response.data);
          }
        })
        .finally(() => setButtonIsActive(false));
    }
  };

  const handleUpdateField = (field: ExaminationFieldRequestType) => {
    if (!buttonIsActive) {
      setButtonIsActive(true);

      dispatch.examination
        .updateField(field)
        .then(() => {
          setActiveModal('');
        })
        .catch((e) => {
          if (e.response?.data) {
            setError(e.response.data);
          }
        })
        .finally(() => setButtonIsActive(false));
    }
  };

  const handleDeleteField = () => {
    if (selectedFieldId) {
      dispatch.examination.deleteField(selectedFieldId);
    }
  };

  const handleChangeName = () => {
    dispatch.examination
      .changeName(newTableName)
      .then(() => {
        setTableName(newTableName);
        setActiveModal('');
      })
      .catch((e) => {
        if (e.response?.data) {
          setError(e.response.data);
        }
      });
  };

  const handleStatusTable = (status: boolean) => {
    dispatch.examination.changeStatus(status).then(() => {
      setIsActive(status);
    });
  };

  const handleChangeCase = (status: boolean) => {
    dispatch.examination.changeCase(status).then(() => {
      setIsCase(status);
    });
  };

  const handleFileUpload = (status: boolean) => {
    dispatch.examination.changeFileUpload(status).then(() => {
      setIsFileUploadAvailable(status);
    });
  };

  const handleAssignStudies = (id: number) => {
    const includesId = selectedStudies?.some((number) => number === id);

    if (selectedStudies) {
      if (includesId) {
        const newSelectedStudies = selectedStudies.filter((item) => item !== id);

        dispatch.examination.assignStudies(newSelectedStudies).then(() => {
          setSelectedStudies(newSelectedStudies);
        });
      } else {
        dispatch.examination.assignStudies([...selectedStudies, id]).then(() => {
          setSelectedStudies([...selectedStudies, id]);
        });
      }
    }
  };

  const handleDeleteExamination = (id: number) => {
    dispatch.examination.deleteExamination(id).then(() => {
      history.push(url.PROCEDURES);
    });
  };

  const handleSectionAction = (action: string, name: string) => {
    if (action === 'edit') {
      setSectionName(name);
      setSelectedSectionName(name);
      setActiveModal(modals.EDIT_SECTION);
    } else if (action === 'delete') {
      setSelectedSectionName(name);
      setActiveModal(modals.DELETE_SECTION);
    }
  };

  const handleFieldAction = (action: string) => (id: number) => {
    if (action === 'edit') {
      setSelectedField(dataTable.find((field) => field.id === id));
      setActiveModal(modals.EDIT_FIELD);
    } else if (action === 'delete') {
      setSelectedFieldId(id);
      setActiveModal(modals.DELETE_FIELD);
    }
  };

  const handleCloseFieldsModal = () => {
    setError(null);
    setActiveModal('');
    setSelectedField(undefined);
    setSelectedFieldId(null);
    setNewTableName('');
    setSectionName('');
  };

  const handlePageClick = (selectedPage: number) => {
    dispatch.examination.setQuery({ ...examinationQuery, pageNumber: selectedPage });
    dispatch.examination.getTable();

    history.push(`${url.EXAMINATION}/?page=${selectedPage}&id=${pageId}`);
  };

  useEffect(() => {
    const result: StudyType[] = [];
    const currentQuery = {
      pageNumber: 1,
      pageSize: 50,
    };

    const loadSudies = () => {
      dispatch.studies.getStudies(currentQuery).then((response) => {
        result.push(...response.results);

        if (currentQuery.pageNumber === 1) {
          setPartStudies(result);
        }

        currentQuery.pageNumber += 1;

        if (currentQuery.pageNumber > Math.ceil(response.count / currentQuery.pageSize)) {
          setStudies(result);
        }

        if (currentQuery.pageNumber <= Math.ceil(response.count / currentQuery.pageSize)) {
          loadSudies();
        }
      });
    };

    loadSudies();
  }, []);

  useEffect(() => {
    if (pageId) {
      setLoading(true);

      dispatch.examination.setQuery({ ...examinationQuery, pageNumber });
      dispatch.examination.getExamination({ id: pageId }).finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    setTableName(examination.name);
    setDataTable(table);
    setIsActive(examination.isActive);
    setIsCase(examination.isCase);
    setIsStudyCase(examination.isStudyCase);
    setIsFileUploadAvailable(examination.isFileUploadAvailable);
    setSelectedStudies(examination.studies);

    if (examination.sections) {
      setSections(examination.sections.data);
    } else {
      setSections(undefined);
    }
  }, [examination, table]);

  useEffect(() => {
    if (!pageId && examination.id) {
      history.replace(`${url.EXAMINATION}/?page=${pageNumber}&id=${examination.id}`);
    }
  }, [examination.id]);

  if (loading) {
    return (
      <PageWrapper>
        <RouteBlock
          previousURL={url.PROCEDURES}
          previousQuery={proceduresQuery}
          previous="Procedures"
          current="Examination table"
        />

        <Loader />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <RouteBlock
        previousURL={url.PROCEDURES}
        previousQuery={proceduresQuery}
        previous="Procedures"
        current="Examination table"
      />

      <Header>
        <TableInfo>
          <TableName>
            {tableName}
            <Edit src={Icons.Edit} onClick={() => setActiveModal(modals.CHANGE_NAME)} />

            <ModalWindowInput
              title="Change name"
              modalIsOpen={activeModal === modals.CHANGE_NAME}
              closeModal={() => setActiveModal('')}
              saveInfo={handleChangeName}
              inputValue={newTableName}
              inputOnChange={setNewTableName}
              inputRequired
              placeholder="Table name"
              error={error?.name}
            />
          </TableName>
          <TableStatus>
            Table status:
            <DropdownStatus isActivated={isActive} setStatusTable={handleStatusTable} />
          </TableStatus>

          <TableStatus>
            Display as Case:
            <YesNoBlock onClick={() => handleChangeCase(!isCase)} isCase={isCase}>
              {isCase ? 'Yes' : 'No'}
            </YesNoBlock>
          </TableStatus>

          <TableStatus>
            File upload:
            <YesNoBlock
              onClick={() => handleFileUpload(!isFileUploadAvailable)}
              isCase={!!isFileUploadAvailable}
            >
              {isFileUploadAvailable ? 'Yes' : 'No'}
            </YesNoBlock>
          </TableStatus>
        </TableInfo>

        <ButtonBlock>
          {isStudyCase && (
            <Dropdown
              onClick={handleAssignStudies}
              options={studiesForDropdown}
              description="Assign available studies"
            />
          )}

          <AddFieldButton onClick={() => setActiveModal(modals.ADD_SECTION)}>
            Add Section
          </AddFieldButton>

          <AddFieldButton onClick={() => setActiveModal(modals.ADD_FIELD)}>
            Add Field
          </AddFieldButton>

          <DeleteButton onClick={() => setActiveModal(modals.DELETE_EXAMINATION)}>
            Delete
          </DeleteButton>

          <ModalExaminationTable
            modalIsOpen={activeModal === modals.ADD_FIELD}
            closeModal={handleCloseFieldsModal}
            saveField={handleAddField}
            title="Add field"
            error={error}
            buttonDisabled={buttonIsActive}
          />

          <ModalWindowConfirm
            title="Delete table"
            modalIsOpen={activeModal === modals.DELETE_EXAMINATION}
            closeModal={() => setActiveModal('')}
            saveInfo={() => handleDeleteExamination(pageId)}
          >
            Are you sure you want to delete this table ?
          </ModalWindowConfirm>
        </ButtonBlock>
      </Header>

      <Table
        columns={columns}
        data={dataForTable}
        handleEdit={handleFieldAction('edit')}
        handleDelete={handleFieldAction('delete')}
        setCurrentFieldId={setCurrentFieldId}
        setHoverFieldId={dragAndDropField}
        dragAndDrop
      />

      {sections && (
        <Tsections
          sections={sections}
          dataForSection={dataForSection}
          handleFieldAction={handleFieldAction}
          handleSectionAction={handleSectionAction}
          setCurrentFieldId={setCurrentFieldId}
          dragAndDropField={dragAndDropField}
          setCurrentSection={setCurrentSection}
          dragAndDropSection={dragAndDropSection}
        />
      )}

      <ModalWindowInput
        title="Add section"
        modalIsOpen={activeModal === modals.ADD_SECTION}
        closeModal={handleCloseFieldsModal}
        saveInfo={handleAddSection}
        inputValue={sectionName}
        inputOnChange={setSectionName}
        inputRequired
        placeholder="Section name"
        error={error?.section}
      />

      <ModalWindowInput
        title="Edit section"
        modalIsOpen={activeModal === modals.EDIT_SECTION}
        closeModal={handleCloseFieldsModal}
        saveInfo={handleChangeNameSection}
        inputValue={sectionName}
        inputOnChange={setSectionName}
        inputRequired
        placeholder="Section name"
        error={error?.section}
      />

      <ModalWindowConfirm
        title="Delete section"
        modalIsOpen={activeModal === modals.DELETE_SECTION}
        closeModal={() => setActiveModal('')}
        saveInfo={handleDeleteSection}
      >
        Are you sure you want to delete this section ?
      </ModalWindowConfirm>

      <ModalExaminationTable
        modalIsOpen={activeModal === modals.EDIT_FIELD}
        closeModal={handleCloseFieldsModal}
        saveField={handleUpdateField}
        title="Edit field"
        selectedField={selectedField}
        error={error}
        buttonDisabled={buttonIsActive}
      />

      <ModalWindowConfirm
        title="Delete field"
        modalIsOpen={activeModal === modals.DELETE_FIELD}
        closeModal={() => setActiveModal('')}
        saveInfo={handleDeleteField}
      >
        Are you sure you want to delete this field ?
      </ModalWindowConfirm>

      {dataTable.length === 0 && (
        <AddButton onClick={() => setActiveModal(modals.ADD_FIELD)}>Add field</AddButton>
      )}
    </PageWrapper>
  );
};

const ButtonBlock = styled.div`
  display: flex;
`;

const Dropdown = styled(DropdownBase)`
  width: 218px;
`;

const AddFieldButton = styled(ButtonBase)`
  margin-left: 16px;
  width: 104px;
`;

const DeleteButton = styled(ButtonBase)`
  margin-left: 16px;
  width: 89px;
`;

const TableInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
`;

const TableName = styled.div`
  display: flex;

  font-size: 18px;
  color: (--color-black);
`;

const TableStatus = styled.div`
  display: flex;
`;

const DropdownStatus = styled(DropdownStatusBase)`
  margin-left: 7px;
`;

const Edit = styled.img`
  margin-left: 8px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const AddButton = styled(AddButtonBase)`
  display: flex;
  justify-content: center;
  border: 1px solid var(--color-lines);
  border-top: none;
`;

const YesNoBlock = styled.span`
  color: var(--color-error);
  margin-left: 7px;

  ${({ isCase }: { isCase: boolean }) =>
    isCase &&
    `
    color: var(--color-green);
  `}

  cursor: pointer;
`;

export default Examination;
