import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { ModalWindow, Input as InputBase } from '../molecules';
import { ErrorType } from '../../types/general';

const FirstLogin: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.user.data);
  const [error, setError] = useState<ErrorType | null>(null);

  const [isOpen, setIsOpen] = useState(true);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleChangePassword = () => {
    if (user) {
      const userData = {
        id: user.id,
        oldPassword: oldPassword.trim(),
        newPassword: newPassword.trim(),
      };

      dispatch.user
        .changePassword(userData)
        .then(() => {
          setIsOpen(false);
        })
        .catch((e) => {
          if (e.response?.data) {
            setError(e.response.data);
          }
        });
    }
  };

  return (
    <ModalWindow
      modalIsOpen={!!user?.isPasswordTemp && isOpen}
      closeModal={() => setIsOpen(false)}
      saveInfo={handleChangePassword}
      title="Change password"
    >
      <CenteringWrapper>
        <Title>Hello, it’s your first-time login, please set your new password.</Title>

        <Content>
          <InputPassword
            password
            value={oldPassword}
            onChange={setOldPassword}
            placeholder="Old password"
            error={error?.oldPassword}
          />

          <InputPassword
            password
            value={newPassword}
            onChange={setNewPassword}
            placeholder="New password"
            error={error?.newPassword || error?.nonFieldErrors}
          />
        </Content>
      </CenteringWrapper>
    </ModalWindow>
  );
};

const Content = styled.div`
  width: 340px;
`;

const Title = styled.div`
  margin-bottom: 8px;
`;

const InputPassword = styled(InputBase)<InputType>`
  margin-top: 8px;

  ${({ error }) =>
    error &&
    `
    &:first-of-type {
      margin-bottom: 22px;
    }
  `}
`;

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type InputType = {
  error?: string[] | null;
};

export default FirstLogin;
