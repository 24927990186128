import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { QueryType, PaginationResponseType } from '../../types/general';
import { ExaminationType } from '../../types/procedures';
import { StudyType } from '../../types/studies';
import { getExaminations } from '../../services/ProceduresServices';
import { getStudies } from '../../services/StudiesServices';
import store from '../index';

type InitialStateType<Examination, Study> = {
  procedures: PaginationResponseType<Examination>;
  studies: PaginationResponseType<Study>;
  proceduresQuery: QueryType;
  studiesQuery: QueryType;
};

const initialState: InitialStateType<ExaminationType, StudyType> = {
  procedures: {
    count: 0,
    results: [],
  },
  studies: {
    count: 0,
    results: [],
  },
  proceduresQuery: {
    pageNumber: 1,
    pageSize: 20,
  },
  studiesQuery: {
    pageNumber: 1,
    pageSize: 20,
  },
};

const landingPage = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setProcedures(state, procedures: PaginationResponseType<ExaminationType>) {
      return {
        ...state,
        procedures,
      };
    },
    setStudies(state, studies: PaginationResponseType<StudyType>) {
      return {
        ...state,
        studies,
      };
    },
    setProceduresQuery(state, proceduresQuery: QueryType) {
      return {
        ...state,
        proceduresQuery,
      };
    },
    setStudiesQuery(state, studiesQuery: QueryType) {
      return {
        ...state,
        studiesQuery,
      };
    },
    setClearPhysicians() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async getProcedures() {
      try {
        const { proceduresQuery } = store.getState().landingPage;
        const proceduresParameters = '&is_active=true&is_case=true';

        const response = await getExaminations(proceduresQuery, proceduresParameters);
        dispatch.landingPage.setProcedures(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getStudies() {
      try {
        const { studiesQuery } = store.getState().landingPage;
        const studiesParameters = '&is_active=true';

        const response = await getStudies(studiesQuery, studiesParameters);
        dispatch.landingPage.setStudies(response.data);
      } catch (error) {
        throw error;
      }
    },
  }),
});

export default landingPage;
