import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { PageWrapper, Table, Pagination } from '../features';
import { RouteBlock as RouteBlockBase, Title, Button } from '../atoms';
import { url } from '../../router/routes';
import { useQuery } from '../../hooks';
import { PaginationResponseType } from '../../types/general';
import { ProcedureDataType } from '../../types/patients';
import { Loader, ModalWindowConfirm } from '../molecules';
import { ModalAddProcedureData } from '../organisms';
import { getAccessToken } from '../../services/AuthServices';
import { apiFile } from '../../api/api';
import Notification, { notify, notifitationVariants } from '../molecules/Notification';

const headerActions = {
  PROCEDURE_ID: 'procedureId',
  DATE: 'date',
  PHYSICIAN: 'physicianValue',
  PHYSICIAN_ID: 'physician',
  PATIENT_ID: 'patientId',
  ACTIONS: 'actions',
};

const modals = {
  EDIT: 'edit',
  DELETE: 'delete',
};

const LandingPageQueryResults: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const procedure = query.get('procedure');
  const study = query.get('study');
  const id = Number(query.get('id'));
  const pageNumber = Number(query.get('page')) || 1;
  const pageSize = 20;

  const queryData = {
    pageNumber,
    pageSize,
  };

  const [activeModal, setActiveModal] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const [masterData, setMasterData] = useState<ProcedureDataType[]>([]);
  const [procedureId, setProcedureId] = useState<number | null>(null);
  const [procedureTypeId, setProcedureTypeId] = useState<number | null>(null);
  const [patientId, setPatientId] = useState<number | null>(null);
  const [pagination, setPagination] = useState<PaginationResponseType<ProcedureDataType>>({
    count: 0,
    results: [],
  });

  const data = useMemo(() => masterData, [masterData]);
  const columns = useMemo(
    () => [
      {
        Header: 'Procedure ID',
        accessor: headerActions.PROCEDURE_ID,
      },
      {
        Header: 'Date',
        accessor: headerActions.DATE,
      },
      {
        Header: 'Physician',
        accessor: procedure ? headerActions.PHYSICIAN_ID : headerActions.PHYSICIAN,
      },
      {
        Header: 'Patient ID',
        accessor: headerActions.PATIENT_ID,
      },
      {
        Header: 'Actions',
        accessor: headerActions.ACTIONS,
      },
    ],
    []
  );

  const handleAction = (action: string) => (identifier: number) => {
    if (action === 'edit') {
      const findedField = masterData.find((item) => item.procedureId === identifier);
      setPatientId(findedField?.patientId || findedField?.id);
      setProcedureId(identifier);

      if (procedure) {
        setProcedureTypeId(id);
      }

      if (study) {
        dispatch.examination.getExaminationByName(findedField?.type).then((response) => {
          const findedId = response.results[0]?.id;
          if (findedId) setProcedureTypeId(findedId);
        });
      }

      setActiveModal(modals.EDIT);
    } else if (action === 'delete') {
      setProcedureId(identifier);
      setActiveModal(modals.DELETE);
    }
  };

  const handleDelete = () => {
    if (procedure && procedureId && id) {
      const dataForSending = {
        procedureTypeId: id,
        procedureId,
      };

      dispatch.patients.deleteProcedureData(dataForSending).then(() => loadData());
    }

    if (study && procedureId) {
      const findedField = masterData.find((item) => item.procedureId === procedureId);

      dispatch.examination.getExaminationByName(findedField?.tableName).then((response) => {
        const findedId = response.results[0]?.id;

        if (findedId) {
          const dataForSending = {
            procedureTypeId: findedId,
            procedureId,
          };

          dispatch.patients.deleteProcedureData(dataForSending).then(() => loadData());
        }
      });
    }
  };

  const handleCloseModal = () => {
    setPatientId(null);
    setProcedureId(null);
    setProcedureTypeId(null);
    setActiveModal('');
  };

  const handlePageClick = (selectedPage: number) => {
    queryData.pageNumber = selectedPage;
    loadData();

    if (procedure) {
      history.push(
        `${url.LANDINGPAGE_QUERY_RESULTS}/?page=${selectedPage}&procedure=${procedure}&id=${id}`
      );
    }

    if (study) {
      history.push(
        `${url.LANDINGPAGE_QUERY_RESULTS}/?page=${selectedPage}&study=${study}&id=${id}`
      );
    }
  };

  const loadData = () => {
    if (procedure && id) {
      if (masterData.length === 0) {
        setLoading(true);
      }

      dispatch.patients
        .getAllProcedureData({ procedureTypeId: id, ...queryData })
        .then((response) => {
          const updatedData = response.results.map((item) => {
            return {
              ...item,
              procedureId: item.id,
              patientId: item.patient,
            };
          });

          setMasterData(updatedData);
          setPagination(response);
        })
        .finally(() => setLoading(false));
    }

    if (study && id) {
      if (masterData.length === 0) {
        setLoading(true);
      }

      dispatch.studies
        .getProcedures({ id, ...queryData })
        .then((response) => {
          const updatedData = response.results.map((item) => {
            return {
              ...item,
              id: item.procedureId,
            };
          });

          setMasterData(updatedData);
          setPagination(response);
        })
        .finally(() => setLoading(false));
    }
  };

  const getFile = (src: string) => {
    const updatedSrc = src.slice(src.indexOf('/media/'));

    if (updatedSrc) {
      apiFile
        .get(updatedSrc, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        .then((response) => {
          const fileUrl = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.rel = 'noreferrer';
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => notify('File could not be loaded', notifitationVariants.ERROR));
    }
  };

  useEffect(() => {
    if (!downloadUrl) {
      const parameters = procedure ? `?procedure_type_id=${id}` : `?study_id=${id}`;

      const getSuccessStatus = (taskId: string) => {
        dispatch.patients.getPreparedFile(taskId).then((file) => {
          if (file.taskStatus === 'PENDING') {
            getSuccessStatus(taskId);
          } else {
            setDownloadUrl(file.file);
          }
        });
      };

      dispatch.patients.preparedFile(parameters).then((taskId) => getSuccessStatus(taskId));
    }
  }, [downloadUrl]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      <TitleWrapper>
        <СolumnTape>
          <RouteBlock previous="Dashboard" current="Query results" previousURL={url.LANDINGPAGE} />
          <Title>Query results: {procedure ? `${procedure} procedure` : `${study} study`}</Title>
        </СolumnTape>

        <ExportButton onClick={() => getFile(downloadUrl)} disabled={!downloadUrl}>
          Export
        </ExportButton>
      </TitleWrapper>

      {loading ? (
        <Loader />
      ) : (
        <TableWrapper>
          <Table
            data={data}
            columns={columns}
            handleEdit={handleAction(modals.EDIT)}
            handleDelete={handleAction(modals.DELETE)}
          />

          <Pagination
            dataTable={pagination}
            activePage={pageNumber}
            pageSize={pageSize}
            onPageChange={handlePageClick}
          />
        </TableWrapper>
      )}

      <ModalAddProcedureData
        title="Edit procedure"
        modalIsOpen={activeModal === modals.EDIT}
        closeModal={handleCloseModal}
        updateList={loadData}
        procedureId={procedureId}
        procedureTypeId={procedureTypeId}
        patientId={patientId}
        editMode
      />

      <ModalWindowConfirm
        title="Delete procedure"
        modalIsOpen={activeModal === modals.DELETE}
        closeModal={handleCloseModal}
        saveInfo={handleDelete}
      >
        Are you sure you want to delete these procedure?
      </ModalWindowConfirm>

      <Notification />
    </PageWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const СolumnTape = styled.div`
  display: flex;
  flex-direction: column;
`;

const RouteBlock = styled(RouteBlockBase)`
  margin-bottom: 40px;
`;

const TableWrapper = styled.div`
  margin: 40px 200px 0 0;
`;

const ExportButton = styled(Button)`
  width: 100px;
`;

export default LandingPageQueryResults;
