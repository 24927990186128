import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { PageWrapper, Table, Pagination } from '../features';
import { Title, Button as ButtonBase, Header } from '../atoms';
import { Search as SearchBase, Loader } from '../molecules';
import { TableWrapper as CustomTable } from '../features/Table';
import { HeaderCell as CustomCellBase } from '../molecules/Thead';
import { ExaminationType } from '../../types/procedures';
import { StudyType } from '../../types/studies';
import { url } from '../../router/routes';
import { ModalQueryData } from '../organisms';

const caseActions = {
  NAME: 'name',
  PROCEDURES_NUMBER: 'proceduresNumber',
  SIX_MONTH_SUCCESS_RATE: 'sixMonthSuccessRate',
  YEAR_SUCCESS_RATE: 'yearSuccessRate',
  NO_SUCCESS_RATE: 'noSuccessRate',
};

const studyActions = {
  NAME: 'name',
  ACTUAL: 'actual',
  TARGET: 'target',
  PERCENTAGES: 'percentages',
};

const tables = {
  PROCEDURES: 'procedures',
  STUDIES: 'studies',
};

const LandingPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const landingPage = useSelector((state: RootState) => state.landingPage);

  const [queryIsOpen, setQueryIsOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [proceduresData, setProceduresData] = useState<ExaminationType[]>([]);
  const [studiesData, setStudiesData] = useState<StudyType[]>([]);
  const [loading, setLoading] = useState(false);

  const studiesWithPercentages = studiesData.map((study) => {
    const percentages = `${((Number(study.actual) * 100) / study.target).toFixed(2)}%`;

    return {
      ...study,
      percentages: study.actual ? percentages : '0%',
    };
  });
  const proceduresWithPercentages = proceduresData.map((process) => {
    const sixMonthSuccessRate = `${(
      (Number(process.sixMonthSuccessRate) * 100) /
      Number(process.proceduresNumber)
    ).toFixed(0)}%`;
    const yearSuccessRate = `${(
      (Number(process.yearSuccessRate) * 100) /
      Number(process.proceduresNumber)
    ).toFixed(0)}%`;
    const noSuccessRate = `${(
      (Number(process.noSuccessRate) * 100) /
      Number(process.proceduresNumber)
    ).toFixed(0)}%`;

    return {
      ...process,
      sixMonthSuccessRate,
      yearSuccessRate,
      noSuccessRate,
    };
  });

  const total = proceduresData.reduce((acc, item) => acc + Number(item.proceduresNumber), 0);
  // const totalSix = `${proceduresData.reduce(
  //   (acc, item) => (acc + Number(item.sixMonthSuccessRate) * 100) / Number(item.proceduresNumber),
  //   0
  // )}%`;
  // const totalYear = `${proceduresData.reduce(
  //   (acc, item) => (acc + Number(item.yearSuccessRate) * 100) / Number(item.proceduresNumber),
  //   0
  // )}%`;
  // const totalNoSuccess = `${proceduresData.reduce(
  //   (acc, item) => (acc + Number(item.noSuccessRate) * 100) / Number(item.proceduresNumber),
  //   0
  // )}%`;

  const caseData = useMemo(() => proceduresWithPercentages, [proceduresWithPercentages]);
  const caseColumns = useMemo(
    () => [
      {
        Header: 'Case type',
        accessor: caseActions.NAME,
      },
      {
        Header: 'Actual',
        accessor: caseActions.PROCEDURES_NUMBER,
      },
      {
        Header: 'Success 6 months',
        accessor: caseActions.SIX_MONTH_SUCCESS_RATE,
      },
      {
        Header: 'Success 12 months',
        accessor: caseActions.YEAR_SUCCESS_RATE,
      },
      {
        Header: 'No success rate',
        accessor: caseActions.NO_SUCCESS_RATE,
      },
    ],
    []
  );

  const studyData = useMemo(() => studiesWithPercentages, [studiesWithPercentages]);
  const studyColumns = useMemo(
    () => [
      {
        Header: 'Study name',
        accessor: studyActions.NAME,
      },
      {
        Header: 'Actual',
        accessor: studyActions.ACTUAL,
      },
      {
        Header: 'Target',
        accessor: studyActions.TARGET,
      },
      {
        Header: 'Percentage completed',
        accessor: studyActions.PERCENTAGES,
      },
    ],
    []
  );

  const handleClickField = (table: string) => (id: number) => {
    if (table === tables.PROCEDURES) {
      dispatch.procedures.getProcedure(id).then((response) => {
        history.push(
          `${url.LANDINGPAGE_QUERY_RESULTS}/?page=1&procedure=${response.name}&id=${id}`
        );
      });
    }

    if (table === tables.STUDIES) {
      dispatch.studies.getStudy(id).then((response) => {
        history.push(`${url.LANDINGPAGE_QUERY_RESULTS}/?page=1&study=${response.name}&id=${id}`);
      });
    }
  };

  const handlePageClick = (table: string) => (selectedPage: number) => {
    if (table === tables.PROCEDURES) {
      dispatch.landingPage.setProceduresQuery({
        ...landingPage.proceduresQuery,
        pageNumber: selectedPage,
      });
      dispatch.landingPage.getProcedures();
    }

    if (table === tables.STUDIES) {
      dispatch.landingPage.setStudiesQuery({
        ...landingPage.studiesQuery,
        pageNumber: selectedPage,
      });
      dispatch.landingPage.getStudies();
    }
  };

  const handleSearch = () => {
    if (search) {
      history.push(`${url.SEARCH}/?page=1&prev_page=landing-page&tab=patient&search=${search}`);
    }
  };

  useEffect(() => {
    setProceduresData(landingPage.procedures.results);
    setStudiesData(landingPage.studies.results);
  }, [landingPage.procedures.results, landingPage.studies.results]);

  useEffect(() => {
    setLoading(true);

    dispatch.landingPage.getProcedures().finally(() => setLoading(false));
    dispatch.landingPage.getStudies().finally(() => setLoading(false));
  }, []);

  return (
    <PageWrapper>
      <Header>
        <Title>Landing page</Title>

        <SearchBlock>
          <Search value={search} onChange={setSearch} onClick={handleSearch} />
          <Button onClick={() => setQueryIsOpen(true)}>Query data</Button>
        </SearchBlock>
      </Header>

      {loading ? (
        <Loader />
      ) : (
        <Tables>
          <TableWrapper>
            <TableTitle>Case dashboard</TableTitle>
            <Table
              data={caseData}
              columns={caseColumns}
              handleClickField={handleClickField(tables.PROCEDURES)}
            />

            <CustomTable>
              <thead>
                <tr>
                  <CustomCell>TOTAL</CustomCell>
                  <CustomCell>{total}</CustomCell>
                  <CustomCell />
                  <CustomCell />
                  <CustomCell />
                </tr>
              </thead>
            </CustomTable>

            <Pagination
              dataTable={landingPage.procedures}
              pageSize={landingPage.proceduresQuery.pageSize}
              activePage={landingPage.proceduresQuery.pageNumber}
              onPageChange={handlePageClick(tables.PROCEDURES)}
            />
          </TableWrapper>

          <TableWrapper>
            <TableTitle>Study dashboard</TableTitle>
            <Table
              data={studyData}
              columns={studyColumns}
              handleClickField={handleClickField(tables.STUDIES)}
            />

            <Pagination
              dataTable={landingPage.studies}
              pageSize={landingPage.studiesQuery.pageSize}
              activePage={landingPage.studiesQuery.pageNumber}
              onPageChange={handlePageClick(tables.STUDIES)}
            />
          </TableWrapper>
        </Tables>
      )}

      <ModalQueryData modalIsOpen={queryIsOpen} closeModal={() => setQueryIsOpen(false)} />
    </PageWrapper>
  );
};

const SearchBlock = styled.div`
  display: flex;
`;

const Search = styled(SearchBase)`
  width: 340px;
`;

const Button = styled(ButtonBase)`
  margin-left: 14px;
  width: 121px;
`;

const Tables = styled.div`
  display: flex;
`;

const TableWrapper = styled.div`
  &:first-of-type {
    margin-right: 56px;
  }
`;

const TableTitle = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  color: var(--color-dark);
`;

const CustomCell = styled(CustomCellBase)`
  border-top: none;
  width: 168px;
`;

export default LandingPage;
