import { AxiosResponse } from 'axios';
import { AuthResponseType, CheckValidTokenType } from '../types/auth';
import api, { apiPublic } from '../api/api';

const ACCESS = 'accessToken';
const REFRESH = 'refreshToken';

export const getAccessToken = (): string | null => localStorage.getItem(ACCESS);
export const getRefreshToken = (): string | null => localStorage.getItem(REFRESH);
export const setAccessToken = (token: string): void => localStorage.setItem(ACCESS, token);
export const setRefreshToken = (token: string): void => localStorage.setItem(REFRESH, token);
export const removeTokens = (): void => {
  localStorage.removeItem(ACCESS);
  localStorage.removeItem(REFRESH);
};
export const userIsAuth = (): boolean => {
  if (getAccessToken()) return true;
  return false;
};

export const logIn = ({ email, password }: LogInType): Promise<AxiosResponse<AuthResponseType>> => {
  return apiPublic.post<AuthResponseType>('/token/', { email, password });
};

export const changePassword = ({
  id,
  oldPassword,
  newPassword,
}: ChangePasswordType): Promise<void> => {
  return api.put(`/users/${id}/change_password/`, { oldPassword, newPassword });
};

export const checkAuth = (): Promise<AxiosResponse<AuthResponseType>> => {
  return apiPublic.post<AuthResponseType>('/token/refresh/', { refresh: getRefreshToken() });
};

export const checkValidToken = (): Promise<AxiosResponse<CheckValidTokenType>> => {
  return apiPublic.post<CheckValidTokenType>('/token/verify/', { token: getAccessToken() });
};

export type LogInType = {
  email: string;
  password: string;
};

export type ChangePasswordType = {
  id: number;
  oldPassword: string;
  newPassword: string;
};
