import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ExaminationType, SwitchType } from '../../types/procedures';
import { QueryType, PaginationResponseType } from '../../types/general';
import {
  getExamination,
  getExaminations,
  switchExamination,
} from '../../services/ProceduresServices';
import store from '../index';

type InitialStateType<Examination> = {
  data: PaginationResponseType<Examination>;
  query: QueryType;
};

const initialState: InitialStateType<ExaminationType> = {
  data: {
    count: 0,
    results: [],
  },
  query: {
    pageNumber: 1,
    pageSize: 20,
  },
};

const procedures = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setTable(state, data: PaginationResponseType<ExaminationType>) {
      return {
        ...state,
        data,
      };
    },
    setQuery(state, query: QueryType) {
      return {
        ...state,
        query,
      };
    },
    setClearProcedures() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async getTable() {
      try {
        const { query } = store.getState().procedures;
        const response = await getExaminations(query);

        dispatch.procedures.setTable(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getProcedure(id: number) {
      try {
        const response = await getExamination(id);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async switchExamination(data: SwitchType) {
      try {
        await switchExamination(data);
        dispatch.procedures.getTable();
      } catch (error) {
        throw error;
      }
    },
  }),
});

export default procedures;
