import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { PageWrapper, Table, Pagination } from '../features';
import { Heading, Button as ButtonBase, Header } from '../atoms';
import { ModalWindowConfirm, Search as SearchBase, Loader } from '../molecules';
import { useQuery } from '../../hooks';
import { url } from '../../router/routes';
import { ModalPatientTable, ModalAddProcedureData, ModalQueryData } from '../organisms';
import { PatientType } from '../../types/patients';
import Notification, { notify } from '../molecules/Notification';
import { userRoles } from './Profile';

export type StartExaminationIds = {
  procedure: number;
  patient: number;
};

const headerActions = {
  PATIENT_ID: 'id',
  DATE_OF_BIRTH: 'bornAt',
  CHANGE_DATE: 'changeTime',
  CHANGE_USER: 'changeUser',
  CREATE_DATE: 'createTime',
  CREATE_USER: 'createUser',
  FULL_NAME: 'fullName',
  ACTIONS: 'actions',
};

const modals = {
  ADD_FIELD: 'addField',
  DELETE_FIELD: 'deleteField',
  EDIT_FIELD: 'editField',
  ADD_PROCEDURE_DATA: 'addProcedureData',
  QUERY_DATA: 'queryData',
};

const Patients: FC = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch<Dispatch>();
  const patients = useSelector((state: RootState) => state.patients);
  const user = useSelector((state: RootState) => state.user.data?.group);

  const editor = user === userRoles.editor;
  const admin = user === userRoles.admin;

  const pageNumber = Number(query.get('page')) || 1;

  const [idOfSelectedPatient, setIdOfSelectedPatient] = useState<number | null>(null);
  const [selectedPatient, setSelectedPatient] = useState<PatientType | null>(null);
  const [activeModal, setActiveModal] = useState('');
  const [search, setSearch] = useState('');
  const [procedureTypeId, setProcedureTypeId] = useState<number | null>(null);
  const [patientId, setPatientId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const [dataTable, setDataTable] = useState(patients.data.results);

  const data = useMemo(
    () => dataTable.map((item) => ({ ...item, fullName: `${item.firstName} ${item.name}` })),
    [dataTable]
  );
  const columns = useMemo(
    () => [
      {
        Header: 'Patient ID',
        accessor: headerActions.PATIENT_ID,
      },
      {
        Header: 'Date of birth ',
        accessor: headerActions.DATE_OF_BIRTH,
      },
      {
        Header: 'Patient Name',
        accessor: headerActions.FULL_NAME,
      },
      {
        Header: 'Change date',
        accessor: headerActions.CHANGE_DATE,
      },
      {
        Header: 'Change user',
        accessor: headerActions.CHANGE_USER,
      },
      {
        Header: 'Create date',
        accessor: headerActions.CREATE_DATE,
      },
      {
        Header: 'Create user',
        accessor: headerActions.CREATE_USER,
      },
      {
        Header: 'Actions',
        accessor: headerActions.ACTIONS,
      },
    ],
    []
  );

  const handleAction = (action: string) => (id: number) => {
    if (action === 'edit') {
      history.push(`${url.PATIENT}/?page=1&id=${id}`);
    } else if (action === 'delete') {
      setIdOfSelectedPatient(id);
      setActiveModal(modals.DELETE_FIELD);
    }
  };

  const handleModalDelete = () => {
    if (idOfSelectedPatient) {
      dispatch.patients.deletePatient(idOfSelectedPatient).then(() => dispatch.patients.getTable());
    }
  };

  const handleCloseModal = () => {
    setActiveModal('');
  };

  const handlePageClick = (selectedPage: number) => {
    dispatch.patients.setQuery({ ...patients.query, pageNumber: selectedPage });
    dispatch.patients.getPatients();
    history.push(`${url.PATIENTS}/?page=${selectedPage}`);
  };

  const handleClickSearch = () => {
    if (search) {
      history.push(`${url.SEARCH}/?page=1&prev_page=patients&tab=patient&search=${search}`);
    }
  };

  const handleStartExamination = (ids: StartExaminationIds) => {
    setProcedureTypeId(ids.procedure);
    setPatientId(ids.patient);
    setActiveModal(modals.ADD_PROCEDURE_DATA);
  };

  useEffect(() => {
    setLoading(true);
    dispatch.patients.setQuery({ ...patients.query, pageNumber });
    dispatch.patients.getPatients().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setDataTable(patients.data.results);
  }, [patients]);

  return (
    <PageWrapper>
      <Header>
        <Heading
          path={`${url.PATIENTS}/?page=1`}
          onClick={() => {
            dispatch.patients.setClearPatients();
            dispatch.patients.getPatients();
          }}
        >
          Patients
        </Heading>

        <ButtonBlock>
          <Search
            onChange={setSearch}
            placeholder="Search"
            value={search}
            onClick={handleClickSearch}
          />

          <Button onClick={() => setActiveModal(modals.QUERY_DATA)}>Query data</Button>

          {(admin || editor) && (
            <Button onClick={() => setActiveModal(modals.ADD_FIELD)}>Add patient</Button>
          )}
        </ButtonBlock>
      </Header>

      {loading ? (
        <Loader />
      ) : (
        <>
          <TableWrapper>
            {admin ? (
              <Table
                data={data}
                columns={columns}
                handleEdit={handleAction('edit')}
                handleDelete={handleAction('delete')}
              />
            ) : (
              <Table data={data} columns={columns} handleEdit={handleAction('edit')} />
            )}
          </TableWrapper>

          <Pagination
            dataTable={patients.data}
            pageSize={patients.query.pageSize}
            onPageChange={handlePageClick}
            activePage={pageNumber}
          />
        </>
      )}

      <ModalPatientTable
        title="Add patient"
        modalIsOpen={activeModal === modals.ADD_FIELD}
        closeModal={handleCloseModal}
        startExamination={handleStartExamination}
        notify={notify}
      />

      <ModalPatientTable
        title="Edit patient"
        modalIsOpen={activeModal === modals.EDIT_FIELD}
        closeModal={handleCloseModal}
        selectedPatient={selectedPatient}
        setSelectedPatient={setSelectedPatient}
        notify={notify}
      />

      <ModalWindowConfirm
        title="Delete patient"
        modalIsOpen={activeModal === modals.DELETE_FIELD}
        closeModal={handleCloseModal}
        saveInfo={handleModalDelete}
      >
        Are you sure you want to delete these patient data and all related procedure data
      </ModalWindowConfirm>

      <ModalAddProcedureData
        title="Add procedure data"
        modalIsOpen={activeModal === modals.ADD_PROCEDURE_DATA}
        closeModal={handleCloseModal}
        procedureTypeId={procedureTypeId}
        patientId={patientId}
      />

      <ModalQueryData
        modalIsOpen={activeModal === modals.QUERY_DATA}
        closeModal={handleCloseModal}
      />
      <Notification />
    </PageWrapper>
  );
};

const ButtonBlock = styled.div`
  display: flex;
`;

const Search = styled(SearchBase)`
  width: 340px;
`;

const Button = styled(ButtonBase)`
  margin-left: 14px;
  width: 121px;
`;

const TableWrapper = styled.div`
  margin-right: 200px;
`;

export default Patients;
