import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { version } from '../../../package.json';
import { Dispatch, RootState } from '../../store';
import { PageWrapper } from '../features';
import { Title, Header, Button, Icon, Error } from '../atoms';
import { Input as InputBase } from '../molecules';
import Icons from '../../assets/icons';
import { ErrorType } from '../../types/general';
import { uniqueErrors } from '../../utils';
import Notification, { notify, notifitationVariants } from '../molecules/Notification';

export const userRoles = {
  admin: 'Administrators',
  editor: 'Editors',
  viewer: 'Viewers',
};

const descriptionError = 'This field may not be blank.';
const descriptionErrorMatch = 'Passwords must match';

const notificationSuccess = 'Data updated';
const notificationError = 'Data not updated';

const Profile: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.user.data);

  const viewer = user?.group === userRoles.viewer;

  const [buttonIsActive, setButtonIsActive] = useState(false);
  const [resetPasswordIsOpen, setResetPasswordIsOpen] = useState(false);
  const [errorMatch, setErrorMatch] = useState<ErrorType | null>(null);
  const [error, setError] = useState<ErrorType | null>(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!buttonIsActive) {
      setButtonIsActive(true);

      if (resetPasswordIsOpen) {
        if (checkEmptyFields()) {
          return;
        }

        if (newPassword !== confirmNewPassword) {
          setErrorMatch({
            newPassword: [descriptionErrorMatch],
            confirmNewPassword: [descriptionErrorMatch],
          });
          return;
        }

        setErrorMatch(null);

        if (user?.id) {
          const data = {
            id: user.id,
            oldPassword: currentPassword,
            newPassword,
          };

          dispatch.user
            .changePassword(data)
            .then(() => {
              notify(notificationSuccess);
              setCurrentPassword('');
              setNewPassword('');
              setConfirmNewPassword('');
              setError(null);
            })
            .catch((err) => {
              notify(notificationError, notifitationVariants.ERROR);
              setError(err.response.data);
            })
            .finally(() => setButtonIsActive(false));
        }
      } else {
        if (checkEmptyFields()) {
          return;
        }

        const profile = {
          email,
          firstName,
          lastName,
        };

        dispatch.user
          .updateCurrentUser(profile)
          .then(() => {
            notify(notificationSuccess);
            setError(null);
          })
          .catch((err) => {
            notify(notificationError, notifitationVariants.ERROR);
            setError(err.response.data);
          })
          .finally(() => setButtonIsActive(false));
      }
    }
  };

  const handleResetPassword = (status: boolean) => {
    setResetPasswordIsOpen(status);
    setErrorMatch(null);
    setError(null);
  };

  const checkEmptyFields = () => {
    const updatedError: ErrorType = {};

    if (!resetPasswordIsOpen) {
      if (firstName === '') {
        updatedError.firstName = [descriptionError];
      }

      if (lastName === '') {
        updatedError.lastName = [descriptionError];
      }

      if (email === '') {
        updatedError.email = [descriptionError];
      }
    }

    if (resetPasswordIsOpen) {
      if (currentPassword === '') {
        updatedError.currentPassword = [descriptionError];
      }

      if (newPassword === '') {
        updatedError.newPassword = [descriptionError];
      }

      if (confirmNewPassword === '') {
        updatedError.confirmNewPassword = [descriptionError];
      }
    }

    if (Object.keys(updatedError).length === 0) {
      setError(null);
      return false;
    }

    setError(updatedError);
    return true;
  };

  useEffect(() => {
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
    setEmail(user?.email || '');
  }, [user]);

  return (
    <PageWrapper>
      <Header>
        <Title>Profile</Title>

        <Title>App Version: {version}</Title>
      </Header>

      {resetPasswordIsOpen && (
        <Reset withArrow onClick={() => handleResetPassword(false)}>
          <Arrow src={Icons.ArrowLeftWithoutCircle} />
          Reset password
        </Reset>
      )}

      <Fields onSubmit={handleSubmit}>
        {!resetPasswordIsOpen && (
          <>
            <Input
              value={firstName}
              onChange={setFirstName}
              placeholder="Name"
              errorBorder={!!error?.firstName}
              required
            />

            <Input
              value={lastName}
              onChange={setLastName}
              placeholder="Lastname"
              errorBorder={!!error?.lastName}
              required
            />

            <Input
              value={email}
              onChange={setEmail}
              placeholder="Email"
              errorBorder={!!error?.email}
              readOnly={viewer}
              required
            />

            <Reset onClick={() => handleResetPassword(true)}>Reset password</Reset>
          </>
        )}

        {resetPasswordIsOpen && (
          <>
            <Input
              value={currentPassword}
              onChange={setCurrentPassword}
              placeholder="Current password"
              errorBorder={!!error?.currentPassword}
              required
            />
            <Input
              value={newPassword}
              onChange={setNewPassword}
              placeholder="New password"
              errorBorder={!!error?.newPassword || !!errorMatch?.newPassword}
              required
              resetPasswordIsActive
            />

            <Prompt>Use 8 or more characters with a mix of letters, numbers & symbols</Prompt>

            <Input
              value={confirmNewPassword}
              onChange={setConfirmNewPassword}
              placeholder="Confirm new password"
              errorBorder={!!error?.confirmNewPassword || !!errorMatch?.confirmNewPassword}
              required
            />
          </>
        )}

        {uniqueErrors(error) && <Error error={uniqueErrors(error) || []} />}

        {uniqueErrors(errorMatch) && <Error error={uniqueErrors(errorMatch) || []} />}

        <Button disabled={buttonIsActive} secondary type="submit">
          Save
        </Button>
      </Fields>

      <Notification />
    </PageWrapper>
  );
};

const Fields = styled.form`
  display: flex;
  flex-direction: column;
  width: 464px;
`;

const Input = styled(InputBase)<InputType>`
  margin-bottom: 16px;

  ${({ resetPasswordIsActive }) =>
    resetPasswordIsActive &&
    `
    margin-bottom: 8px;
  `}
`;

const Reset = styled.div<ResetType>`
  margin: 24px 0 40px 0;

  ${({ withArrow }) =>
    withArrow &&
    `
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
  `}

  cursor: pointer;
`;

const Arrow = styled(Icon)`
  position: relative;
  top: 1px;
`;

const Prompt = styled.div`
  margin-bottom: 16px;
  font-size: 10px;
  color: var(--color-dark);
`;

type ResetType = {
  withArrow?: boolean;
};

type InputType = {
  resetPasswordIsActive?: boolean;
};

export default Profile;
