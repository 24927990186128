import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Icon } from '../atoms';
import Icons from '../../assets/icons';
import { conversionOptions } from '../organisms/ModalExaminationTable';
import { userRoles } from '../pages/Profile';

type Props<T extends {}> = {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<T> | undefined) => TableBodyProps;
  rows: Row<T>[];
  prepareRow: (row: Row<T>) => void;
  handleEdit?: (id: number) => void;
  handleDelete?: (id: number) => void;
  setCurrentFieldId?: (id: number) => void;
  setHoverFieldId?: (id: number) => void;
  handleClickField?: (id: number) => void;
  handleViewButton?: (id: number) => void;
  handleAttachedFiles?: (id: number) => void;
  dragAndDrop?: boolean;
  isActiveVariant?: string;
  search?: string;
};

export const isActiveVariants = {
  toggle: 'toggle',
  trueOrFalse: 'trueOrFalse',
};

const Tbody = <T extends {}>({
  getTableBodyProps,
  rows,
  prepareRow,
  handleEdit,
  handleDelete,
  setCurrentFieldId,
  setHoverFieldId,
  handleClickField,
  handleViewButton,
  handleAttachedFiles,
  dragAndDrop,
  isActiveVariant = isActiveVariants.trueOrFalse,
  search,
}: Props<T>): JSX.Element => {
  const user = useSelector((state: RootState) => state.user.data?.group);
  const viewer = user === userRoles.viewer;
  const editor = user === userRoles.editor;
  const admin = user === userRoles.admin;

  const [onMouse, setOnMouse] = useState(false);

  const onClickField = (id: number) => {
    if (handleClickField) {
      handleClickField(id);
    }
  };

  const dragOverHandler = (e: React.DragEvent<HTMLTableRowElement>) => {
    if (dragAndDrop) {
      e.preventDefault();
    }
  };

  const dragStartHandler = (row: Row<T>) => {
    if (dragAndDrop) {
      // @ts-ignore
      setCurrentFieldId(row.original.id);
    }
  };

  const dropHandler = (e: React.DragEvent<HTMLTableRowElement>, row: Row<T>) => {
    setOnMouse(false);

    if (dragAndDrop) {
      e.preventDefault();

      // @ts-ignore
      setHoverFieldId(row.original.id);
    }
  };

  const highlight = (text: string) => {
    if (search && text) {
      const regexp = new RegExp(search, 'ig');
      const matchValue = text.match(regexp);

      if (matchValue) {
        return text.split(regexp).map((item, index, array) => {
          if (index < array.length - 1) {
            const result = matchValue.shift();

            return (
              <>
                {item}
                <Highlight>{result}</Highlight>
              </>
            );
          }

          return item;
        });
      }
    }

    return text;
  };

  return (
    <TbodyWrapper {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <TableRow
            // @ts-ignore
            onClick={() => onClickField(row.original.id)}
            onDragOver={onMouse ? (e) => dragOverHandler(e) : undefined}
            onDragStart={() => dragStartHandler(row)}
            onDrop={(e) => dropHandler(e, row)}
            {...row.getRowProps()}
          >
            {row.cells.map((cell) => {
              const actions = cell.column.id === 'actions';
              const isActive = cell.column.id === 'isActive';

              // @ts-ignore
              const filesIsLoaded = row.original?.files?.length === 0;

              return (
                <DataCell {...cell.getCellProps()}>
                  {dragAndDrop && (
                    <DragAndDrop
                      onMouseDown={() => setOnMouse(true)}
                      className="dragndrop"
                      src={Icons.DragAndDrop}
                    />
                  )}

                  {
                    // if Cell is equal to searchResult value return with highlight text
                    // @ts-ignore
                    cell.render('Cell').props.column.id === 'searchResult' && // @ts-ignore
                      highlight(cell.render('Cell').props.row.original.searchResult, '1')
                  }

                  {
                    // if Cell is equal to DataType change data to converted data
                    // @ts-ignore
                    cell.render('Cell').props.column.id === 'dataType' &&
                      conversionOptions[cell.value]
                  }

                  {
                    // show results if Cell is not equal to searchResult and Cell is not equal to dataType
                    // @ts-ignore
                    cell.render('Cell').props.column.id !== 'searchResult' &&
                      // @ts-ignore
                      cell.render('Cell').props.column.id !== 'dataType' &&
                      cell.render('Cell')
                  }

                  {
                    // add buttons to 'actions' cells
                    actions && (
                      <Buttons>
                        {handleAttachedFiles && (
                          <IconFile
                            onClick={() => {
                              if (!filesIsLoaded) {
                                // @ts-ignore
                                handleAttachedFiles(row.original.id);
                              }
                            }}
                            src={Icons.Attachment}
                            isDeactivated={filesIsLoaded}
                          />
                        )}

                        {handleViewButton && (
                          <IconFile
                            // @ts-ignore
                            onClick={() => handleViewButton(row.original.id)}
                            src={Icons.View}
                          />
                        )}

                        {handleEdit && (
                          <IconWithHover
                            // @ts-ignore
                            onClick={() => handleEdit(row.original.id)}
                            src={viewer ? Icons.EyeOpen : Icons.Edit}
                          />
                        )}

                        {handleDelete && (admin || editor) && (
                          <IconTrash
                            // @ts-ignore
                            onClick={() => handleDelete(row.original.id)}
                            src={Icons.Trash}
                          />
                        )}
                      </Buttons>
                    )
                  }

                  {
                    // convert boolean value to string Yes or No
                    typeof cell.value === 'boolean' &&
                      isActiveVariant !== isActiveVariants.toggle &&
                      (cell.value ? 'Yes' : 'No')
                  }

                  {
                    // convert boolean value isActive to toggle Activated or Deactivated
                    isActiveVariant === isActiveVariants.toggle &&
                      isActive &&
                      (cell.value ? (
                        <Icon src={Icons.Activated} />
                      ) : (
                        <Icon src={Icons.Deactivated} />
                      ))
                  }
                </DataCell>
              );
            })}
          </TableRow>
        );
      })}
    </TbodyWrapper>
  );
};

const TbodyWrapper = styled.tbody``;

const TableRow = styled.tr`
  position: relative;

  &:hover .dragndrop {
    display: block;
  }

  &:hover {
    background-color: var(--color-hoverTable);
  }
`;

const DataCell = styled.td`
  padding-left: 16px;
  width: 200px;
  height: 40px;

  font-size: 13px;
  color: var(--color-dark);
  border: 1px solid var(--color-lines);

  &:first-of-type {
    padding-left: 24px;
  }
`;

const DragAndDrop = styled.img`
  display: none;
  position: absolute;
  top: 10px;
  left: 0;

  cursor: pointer;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
`;

const IconFile = styled(Icon)<DeactivatedIconType>`
  margin-right: 5px;

  &:hover {
    opacity: 0.7;
  }

  ${({ isDeactivated }) =>
    isDeactivated &&
    `
    opacity: 0.3;

    &:hover {
      opacity: 0.3;
    }
  `}
`;

const IconWithHover = styled(Icon)`
  margin-right: 5px;

  &:hover {
    opacity: 0.7;
  }
`;

const IconTrash = styled(Icon)`
  svg path {
    fill: var(--color-error);
  }

  &:hover {
    opacity: 0.7;
  }
`;

const Highlight = styled.span`
  color: var(--color-error);
`;

type DeactivatedIconType = {
  isDeactivated?: boolean;
};

export default Tbody;
