import React, { FC } from 'react';
import styled from '@emotion/styled';

type Props = {
  dataTable: {
    count: number;
    next?: string;
    previous?: string;
    results: Object[];
  };
  pageSize: number;
  onPageChange: (arg: number) => void;
  activePage: number;
  className?: string;
};

const Pagination: FC<Props> = ({ className, dataTable, pageSize, onPageChange, activePage }) => {
  const pages = Math.ceil(dataTable.count / pageSize) || 1;
  const arrayPages: number[] = Array.from(Array(pages).keys()).map((x) => 1 + x); // generate an array with page numbers

  const dynamicPagination = pages >= 10;

  const getPageRange = () => {
    if (activePage <= 5) {
      return {
        start: arrayPages.slice(0, 6),
        finish: arrayPages.slice(-2),
      };
    }

    if (activePage >= arrayPages.length - 4) {
      return {
        start: arrayPages.slice(0, 2),
        finish: arrayPages.slice(-6),
      };
    }

    return {
      start: arrayPages.slice(0, 2),
      middle: arrayPages.slice(activePage - 3, activePage + 2),
      finish: arrayPages.slice(-2),
    };
  };

  const { start } = getPageRange();
  const { middle } = getPageRange();
  const { finish } = getPageRange();

  const handleClickArrow = (route: string, page: number) => {
    if (route === 'next' && page + 1 <= arrayPages[arrayPages.length - 1]) {
      onPageChange(activePage + 1);
    }

    if (route === 'prev' && page - 1 >= arrayPages[0]) {
      onPageChange(activePage - 1);
    }
  };

  return (
    <>
      {dataTable.count > dataTable.results.length && (
        <Wrapper className={className}>
          <Arrow onClick={() => handleClickArrow('prev', activePage)}>{'<'}</Arrow>
          <NumberContainer>
            {!dynamicPagination &&
              arrayPages.map((page) => {
                return (
                  <Number
                    key={page}
                    activePage={page === activePage}
                    onClick={() => onPageChange(page)}
                  >
                    {page}
                  </Number>
                );
              })}

            {dynamicPagination &&
              start.map((page) => {
                return (
                  <Number
                    key={page}
                    activePage={page === activePage}
                    onClick={() => onPageChange(page)}
                  >
                    {page}
                  </Number>
                );
              })}

            {dynamicPagination && <Dots>...</Dots>}

            {dynamicPagination &&
              middle &&
              middle.map((page) => {
                return (
                  <Number
                    key={page}
                    activePage={page === activePage}
                    onClick={() => onPageChange(page)}
                  >
                    {page}
                  </Number>
                );
              })}

            {dynamicPagination && middle && <Dots>...</Dots>}

            {dynamicPagination &&
              finish.map((page) => {
                return (
                  <Number
                    key={page}
                    activePage={page === activePage}
                    onClick={() => onPageChange(page)}
                  >
                    {page}
                  </Number>
                );
              })}
          </NumberContainer>
          <Arrow onClick={() => handleClickArrow('next', activePage)}>{'>'}</Arrow>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const NumberContainer = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`;

const Number = styled.li<NumberType>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;

  height: 30px;
  width: 30px;

  border-radius: 50%;

  &:hover {
    background-color: #e0e0e0;
  }

  ${({ activePage }) =>
    activePage &&
    `
    background-color: #ebebeb;
  `}

  cursor: pointer;
  list-style-type: none;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;

  &:hover {
    border-radius: 50%;
    background-color: #e0e0e0;
  }

  cursor: pointer;
`;

const Dots = styled.div`
  position: relative;
  bottom: 6px;
  display: flex;
  align-items: flex-end;
`;

type NumberType = {
  activePage: boolean;
};

export default Pagination;
