import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PageWrapper } from '../features';
import { Dispatch } from '../../store';
import { Button as ButtonBase } from '../atoms';
import { ModalWindowConfirm } from '../molecules';
import { url } from '../../router/routes';

const Logout: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const [isOpen, setIsOpen] = useState(false);

  const LogOutHandler = () => {
    dispatch.user.LogOut();
    setIsOpen(false);
    history.push(url.LOGIN);
  };

  return (
    <PageWrapper>
      <Button onClick={() => setIsOpen(true)} secondary>
        Logout
      </Button>

      <ModalWindowConfirm
        title="Logout"
        modalIsOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        saveInfo={LogOutHandler}
      >
        Do you really want to leave?
      </ModalWindowConfirm>
    </PageWrapper>
  );
};

const Button = styled(ButtonBase)`
  width: 130px;
`;

export default Logout;
