import { AxiosResponse } from 'axios';
import api from '../api/api';
import {
  PatientType,
  SelectionType,
  FileType,
  TaskIdForZipFileType,
  ZipFileType,
  ProcedureDataType,
  ProcedureSearchDataType,
  QueryDataType,
} from '../types/patients';
import { QueryType, PaginationResponseType } from '../types/general';

export const getPatients = (
  query: QueryType
): Promise<AxiosResponse<PaginationResponseType<PatientType>>> => {
  return api.get<PaginationResponseType<PatientType>>(
    `/patients/?page=${query.pageNumber}&page_size=${query.pageSize}`
  );
};

export const getPatient = (id: number): Promise<AxiosResponse<PatientType>> => {
  return api.get<PatientType>(`/patients/${id}/`);
};
export const setPatient = (data: PatientType): Promise<AxiosResponse<PatientType>> => {
  return api.post<PatientType>(`/patients/`, data);
};

export const updatePatient = (data: PatientType): Promise<AxiosResponse<PatientType>> => {
  return api.put<PatientType>(`/patients/${data.id}/`, data);
};

export const deletePatient = (id: number): Promise<void> => {
  return api.delete(`/patients/${id}/`);
};

export const searchPatient = (
  query: QueryType
): Promise<AxiosResponse<PaginationResponseType<PatientType>>> => {
  return api.get<PaginationResponseType<PatientType>>(
    `/search/patient/?page=${query.pageNumber}&page_size=${query.pageSize}&search=${query.search}`
  );
};

export const searchProcedure = (
  query: QueryType
): Promise<AxiosResponse<PaginationResponseType<ProcedureSearchDataType>>> => {
  return api.get<PaginationResponseType<ProcedureSearchDataType>>(
    `/search/procedure/?page=${query.pageNumber}&page_size=${query.pageSize}&search=${query.search}`
  );
};

export const getSelections = (
  type: string
): Promise<AxiosResponse<PaginationResponseType<SelectionType>>> => {
  return api.get<PaginationResponseType<SelectionType>>(`/selections/${type}/`);
};

export const getFiles = (): Promise<AxiosResponse<PaginationResponseType<FileType>>> => {
  return api.get<PaginationResponseType<FileType>>(`/procedure_files/`, headersFile);
};

export const getPatientFiles = (
  id: number
): Promise<AxiosResponse<PaginationResponseType<FileType>>> => {
  return api.get<PaginationResponseType<FileType>>(
    `/procedure_files/?patient_id=${id}`,
    headersFile
  );
};

export const getTaskIdForDownloadZip = ({
  stringWithFilesIds = '',
  procedureTypeId,
  procedureId,
}: {
  stringWithFilesIds?: string;
  procedureTypeId: number;
  procedureId: number;
}): Promise<AxiosResponse<TaskIdForZipFileType>> => {
  return api.get<TaskIdForZipFileType>(
    `/procedure_types/${procedureTypeId}/records/${procedureId}/zip_files/${stringWithFilesIds}`
  );
};

export const getZipWithProcedureFiles = (
  procedureTypeId: number,
  procedureId: number,
  taskId: string
): Promise<AxiosResponse<ZipFileType>> => {
  return api.get<ZipFileType>(
    `/procedure_types/${procedureTypeId}/records/${procedureId}/get_files/${taskId}/`
  );
};

export const prepareFile = (parameters: string): Promise<AxiosResponse<TaskIdForZipFileType>> => {
  return api.get<TaskIdForZipFileType>(`/query/prepare_file/${parameters}`);
};

export const getPreparedFile = (taskId: string): Promise<AxiosResponse<ZipFileType>> => {
  return api.get<ZipFileType>(`query/get_file/${taskId}`);
};

export const getFile = (id: number): Promise<AxiosResponse<FileType>> => {
  return api.get<FileType>(`/procedure_files/${id}`, headersFile);
};

export const setFile = (
  procedureTypeId: number,
  procedureId: number,
  file: FormData
): Promise<AxiosResponse<FileType>> => {
  return api.post<FileType>(
    `/procedure_types/${procedureTypeId}/records/${procedureId}/upload_files/`,
    file,
    headersFile
  );
};

export const deleteFile = (id: number): Promise<void> => {
  return api.delete(`/procedure_files/${id}`);
};

const headersFile = {
  headers: { 'Content-Type': 'multipart/form-data' },
};

export const getAllProcedureData = ({
  procedureTypeId,
  pageNumber = 1,
  pageSize = 20,
}: {
  procedureTypeId: number;
  pageNumber?: number;
  pageSize?: number;
}): Promise<AxiosResponse<PaginationResponseType<ProcedureDataType>>> => {
  return api.get<PaginationResponseType<ProcedureDataType>>(
    `/procedure_types/${procedureTypeId}/records/?page=${pageNumber}&page_size=${pageSize}`
  );
};

export const getAllProceduresForPatient = (
  patientId: number,
  query: QueryType
): Promise<AxiosResponse<PaginationResponseType<ProcedureDataType>>> => {
  return api.get<PaginationResponseType<ProcedureDataType>>(
    `/patients/${patientId}/procedures/?page=${query.pageNumber}&page_size=${query.pageSize}`
  );
};

export const getProceduresData = (
  procedureTypeId: number
): Promise<AxiosResponse<PaginationResponseType<ProcedureDataType>>> => {
  return api.get<PaginationResponseType<ProcedureDataType>>(
    `/procedure_types/${procedureTypeId}/records/`
  );
};

export const getProcedureData = (
  procedureTypeId: number,
  procedureId: number
): Promise<AxiosResponse<ProcedureDataType>> => {
  return api.get<ProcedureDataType>(`/procedure_types/${procedureTypeId}/records/${procedureId}/`);
};

export const setProcedureData = (
  procedureTypeId: number,
  data: ProcedureDataType
): Promise<AxiosResponse<ProcedureDataType>> => {
  return api.post<ProcedureDataType>(`/procedure_types/${procedureTypeId}/records/`, data);
};

export const updateProcedureData = (
  procedureTypeId: number,
  procedureId: number,
  data: ProcedureDataType
): Promise<AxiosResponse<ProcedureDataType>> => {
  return api.put<ProcedureDataType>(
    `/procedure_types/${procedureTypeId}/records/${procedureId}/`,
    data
  );
};

export const deleteProcedureData = (
  procedureTypeId: number,
  procedureId: number
): Promise<void> => {
  return api.delete(`/procedure_types/${procedureTypeId}/records/${procedureId}/`);
};

export const getQuery = (
  query: QueryType,
  parameters: string
): Promise<AxiosResponse<PaginationResponseType<QueryDataType>>> => {
  return api.get<PaginationResponseType<QueryDataType>>(
    `/query/?page=${query.pageNumber}&page_size=${query.pageSize}${parameters}`
  );
};
