import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ModalWindow, Input as InputBase } from '.';

type Props = {
  title: string;
  modalIsOpen: boolean;
  closeModal: () => void;
  saveInfo: () => void;
  inputValue: string;
  inputOnChange: (arg: string) => void;
  inputRequired?: boolean;
  placeholder?: string;
  error?: string[] | null;
  buttonDisabled?: boolean;
};

const ModalWindowInput: FC<Props> = ({
  title,
  modalIsOpen,
  closeModal,
  saveInfo,
  inputValue,
  inputOnChange,
  inputRequired,
  placeholder,
  error,
  buttonDisabled,
}) => {
  return (
    <ModalWindow
      title={title}
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
      saveInfo={saveInfo}
      buttonDisabled={buttonDisabled}
    >
      <Content>
        <Input
          required={inputRequired}
          value={inputValue}
          onChange={inputOnChange}
          placeholder={placeholder}
          error={error}
        />
      </Content>
    </ModalWindow>
  );
};

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const Input = styled(InputBase)`
  width: 340px;
`;

export default ModalWindowInput;
