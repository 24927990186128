import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import GlobalStyles from './styles';
import { RootState, Dispatch } from './store';
import { FirstLogin } from './components/features';
import AppRouter from './router/AppRouter';
import { url } from './router/routes';

const App: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.user.data);

  useEffect(() => {
    if (!user && location.pathname !== url.LOGIN) {
      dispatch.user.getCurrentUser();
    }
  }, []);

  return (
    <>
      <GlobalStyles />

      <FirstLogin />
      <AppRouter />
    </>
  );
};

export default App;
