import { AxiosResponse } from 'axios';
import { StudyType, StudyRequestType } from '../types/studies';
import { QueryType, PaginationResponseType } from '../types/general';
import api from '../api/api';
import { ProcedureDataType } from '../types/patients';

export const getStudies = (
  query: QueryType,
  parameters?: string
): Promise<AxiosResponse<PaginationResponseType<StudyType>>> => {
  return api.get<PaginationResponseType<StudyType>>(
    `/studies/?page=${query.pageNumber}&page_size=${query.pageSize}${parameters || ''}`
  );
};

export const getStudy = (id: number): Promise<AxiosResponse<StudyType>> => {
  return api.get<StudyType>(`/studies/${id}/`);
};

export const setStudy = (data: StudyRequestType): Promise<AxiosResponse<StudyType>> => {
  return api.post<StudyType>(`/studies/`, data);
};

export const updateStudy = (
  id: number,
  data: StudyRequestType
): Promise<AxiosResponse<StudyType>> => {
  return api.put<StudyType>(`/studies/${id}/`, data);
};

export const deleteStudy = (id: number): Promise<void> => {
  return api.delete(`/studies/${id}`);
};

export const getProcedures = ({
  id,
  pageNumber = 1,
  pageSize = 20,
}: {
  id: number;
  pageNumber?: number;
  pageSize?: number;
}): Promise<AxiosResponse<PaginationResponseType<ProcedureDataType>>> => {
  return api.get<PaginationResponseType<ProcedureDataType>>(
    `/query/?table=patient&field=procedures__studies&condition=exact&operator=and&value=${id}&page=${pageNumber}&page_size=${pageSize}`
  );
};
