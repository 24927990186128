import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { userRoles } from '../pages/Profile';
import {
  ModalWindow,
  FieldsTab,
  ProcedureDataForm,
  ProcedureDataSections,
  Multiselect,
  Select,
  Loader,
  LocalFiles,
  ServerFiles,
  File,
  ModalWindowConfirm,
} from '../molecules';
import { ErrorType } from '../../types/general';
import { StudyType as StudyTypeBase } from '../../types/studies';
import { SelectionType, ProcedureDataType, FileType } from '../../types/patients';
import { convertToListOfStringsFromSelections } from './ModalPatientTable';
import { uniqueErrors, isEqual } from '../../utils';

export type LocalFilesType = {
  [key: string]: FormData;
};

type StudyType = Omit<StudyTypeBase, 'target' | 'createdOn'>;

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  title: string;
  procedureId?: number | null;
  procedureTypeId?: number | null;
  patientId: number | null;
  procedureErrors?: ErrorType | null;
  fileError?: ErrorType | null;
  editMode?: boolean;
  goBack?: () => void;
  updateList?: () => void;
};

const convertToListOfOptions = (list: StudyType[]) => {
  return list.map(({ name, isActive, id }) => {
    return {
      name,
      isActive,
      id,
    };
  });
};

const convertOptionsToString = (list: StudyType[]) => {
  const filteredList = list.filter((item) => item.isActive === true);
  return filteredList.reduce((acc, item) => `${acc} ${item.name},`, '').slice(1, -1);
};

const initialCheckForMandatory = {
  physician: ['Please fill all mandatory fields'],
};

const ModalAddProcedureData: FC<Props> = ({
  procedureTypeId,
  procedureId,
  patientId,
  closeModal,
  goBack,
  updateList,
  editMode,
  ...props
}) => {
  const dispatch = useDispatch<Dispatch>();
  const examination = useSelector((state: RootState) => state.examination);
  const sections = useSelector((state: RootState) => state.examination.data.sections?.data);
  const examinationStudies = useSelector((state: RootState) => state.examination.data.studies);
  const studies = useSelector((state: RootState) => state.studies.data.results);
  const user = useSelector((state: RootState) => state.user.data?.group);
  const viewer = user === userRoles.viewer;
  const editor = user === userRoles.editor;
  const admin = user === userRoles.admin;

  const formatDate = 'yyyy-MM-dd';
  const currentDate = format(new Date(), formatDate);

  const [tabIsOpen, setTabIsOpen] = useState(true);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [buttonIsActive, setButtonIsActive] = useState(false);

  const [initialDataToEdit, setInitialDataToEdit] = useState<ProcedureDataType>({});
  const [initialStudies, setInitialStudies] = useState<number[]>([]);

  const [procedureData, setProcedureData] = useState<ProcedureDataType>({});
  const [examinationData, setExaminationData] = useState(examination.table);
  const [localFiles, setLocalFiles] = useState<FormData | null>(null);
  const [serverFiles, setServerFiles] = useState<FileType[] | null>(null);
  const [idsFilesToDelete, setIdsFilesToDelete] = useState<number[]>([]);
  const [procedureErrors, setProcedureErrors] = useState<ErrorType | null>(null);
  const [fileError, setFileError] = useState<string[] | null>(null);
  const [filteredStudies, setFilteredStudies] = useState<StudyType[]>([]);
  const [selectedStudiesIds, setSelectedStudiesIds] = useState<number[]>([]);
  const [physician, setPhysician] = useState<SelectionType[]>([]);
  const [checkForMandatory, setCheckForMandatory] = useState<ErrorType>(initialCheckForMandatory);
  const { isFileUploadAvailable } = examination.data;

  const examinationLoading = examinationData.length === 0;

  const allFieldsInSections = sections
    ? sections.reduce((acc: number[], item) => acc.concat(item.fields), [])
    : [];

  const filteredExaminationData = examinationData.filter(
    (field) => !allFieldsInSections.includes(field.id)
  );

  const dataInput = (name: string, value: string, required: boolean) => {
    setProcedureData({ ...procedureData, [name]: value });

    if (required && !value) {
      setCheckForMandatory({
        ...checkForMandatory,
        [name]: ['Please fill all mandatory fields'],
      });
    } else if (required && value) {
      delete checkForMandatory[name];
    }
  };

  const handleSaveInfo = () => {
    const mandatoryFields = { ...checkForMandatory };

    if (editMode) {
      Object.entries(procedureData).filter((field) => {
        if (typeof field[1] === 'boolean') {
          delete mandatoryFields[field[0]];
          return;
        }

        if (field[1]) {
          delete mandatoryFields[field[0]];
        }
      });
    }

    if (Object.keys(mandatoryFields).length !== 0) {
      setProcedureErrors({ ...mandatoryFields });
      return;
    }

    if (fileError && fileError.length !== 0) {
      return;
    }

    deleteServerFiles();
    saveProcedureData();
  };

  const saveProcedureData = () => {
    if (procedureTypeId) {
      const findPhysicianId = physician.find((item) => item.value === procedureData.physician)?.id;

      const standardFields = {
        date: currentDate,
        patient: patientId,
        physician: findPhysicianId,
        studies: selectedStudiesIds,
      };

      if (Object.keys(procedureData).length !== 0) {
        if (!buttonIsActive) {
          setButtonIsActive(true);

          if (editMode && procedureId) {
            const data = {
              procedureId,
              procedureTypeId,
              data: { ...procedureData, ...standardFields },
            };

            dispatch.patients
              .updateProcedureData(data)
              .then(() => {
                saveFiles(procedureId);
                clearData();
                closeModal();

                if (updateList) {
                  updateList();
                }
              })
              .catch((e) => {
                if (e.response?.data) {
                  setProcedureErrors(e.response.data);
                }
              })
              .finally(() => setButtonIsActive(false));
          } else {
            const data = {
              procedureTypeId,
              data: {
                ...procedureData,
                ...standardFields,
                six_month_success: procedureData.six_month_success === 'True',
                year_success: procedureData.year_success === 'True',
              },
            };

            dispatch.patients
              .addProcedureData(data)
              .then((response) => {
                saveFiles(response.id);
                clearData();
                closeModal();

                if (updateList) {
                  updateList();
                }
              })
              .catch((e) => {
                if (e.response?.data) {
                  setProcedureErrors(e.response.data);
                }
              })
              .finally(() => setButtonIsActive(false));
          }
        }
      }
    }
  };

  const saveFiles = (id: number) => {
    if (procedureTypeId && localFiles) {
      const dataForSending = {
        procedureTypeId,
        procedureId: id,
        file: localFiles,
      };

      dispatch.patients
        .addFile(dataForSending)
        .then(() => {
          setFileError(null);

          if (updateList) {
            updateList();
          }
        })
        .catch((e) => {
          if (e.response?.data) {
            setFileError(e.response.data);
          }
        });
    }
  };

  const handleMultiselect = (id: number) => {
    const updatedStudies = filteredStudies.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isActive: !item.isActive,
        };
      }

      return item;
    });

    setFilteredStudies(updatedStudies);

    if (selectedStudiesIds.includes(id)) {
      const updatedIds = selectedStudiesIds.filter((number) => id !== number);
      setSelectedStudiesIds(updatedIds);
    } else {
      const updatedIds = [...selectedStudiesIds, id];
      setSelectedStudiesIds(updatedIds);
    }
  };

  const handleBackArrow = () => {
    if (goBack) {
      setProcedureData({});
      setProcedureErrors(null);
      setCheckForMandatory(initialCheckForMandatory);

      goBack();
    }
  };

  const deleteServerFiles = () => {
    if (idsFilesToDelete.length !== 0) {
      idsFilesToDelete.map((id) => {
        dispatch.patients.deleteFile(id).then(() => {
          if (updateList) {
            updateList();
          }
        });
      });
    }
  };

  const setServerFileId = (id: number) => {
    const filteredServerFiles = serverFiles?.filter((file) => file.id !== id);
    setIdsFilesToDelete([...idsFilesToDelete, id]);

    if (filteredServerFiles) {
      setServerFiles(filteredServerFiles);
    }
  };

  const clearData = () => {
    setProcedureData({});
    setExaminationData([]);
    setFilteredStudies([]);
    setSelectedStudiesIds([]);
    setProcedureErrors(null);
    setFileError(null);
    setLocalFiles(null);
    setServerFiles(null);
    setIdsFilesToDelete([]);
    setTabIsOpen(true);
    setPhysician([]);
    setCheckForMandatory(initialCheckForMandatory);
  };

  const handleUnsavedChanges = () => {
    const studiesIsEqual = initialStudies.every((item) => selectedStudiesIds.includes(item));
    const studiesLengthIsNotEquial = initialStudies.length !== selectedStudiesIds.length;
    const filesToDelete = idsFilesToDelete.length !== 0;

    if (studiesLengthIsNotEquial || !studiesIsEqual || filesToDelete || localFiles) {
      setUnsavedChangesModal(true);
      return;
    }

    if (procedureId) {
      if (!isEqual(initialDataToEdit, procedureData)) {
        setUnsavedChangesModal(true);
        return;
      }
    } else {
      const initialProcedureData = {};

      if (!isEqual(initialProcedureData, procedureData)) {
        setUnsavedChangesModal(true);
        return;
      }
    }

    closeModal();
    clearData();
  };

  const loadDataToEdit = () => {
    if (procedureId && procedureTypeId) {
      dispatch.patients
        .getProcedureData({
          procedureId,
          procedureTypeId,
        })
        .then((fields) => {
          setSelectedStudiesIds(fields.studies);
          setInitialStudies(fields.studies);

          delete fields.studies;
          delete fields.patient;
          delete fields.date;
          delete fields.id;

          const data = {
            ...fields,
            physician: physician.find((item) => item.id === fields.physician)?.value,
            year_success: fields.year_success ? 'True' : 'False',
            six_month_success: fields.six_month_success ? 'True' : 'False',
          };

          setProcedureData(data);
          setInitialDataToEdit(data);
        });
    }
  };

  useEffect(() => {
    if (editMode && patientId) {
      dispatch.patients.getPatientFiles(patientId).then((response) => {
        const filesOfCurrentProcedure = response.results.filter(
          (item) => item.procedure === procedureId
        );

        setServerFiles(filesOfCurrentProcedure);
      });

      loadDataToEdit();
    }
  }, [patientId, procedureTypeId, procedureId]);

  useEffect(() => {
    const getValidStudies = studies.filter((item) => examinationStudies?.includes(item.id));

    const convertToDisplayStudies = getValidStudies.map(({ id, name }) => {
      if (selectedStudiesIds.includes(id)) {
        return {
          id,
          name,
          isActive: true,
        };
      }

      return {
        id,
        name,
        isActive: false,
      };
    });

    setFilteredStudies(convertToDisplayStudies);
  }, [studies, selectedStudiesIds]);

  useEffect(() => {
    const requiredFields: ErrorType = {};

    examinationData.map((item) => {
      if (!item.null) {
        requiredFields[item.name] = ['Please fill all mandatory fields'];
      }
    });

    setCheckForMandatory({ ...initialCheckForMandatory, ...requiredFields });
  }, [examinationData]);

  useEffect(() => {
    setExaminationData(examination.table);
  }, [examination]);

  useEffect(() => {
    if (procedureTypeId) {
      const data = {
        id: procedureTypeId,
        pageNumber: 1,
      };

      dispatch.examination.getExamination(data);
    }

    dispatch.studies.getTable();
    dispatch.patients.getSelections('physician').then((res) => setPhysician(res.results));
  }, [procedureTypeId]);

  return (
    <ModalWindow
      goBack={goBack ? handleBackArrow : undefined}
      saveInfo={handleSaveInfo}
      closeModal={handleUnsavedChanges}
      error={uniqueErrors(procedureErrors)}
      buttonDisabled={buttonIsActive || viewer}
      {...props}
    >
      {examinationLoading ? (
        <CenteringWrapper>
          <Loader />
        </CenteringWrapper>
      ) : (
        <FieldsTab isOpen={tabIsOpen} setIsOpen={setTabIsOpen} title={examination.data.name}>
          <CenteringWrapper>
            <Content>
              <Select
                value={procedureData.physician || ''}
                onChange={(value) => {
                  dataInput('physician', value, true);
                }}
                options={convertToListOfStringsFromSelections(physician)}
                placeholder="Physician"
                errorBorder={!!procedureErrors?.physician}
                readOnly={viewer}
                required
              />

              <ProcedureDataForm
                fields={filteredExaminationData}
                procedureData={procedureData}
                dataInput={dataInput}
                procedureErrors={procedureErrors}
              />

              <Select
                value={procedureData.six_month_success || ''}
                onChange={(value) => {
                  dataInput('six_month_success', value, true);
                }}
                options={['True', 'False']}
                placeholder="6 months success"
                errorBorder={!!procedureErrors?.sixMonthSuccess}
                readOnly={viewer}
                required
              />

              <Select
                value={procedureData.year_success || ''}
                onChange={(value) => {
                  dataInput('year_success', value, true);
                }}
                options={['True', 'False']}
                placeholder="12 months success"
                errorBorder={!!procedureErrors?.yearSuccess}
                readOnly={viewer}
                required
              />

              {filteredStudies.length > 0 && (
                <SectionStudies>
                  <SectionTitle>Studies</SectionTitle>

                  <Multiselect
                    value={convertOptionsToString(filteredStudies) || ''}
                    onClick={handleMultiselect}
                    options={convertToListOfOptions(filteredStudies)}
                    placeholder="List of studies"
                    errorBorder={!!procedureErrors?.studies}
                    readOnly={viewer}
                  />
                </SectionStudies>
              )}

              <ProcedureDataSections
                sections={sections || []}
                examinationData={examinationData}
                procedureData={procedureData}
                dataInput={dataInput}
                procedureErrors={procedureErrors}
              />

              {isFileUploadAvailable && (
                <SectionFile>
                  <SectionTitle>File attachments</SectionTitle>

                  {localFiles && (
                    <LocalFiles localFiles={localFiles} setLocalFiles={setLocalFiles} />
                  )}

                  {serverFiles && (
                    <ServerFiles
                      serverFiles={serverFiles}
                      onClick={setServerFileId}
                      procedureTypeId={procedureTypeId}
                      procedureId={procedureId}
                    />
                  )}

                  {(admin || editor) && (
                    <File
                      setLocalFiles={setLocalFiles}
                      procedure={procedureTypeId}
                      error={fileError}
                      setFileError={setFileError}
                    />
                  )}
                </SectionFile>
              )}
            </Content>
          </CenteringWrapper>
        </FieldsTab>
      )}

      <ModalWindowConfirm
        title="Unsaved changes"
        modalIsOpen={unsavedChangesModal}
        closeModal={() => setUnsavedChangesModal(false)}
        saveInfo={() => {
          closeModal();
          clearData();
        }}
      >
        Are you sure you want to discard your entries?
      </ModalWindowConfirm>
    </ModalWindow>
  );
};

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

const Content = styled.div`
  width: 340px;

  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SectionStudies = styled.div``;

const SectionTitle = styled.div`
  margin-bottom: 16px;
  font-weight: 600;
`;

const SectionFile = styled.div``;

export default ModalAddProcedureData;
