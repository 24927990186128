import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { url } from '../../router/routes';
import { userIsAuth } from '../../services/AuthServices';

type Props = {
  component: FC;
  path: string;
  exact?: boolean;
};

const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={() => (userIsAuth() ? <Component /> : <Redirect to={url.LOGIN} />)} />
  );
};

export default PrivateRoute;
