import { ErrorType } from '../types/general';

const uniqueErrors = (errors: ErrorType | undefined | null): string[] | null => {
  if (errors) {
    const bugList: string[] = [];
    const filteredList: string[] = [];

    Object.values(errors).map((error) => {
      bugList.push(error[0]);
    });

    bugList.map((item) => {
      if (!filteredList.includes(item)) {
        filteredList.push(item);
      }
    });

    return filteredList;
  }

  return null;
};

export default uniqueErrors;
