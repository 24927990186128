import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { useQuery } from '../../hooks';
import { PageWrapper, Table, Pagination } from '../features';
import { Heading, Button as ButtonBase, Header } from '../atoms';
import { StudyType } from '../../types/studies';
import { url } from '../../router/routes';
import { isActiveVariants } from '../molecules/Tbody';
import { ModalStudyTable } from '../organisms';
import { ModalWindowConfirm, Loader } from '../molecules';

const headerActions = {
  ID: 'id',
  NAME: 'name',
  DATE: 'createdOn',
  TARGET: 'target',
  STATUS: 'isActive',
  ACTIONS: 'actions',
};

const modals = {
  ADD_STUDY: 'addStudy',
  EDIT_STUDY: 'editStudy',
  DELETE_STUDY: 'deleteStudy',
};

const Studies: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const studies = useSelector((state: RootState) => state.studies);

  const pageNumber = Number(query.get('page')) || 1;
  const [activeModal, setActiveModal] = useState('');
  const [loading, setLoading] = useState(false);

  const [masterData, setMasterData] = useState<StudyType[]>([]);
  const [studyId, setStudyId] = useState<number | null>(null);

  const data = useMemo(() => masterData, [masterData]);
  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: headerActions.ID,
      },
      {
        Header: 'Name',
        accessor: headerActions.NAME,
      },
      {
        Header: 'Creation date',
        accessor: headerActions.DATE,
      },
      {
        Header: 'Target',
        accessor: headerActions.TARGET,
      },
      {
        Header: 'Status',
        accessor: headerActions.STATUS,
      },
      {
        Header: 'Actions',
        accessor: headerActions.ACTIONS,
      },
    ],
    []
  );

  const handleAction = (action: string) => (id: number) => {
    if (action === 'edit') {
      setStudyId(id);
      setActiveModal(modals.EDIT_STUDY);
    } else if (action === 'delete') {
      setStudyId(id);
      setActiveModal(modals.DELETE_STUDY);
    }
  };

  const handleModalDelete = () => {
    if (studyId) {
      dispatch.studies.deleteStudy(studyId).then(() => {
        dispatch.studies.getTable();
      });
    }
  };

  const handlePageClick = (selectedPage: number) => {
    history.push(`${url.STUDIES}/?page=${selectedPage}`);
    dispatch.studies.setQuery({ ...studies.query, pageNumber: selectedPage });
    dispatch.studies.getTable();
  };

  const handleCloseModal = () => {
    setStudyId(null);
    setActiveModal('');
  };

  useEffect(() => {
    setMasterData(studies.data.results);
  }, [studies]);

  useEffect(() => {
    setLoading(true);

    dispatch.studies.setQuery({ ...studies.query, pageNumber });
    dispatch.studies.getTable().finally(() => setLoading(false));
  }, []);

  return (
    <PageWrapper>
      <Header>
        <Heading
          path={`${url.STUDIES}/?page=1`}
          onClick={() => {
            dispatch.studies.setClearStudies();
            dispatch.studies.getTable();
          }}
        >
          Studies
        </Heading>

        <Button onClick={() => setActiveModal(modals.ADD_STUDY)}>Add study</Button>
      </Header>

      {loading ? (
        <Loader />
      ) : (
        <TableWrapper>
          <Table
            data={data}
            columns={columns}
            isActiveVariant={isActiveVariants.toggle}
            handleEdit={handleAction('edit')}
            handleDelete={handleAction('delete')}
          />

          <Pagination
            dataTable={studies.data}
            pageSize={studies.query.pageSize}
            activePage={pageNumber}
            onPageChange={handlePageClick}
          />
        </TableWrapper>
      )}

      <ModalStudyTable
        title="Add study"
        modalIsOpen={activeModal === modals.ADD_STUDY}
        closeModal={handleCloseModal}
      />

      <ModalStudyTable
        title="Edit study"
        modalIsOpen={activeModal === modals.EDIT_STUDY}
        closeModal={handleCloseModal}
        studyId={studyId}
      />

      <ModalWindowConfirm
        title="Delete study"
        modalIsOpen={activeModal === modals.DELETE_STUDY}
        closeModal={handleCloseModal}
        saveInfo={handleModalDelete}
      >
        Are you sure you want to delete these study
      </ModalWindowConfirm>
    </PageWrapper>
  );
};

const Button = styled(ButtonBase)`
  width: 111px;
`;

const TableWrapper = styled.div`
  margin-right: 200px;
`;

export default Studies;
