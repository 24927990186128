import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ReactSVG } from 'react-svg';

type Props = {
  src: string;
  color?: string; // css var name from 'src/styles/colors.tsx'
  fill?: string; // css var name from 'src/styles/colors.tsx'
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
};

const Icon: FC<Props> = ({ src, color, fill, onClick, className }: Props) => {
  return (
    <IconWrapper className={className} onClick={onClick} color={color} fill={fill}>
      <ReactSVG src={src} />
    </IconWrapper>
  );
};

type IconWrapperType = { color?: string; fill?: string };

const IconWrapper = styled.div<IconWrapperType>`
  ${({ onClick }) => onClick && 'cursor: pointer'};

  svg path {
    ${({ color }) => color && `stroke: var(${color})`};
  }

  svg path {
    ${({ fill }) => fill && `fill: var(${fill})`}
  }
`;

export default Icon;
