import React, { FC } from 'react';
import { Global, css } from '@emotion/react';

const globalColors = css`
  body {
    --color-black: #0e0e0e;
    --color-dark: #2c2c2c;
    --color-grey: #acb0ba;
    --color-light: #f5f6fa;
    --color-white: #ffffff;
    --color-lines: #e2e7ff;
    --color-hoverTable: #f9fbff;

    --color-red: #ffdada;
    --color-green: #0d9202;
    --color-error: #de0000;
  }
`;

const ColorVariables: FC = () => (
  <>
    <Global styles={globalColors} />
  </>
);

export default ColorVariables;
