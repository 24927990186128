import React, { FC } from 'react';
import styled from '@emotion/styled';
import { SectionType, ExaminationFieldType } from '../../types/procedures';
import { ProcedureDataType } from '../../types/patients';
import { ProcedureDataSection as ProcedureDataSectionBase } from '.';
import { ErrorType } from '../../types/general';

type Props = {
  sections: SectionType[];
  examinationData: ExaminationFieldType[];
  procedureData: ProcedureDataType;
  dataInput: (name: string, value: string, required: boolean) => void;
  procedureErrors?: ErrorType | null;
};

const ProcedureDataSections: FC<Props> = ({
  sections,
  examinationData,
  procedureData,
  dataInput,
  procedureErrors,
}) => {
  return (
    <>
      {sections.map((section) => (
        <ProcedureDataSection
          key={section.id}
          section={section}
          data={examinationData}
          procedureData={procedureData}
          dataInput={dataInput}
          procedureErrors={procedureErrors}
        />
      ))}
    </>
  );
};

const ProcedureDataSection = styled(ProcedureDataSectionBase)`
  margin-top: 40px;
`;

export default ProcedureDataSections;
