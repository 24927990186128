import React from 'react';
import styled from '@emotion/styled';
import { HeaderGroup } from 'react-table';

type Props<T extends {}> = {
  headerGroups: HeaderGroup<T>[];
};

const Thead = <T extends {}>({ headerGroups }: Props<T>): JSX.Element => {
  return (
    <TheadWrapper>
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <HeaderCell {...column.getHeaderProps()}>{column.render('Header')}</HeaderCell>
          ))}
        </TableRow>
      ))}
    </TheadWrapper>
  );
};

const TheadWrapper = styled.thead``;

const TableRow = styled.tr``;

export const HeaderCell = styled.th`
  padding: 4px 16px;
  width: 200px;
  height: 30px;

  font-weight: 600;
  text-align: start;

  color: var(--color-black);
  background-color: var(--color-light);
  border: 1px solid var(--color-lines);

  &:first-of-type {
    padding-left: 24px;
  }
`;

export default Thead;
