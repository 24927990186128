import React, { FC, useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Field, Error as ErrorBase, InfoBlock } from '../atoms';
import { Loader } from '.';
import Icons from '../../assets/icons';
import { useOnClickOutside } from '../../hooks';
import { bigFirstLetter } from '../../utils';

type Props = {
  options: string[];
  value: string;
  onChange: (arg: string) => void;
  additionalInfo?: string;
  errorBorder?: boolean;
  error?: string[] | null;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  notAbsoluteDropdown?: boolean;
  dropdownIsOpen?: (status: boolean) => void;
  className?: string;
};

const isCompare = (first: string, second: string) => {
  return first.trim().toLowerCase() === second.trim().toLowerCase();
};

const Select: FC<Props> = ({
  className,
  options,
  value,
  error,
  errorBorder,
  required,
  readOnly,
  placeholder,
  additionalInfo,
  onChange,
  notAbsoluteDropdown,
  dropdownIsOpen,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const [isOpen, setIsOpen] = useState(false);
  const isFilled = !!value;
  const loading = options.length === 0;

  const optionHandler = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  const clickHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) {
      onChange(bigFirstLetter(e.target.value));
      setIsOpen(true);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, name: string) => {
    if (e.key === 'Enter') {
      optionHandler(name);
    }
  };

  useEffect(() => {
    if (dropdownIsOpen) {
      dropdownIsOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <Wrapper className={className} ref={ref}>
      <FieldWrapper error={!!error || !!errorBorder} onClick={() => setIsOpen(!isOpen)}>
        <Placeholder isFilled={isFilled}>
          {placeholder}
          {required && <Star> *</Star>}
        </Placeholder>

        <Focus tabIndex={0} onFocus={() => setIsOpen(!isOpen)} />

        <InputWrapper>
          <InputField tabIndex={-1} value={value} onChange={clickHandler} readOnly />

          {!readOnly && <Arrow src={isOpen ? Icons.ArrowUp : Icons.ArrowDown} />}
        </InputWrapper>

        {additionalInfo && <InfoBlock info={additionalInfo} />}
      </FieldWrapper>

      {isOpen && !readOnly && (
        <Dropdown isAbsolute={notAbsoluteDropdown}>
          {loading ? (
            <CenteringWrapper>
              <Loader small />
            </CenteringWrapper>
          ) : (
            options.map((option, index) => (
              <Option
                tabIndex={Number(`0.${index}`)}
                key={option}
                selected={isCompare(option, value)}
                onClick={() => optionHandler(option)}
                onKeyDown={(e) => onKeyDown(e, option)}
              >
                {option}
              </Option>
            ))
          )}

          <Focus tabIndex={0.99} onFocus={() => setIsOpen(false)} />
        </Dropdown>
      )}

      {error && <Error error={error} />}
    </Wrapper>
  );
};

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const FieldWrapper = styled(Field)<FieldWrapperType>`
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;

  ${({ error }) =>
    error &&
    `
    border: 1px solid var(--color-error);
  `}

  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputField = styled.input`
  padding: 0;
  padding-right: 14px;
  width: 100%;

  color: var(--color-dark);
  background-color: var(--color-light);
  border: none;
  outline: none;
  cursor: pointer;
`;

const Dropdown = styled.div<DropdownType>`
  position: absolute;
  padding: 16px 16px 0;
  margin-top: 8px;
  width: 100%;
  max-height: 158px;

  background-color: var(--color-white);
  border: 1px solid var(--color-lines);

  overflow: auto;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 2px;
  }

  ${({ isAbsolute }) =>
    isAbsolute &&
    `
    position: relative;
  `}
`;

const Option = styled.div<OptionType>`
  padding-bottom: 16px;

  color: var(--color-grey);
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
    color: var(--color-dark);
  `}

  &:focus {
    font-weight: bold;
    outline: none;
  }
`;

const Focus = styled.div`
  height: 1px;
  background-color: transparent;
  outline: none;
  pointer-events: none;
`;

const Placeholder = styled.div<PlaceholderType>`
  position: absolute;
  left: 16px;

  color: var(--color-grey);
  pointer-events: none;
  cursor: text;
  z-index: 1;

  ${({ isFilled }) =>
    isFilled &&
    `
    position: relative;
    left: 0;
    padding-bottom: 6px;

    font-weight: 600;
    font-size: 12px;
  `}
`;

const Star = styled.span`
  color: var(--color-error);
`;

const Arrow = styled.img`
  position: absolute;
  right: 0;

  width: 12px;
  height: 6px;
`;

const Error = styled(ErrorBase)`
  position: absolute;
  top: 50px;
  padding-left: 16px;
`;

type OptionType = {
  selected: boolean;
};

type PlaceholderType = {
  isFilled: boolean;
};

type FieldWrapperType = {
  error: boolean;
};

type DropdownType = {
  isAbsolute?: boolean;
};

export default Select;
