import React from 'react';
import { Tsection } from '.';
import { SectionType } from '../../types/procedures';

type Props<T> = {
  dataForSection: T[];
  sections: SectionType[];
  handleFieldAction: (action: string) => (id: number) => void;
  handleSectionAction: (action: string, name: string) => void;
  setCurrentFieldId: (id: number) => void;
  dragAndDropField: (id: number) => void;
  setCurrentSection: (arg: SectionType) => void;
  handleClickField?: (id: number) => void;
  handleViewButton?: (id: number) => void;
  dragAndDropSection: (arg: SectionType) => void;
  isActiveVariant?: string;
  search?: string;
};

const Tsections = <T extends {}>({
  sections,
  dataForSection,
  handleFieldAction,
  handleSectionAction,
  setCurrentFieldId,
  dragAndDropField,
  setCurrentSection,
  dragAndDropSection,
  handleClickField,
  handleViewButton,
  isActiveVariant,
  search,
}: Props<T>): JSX.Element => {
  return (
    <>
      {sections.map((section) => (
        <Tsection
          key={section.id}
          section={section}
          data={dataForSection}
          handleEdit={handleFieldAction('edit')}
          handleDelete={handleFieldAction('delete')}
          sectionActions={handleSectionAction}
          setCurrentFieldId={setCurrentFieldId}
          setHoverFieldId={dragAndDropField}
          setCurrentSection={setCurrentSection}
          setHoverSection={dragAndDropSection}
          handleClickField={handleClickField}
          handleViewButton={handleViewButton}
          isActiveVariant={isActiveVariant}
          search={search}
        />
      ))}
    </>
  );
};

export default Tsections;
