import { AxiosResponse } from 'axios';
import api from '../api/api';
import {
  ExaminationType,
  ExaminationFieldType,
  ExaminationFieldRequestType,
  SwitchType,
} from '../types/procedures';
import { QueryType, PaginationResponseType } from '../types/general';

export const switchExamination = (data: SwitchType): Promise<void> => {
  return api.put(`procedure_types/switch_order/`, data);
};

export const getExamination = (id: number): Promise<AxiosResponse<ExaminationType>> => {
  return api.get<ExaminationType>(`/procedure_types/${id}`);
};

export const setExamination = (data: ExaminationType): Promise<AxiosResponse<ExaminationType>> => {
  return api.post<ExaminationType>('/procedure_types/', data);
};

export const updateExamination = (
  id: number,
  data: ExaminationType
): Promise<AxiosResponse<ExaminationType>> => {
  return api.put<ExaminationType>(`/procedure_types/${id}/`, data);
};

export const deleteExamination = (id: number): Promise<void> => {
  return api.delete(`/procedure_types/${id}/`);
};

export const switchExaminationField = (data: SwitchType): Promise<void> => {
  return api.put(`procedure_fields_schema/switch_order/`, data);
};

export const setExaminationField = (
  data: ExaminationFieldRequestType
): Promise<AxiosResponse<ExaminationFieldType>> => {
  return api.post<ExaminationFieldType>('/procedure_fields_schema/', data);
};

export const updateExaminationField = (
  data: ExaminationFieldRequestType
): Promise<AxiosResponse<ExaminationFieldType>> => {
  return api.put<ExaminationFieldType>(`/procedure_fields_schema/${data.id}/`, data);
};

export const deleteExaminationField = (id: number): Promise<void> => {
  return api.delete(`/procedure_fields_schema/${id}/`);
};

export const getExaminationFields = (
  id: number
): Promise<AxiosResponse<ExaminationFieldType[]>> => {
  return api.get<ExaminationFieldType[]>(`/procedure_fields_schema/?model_schema=${id}`);
};

export const getExaminations = (
  query: QueryType,
  parameters?: string
): Promise<AxiosResponse<PaginationResponseType<ExaminationType>>> => {
  return api.get<PaginationResponseType<ExaminationType>>(
    `/procedure_types/?page=${query.pageNumber}&page_size=${100}${parameters || ''}`
  );
};

export const getExaminationByName = (
  name: string
): Promise<AxiosResponse<PaginationResponseType<ExaminationType>>> => {
  return api.get<PaginationResponseType<ExaminationType>>(`/procedure_types/?name=${name}`);
};
