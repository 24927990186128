import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Icon } from '.';
import Icons from '../../assets/icons';

type Props = {
  info: string;
};

const InfoBlock: FC<Props> = ({ info }) => {
  return (
    <Wrapper>
      <Icon src={Icons.Info} />
      <Title>{info}</Title>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  right: -23px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover > div {
    display: block;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 20px;
  display: none;
  padding: 6px 8px;

  font-size: 12px;
  color: var(--color-white);
  background-color: #6e6d6f;

  white-space: nowrap;
`;

export default InfoBlock;
