import React, { FC } from 'react';
import styled from '@emotion/styled';
import Modal from 'react-modal';
import Icons from '../../assets/icons';
import { Button as ButtonBase } from '../atoms';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0',
    padding: '0',
    width: '560px',
  },
  overlay: {
    backgroundColor: 'rgba(44, 44, 44, 0.6)',
    zIndex: 2,
  },
};

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  saveInfo: () => void;
  title: string;
  children?: React.ReactNode;
  className?: string;
};

const ModalWindowConfirm: FC<Props> = ({
  className,
  modalIsOpen,
  closeModal,
  saveInfo,
  title,
  children,
}) => {
  const saveButtonHandler = () => {
    saveInfo();
    closeModal();
  };

  return (
    <Wrapper className={className}>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <TitleBlock>
          <Title>{title}</Title>
          <Cross src={Icons.CrossToClose} onClick={closeModal} />
        </TitleBlock>

        <Content>{children}</Content>

        <ButtonBlock>
          <Button onClick={closeModal}>Cancel</Button>
          <Button confirm onClick={saveButtonHandler}>
            Confirm
          </Button>
        </ButtonBlock>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const TitleBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 46px;
  background-color: var(--color-light);
`;

const Title = styled.div`
  color: var(--color-black);
  font-weight: 600;
`;

const Cross = styled.img`
  position: absolute;
  right: 19px;

  width: 8px;
  height: 8px;

  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 32px;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
`;

const Button = styled(ButtonBase)`
  margin: 0 8px;
  width: 120px;
`;

export default ModalWindowConfirm;
