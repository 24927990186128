import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { uuid } from 'uuidv4';

import {
  ModalWindow,
  ModalWindowConfirm,
  Input,
  Select,
  CheckboxField,
  Option,
} from '../molecules';
import { AddButton } from '../atoms';
import { ExaminationFieldType, ExaminationFieldRequestType } from '../../types/procedures';
import { isEqual } from '../../utils';
import { useQuery } from '../../hooks';
import { ErrorType } from '../../types/general';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  saveField: (arg: ExaminationFieldRequestType) => void;
  title: string;
  selectedField?: ExaminationFieldType;
  error: ErrorType | null;
  buttonDisabled?: boolean;
  children?: React.ReactNode;
  className?: string;
};

type ConversionOptionsType = {
  [key: string]: string;
};

type SelectionListType = {
  option: string;
  id: string;
};

const selectOptions = [
  'String',
  'Decimal',
  'Int',
  'DateTime',
  'Date',
  'Time',
  'Bool',
  'Selection list',
];

export const conversionOptions: ConversionOptionsType = {
  //  types to display
  String: 'text',
  Decimal: 'float',
  Int: 'integer',
  DateTime: 'datetime',
  Date: 'date',
  Time: 'time',
  Bool: 'boolean',
  'Selection list': 'choice',
  //  types to send to the server
  text: 'String',
  float: 'Decimal',
  integer: 'Int',
  datetime: 'DateTime',
  date: 'Date',
  time: 'Time',
  boolean: 'Bool',
  choice: 'Selection list',
};

const listVariants = {
  TO_STRING: 'toString',
  TO_OBJECT: 'toObjects',
};

const listHandler = (variant: string, list: any[]) => {
  if (variant === listVariants.TO_STRING) {
    return list.map((item) => item.option);
  }

  if (variant === listVariants.TO_OBJECT) {
    return list.map((option) => {
      return {
        option,
        id: uuid(),
      };
    });
  }

  return [];
};

const getDefaultInput = (
  type: string,
  value: string,
  onChange: (v: string) => void,
  error: any,
  selectionList: SelectionListType[]
) => {
  const defaultInput = {
    [conversionOptions.text]: (
      <Input
        value={value}
        onChange={onChange}
        placeholder="Enter initial value"
        error={error?.default}
        required
      />
    ),
    [conversionOptions.float]: (
      <Input
        value={value}
        onChange={onChange}
        placeholder="Enter initial value"
        error={error?.default}
        type="number"
        required
      />
    ),
    [conversionOptions.integer]: (
      <Input
        value={value}
        onChange={onChange}
        placeholder="Enter initial value"
        error={error?.default}
        type="number"
        required
      />
    ),
    [conversionOptions.date]: (
      <Input
        value={value}
        onChange={onChange}
        errorBorder={!!error?.default}
        type="date"
        required
      />
    ),
    [conversionOptions.datetime]: (
      <Input
        value={value}
        onChange={onChange}
        errorBorder={!!error?.default}
        type="datetime-local"
        required
      />
    ),
    [conversionOptions.time]: (
      <Input
        value={value}
        onChange={onChange}
        errorBorder={!!error?.default}
        type="time"
        required
      />
    ),
    [conversionOptions.boolean]: (
      <Select
        value={value || ''}
        onChange={onChange}
        placeholder="Select"
        options={['True', 'False']}
        errorBorder={!!error?.default}
        required
      />
    ),
    [conversionOptions.choice]: selectionList.length > 0 && (
      <Select
        value={value || ''}
        onChange={onChange}
        placeholder="Select"
        options={selectionList.map((select) => select.option)}
        errorBorder={!!error?.default}
        required
      />
    ),
  };

  return defaultInput[type];
};

const ModalExaminationTable: FC<Props> = ({
  error,
  saveField,
  closeModal,
  selectedField,
  ...props
}) => {
  const query = useQuery();

  const [currentIndex, setCurrenIndex] = useState<number | null>(null);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);

  const [fieldName, setFieldName] = useState('');
  const [description, setDescription] = useState('');
  const [dataType, setDataType] = useState('');
  const [isOptional, setIsOptional] = useState(true);
  const [selectionList, setSelectionList] = useState<SelectionListType[]>([]);

  const [defaultValue, setDefaultValue] = useState('');

  const dataIsSelectionList = dataType === 'Selection list';
  const pageId = Number(query.get('id'));

  const fieldsData: ExaminationFieldRequestType = {
    label: fieldName,
    dataType: conversionOptions[dataType],
    null: isOptional,
    modelSchema: pageId,
    helpText: description || null,
    choices: dataIsSelectionList ? listHandler(listVariants.TO_STRING, selectionList) : undefined,
    id: selectedField ? selectedField.id : undefined,
    default: defaultValue || null,
  };

  const handleSaveInfo = () => {
    saveField(fieldsData);
  };

  const handleChangeOption = (value: string, index: number) => {
    const newSelectionList = [...selectionList];
    newSelectionList[index].option = value;

    setSelectionList(newSelectionList);
  };

  const handleDeleteOption = (name: string) => {
    const newSelectionList = selectionList.filter((item) => item.option !== name);

    setSelectionList(newSelectionList);
  };

  const handleAddOption = () => {
    setSelectionList([...selectionList, { option: '', id: uuid() }]);
  };

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dropHandler = (e: React.DragEvent<HTMLDivElement>, hoverIndex: number) => {
    e.preventDefault();
    if (hoverIndex === currentIndex || !currentIndex) {
      return;
    }

    const hoverData = selectionList[hoverIndex];
    const currentData = selectionList[currentIndex];

    const newData = selectionList.map((selectionItem: SelectionListType, index: number) => {
      if (index === currentIndex) {
        return hoverData;
      }

      if (index === hoverIndex) {
        return currentData;
      }

      return selectionItem;
    });

    setSelectionList(newData);
  };

  const handleUnsavedChanges = () => {
    if (selectedField) {
      // when edit a field
      const intialSelectedField = {
        label: selectedField.label,
        dataType: selectedField.dataType,
        null: selectedField.null,
        modelSchema: pageId,
        helpText: selectedField.helpText || null,
        choices: selectedField.choices || undefined,
        id: selectedField.id,
      };

      if (!isEqual(fieldsData, intialSelectedField)) {
        setUnsavedChangesModal(true);
        return;
      }
    } else {
      // when adding a new field
      const initialFieldsData = {
        label: '',
        dataType: undefined,
        null: true,
        modelSchema: pageId,
        helpText: null,
        choices: undefined,
        id: undefined,
      };

      if (!isEqual(fieldsData, initialFieldsData)) {
        setUnsavedChangesModal(true);
        return;
      }
    }

    closeModal();
  };

  const handleAfterOpenModal = () => {
    if (!selectedField) {
      setUnsavedChangesModal(false);
      setFieldName('');
      setDescription('');
      setDataType('');
      setIsOptional(true);
      setSelectionList([]);
      setDefaultValue('');
    }
  };

  useEffect(() => {
    if (selectedField) {
      setFieldName(selectedField.label);
      setDataType(conversionOptions[selectedField.dataType]);
    }

    setDescription(selectedField?.helpText || '');
    setIsOptional(!!selectedField?.null);

    if (selectedField?.choices) {
      setSelectionList(listHandler(listVariants.TO_OBJECT, selectedField.choices));
    } else {
      setSelectionList([]);
    }
  }, [selectedField]);

  return (
    <ModalWindow
      saveInfo={handleSaveInfo}
      closeModal={handleUnsavedChanges}
      onAfterOpen={handleAfterOpenModal}
      error={error?.nonFieldErrors}
      {...props}
    >
      <CenteringWrapper>
        <Content>
          <Input
            required
            value={fieldName}
            onChange={setFieldName}
            placeholder="Enter field name"
            error={error?.label}
          />

          <Input value={description} onChange={setDescription} placeholder="Enter description" />

          <Select
            value={dataType}
            onChange={setDataType}
            options={selectOptions}
            placeholder="Data type"
            required
            error={error?.dataType}
          />

          {dataIsSelectionList && (
            <>
              {selectionList.map(({ option, id }, index) => (
                <Option
                  key={id}
                  index={index}
                  option={option}
                  onClick={handleDeleteOption}
                  onChange={handleChangeOption}
                  onDragOver={(e) => dragOverHandler(e)}
                  onDragStart={() => setCurrenIndex(index)}
                  onDrop={(e) => dropHandler(e, index)}
                />
              ))}

              <AddButton secondary onClick={handleAddOption}>
                Add option
              </AddButton>
            </>
          )}

          <CheckboxField title="Is optional" checked={isOptional} setChecked={setIsOptional} />

          {!isOptional &&
            getDefaultInput(dataType, defaultValue, setDefaultValue, error, selectionList)}
        </Content>
      </CenteringWrapper>

      <ModalWindowConfirm
        title="Unsaved changes"
        modalIsOpen={unsavedChangesModal}
        closeModal={() => setUnsavedChangesModal(false)}
        saveInfo={closeModal}
      >
        Are you sure you want to discard your entries?
      </ModalWindowConfirm>
    </ModalWindow>
  );
};

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 340px;

  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default ModalExaminationTable;
