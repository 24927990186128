import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { format, parse } from 'date-fns';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { PageWrapper, Table, Pagination } from '../features';
import { Title, Icon, Button, Header } from '../atoms';
import Icons from '../../assets/icons';
import { useQuery } from '../../hooks';
import { filters } from '../organisms/ModalQueryData';
import { QueryDataType, QueryParametersType } from '../../types/patients';
import { PaginationResponseType, QueryType, ErrorType } from '../../types/general';
import { url } from '../../router/routes';
import { ModalQueryData } from '../organisms';
import { Loader } from '../molecules';
import { bigFirstLetter } from '../../utils';
import { getAccessToken } from '../../services/AuthServices';
import { apiFile } from '../../api/api';
import Notification, { notify, notifitationVariants } from '../molecules/Notification';

type HeaderType = {
  Header: string;
  accessor: string;
};

export const headerActions = {
  TABLE_NAME: 'type',
  PROCEDURE_ID: 'procedureId',
  DATE: 'date',
  PHYSICIAN: 'physicianValue',
  PATIENT_ID: 'id',
};

const QueryData: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch<Dispatch>();

  const pageTable = query.getAll(filters.TABLE);
  const pageField = query.getAll(filters.FIELD);
  const pageCondition = query.getAll(filters.CONDITION);
  const pageValue = query.getAll(filters.VALUE);
  const pageValueType = query.getAll(filters.VALUE_TYPE);
  const pageConditionType = query.getAll(filters.CONDITION_TYPE);
  const pageId = query.getAll(filters.ID);
  const pageOperator = query.getAll(filters.OPERATOR);
  const dateFrom = query.get(filters.DATE_FROM) || '';
  const dateTo = query.get(filters.DATE_TO) || '';
  const pageNumber = Number(query.get('page')) || 1;
  const pageSize = 20;
  const parameters = `&${location.search.slice(location.search.indexOf(filters.OPERATOR))}`;
  const prevPage = query.get('prevPage');

  const date = {
    dateFrom,
    dateTo,
  };

  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const [customHeaders, setCustomHeaders] = useState<HeaderType[]>([]);
  const [errors, setErrors] = useState<ErrorType | null>(null);

  const [dataList, setDataList] = useState<QueryParametersType[]>([]);
  const [dataTable, setDataTable] = useState<QueryDataType[]>([]);
  const [pagination, setPagination] = useState<PaginationResponseType<QueryDataType>>({
    count: 0,
    results: [],
  });

  const data = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(
    () => [
      {
        Header: 'Table name',
        accessor: headerActions.TABLE_NAME,
      },
      {
        Header: 'Procedure ID',
        accessor: headerActions.PROCEDURE_ID,
      },
      {
        Header: 'Date',
        accessor: headerActions.DATE,
      },
      {
        Header: 'Physician',
        accessor: headerActions.PHYSICIAN,
      },
      {
        Header: 'Patient ID',
        accessor: headerActions.PATIENT_ID,
      },
      ...customHeaders,
    ],
    [customHeaders]
  );

  const loadData = (queryData: QueryType, queryParameters: string) => {
    setLoading(true);

    const dataForSending = {
      query: queryData,
      parameters: queryParametersHandler(queryParameters),
    };

    dispatch.patients
      .getQuery(dataForSending)
      .then((response) => {
        setDataTable(response.results);
        setPagination(response);
        setErrors(null);
      })
      .catch((err) => {
        setErrors(err.response.data);
        setDataTable([]);
        setPagination({
          count: 0,
          results: [],
        });
      })
      .finally(() => setLoading(false));

    loadDataList();
    setDownloadUrl('');
  };

  const dateHandler = (inputDate: string) => {
    const currentDate = parse(inputDate, 'dd.MM.yyyy', new Date());
    const formatedDate = format(new Date(currentDate), 'yyyy-MM-dd');
    return formatedDate;
  };

  const queryParametersHandler = (queries: string) => {
    // use for changing "exact", "startswith", "endswith" parameters before sending
    const result = queries
      .split('&')
      .map((item) => {
        if (item.includes('value')) {
          const startIndexValue = item.indexOf('=') + 1;
          const value = item.slice(startIndexValue);

          try {
            return `value=${dateHandler(value)}`;
          } catch {
            return item;
          }
        }

        return item;
      })
      .join('&');

    return result;
  };

  const loadDataList = () => {
    const updatedDataList: QueryParametersType[] = [];

    pageTable.map((item, index) => {
      const filter = {
        table: pageTable[index],
        field: pageField[index],
        condition: pageCondition[index],
        value: pageValue[index],
        operator: pageOperator[index],
        valueType: pageValueType[index],
        conditionType: pageConditionType[index],
        id: pageId[index],
      };

      updatedDataList.push(filter);
    });

    setDataList(updatedDataList);
  };

  const handlePageClick = (selectedPage: number) => {
    const queryData = {
      pageNumber: selectedPage,
      pageSize,
    };

    loadData(queryData, parameters);
    history.push(`${url.QUERY_DATA}/?page=${selectedPage}&prevPage=${prevPage}${parameters}`);
  };

  const goBack = () => {
    if (prevPage) {
      history.push(prevPage);
    }
  };

  const getFile = (src: string) => {
    const updatedSrc = src.slice(src.indexOf('/media/'));

    if (updatedSrc) {
      apiFile
        .get(updatedSrc, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        .then((response) => {
          const fileUrl = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.rel = 'noreferrer';
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => notify('File could not be loaded', notifitationVariants.ERROR));
    }
  };

  useEffect(() => {
    if (dataTable.length !== 0 && dataList.length !== 0) {
      const result = dataList.map((item) => {
        let procedureName: string | undefined = '';

        dataTable.find((queryField) => {
          const queryFieldNames = Object.keys(queryField);
          const queryNamePart = item.field.replaceAll(/_/g, '');

          procedureName = queryFieldNames.find((queryName) =>
            queryName.toLowerCase().includes(queryNamePart)
          );
        });

        return {
          Header: bigFirstLetter(item.field).replaceAll(/_/g, ' '),
          accessor: procedureName,
        };
      });

      const uniqueResults = result.filter(
        (item, index, array) =>
          array.findIndex((element) => item.Header === element.Header) === index
      );

      setCustomHeaders(uniqueResults);
    }
  }, [dataTable, dataList]);

  useEffect(() => {
    if (!downloadUrl) {
      const updatedParameters = `?${queryParametersHandler(parameters).slice(1)}`;

      const getSuccessStatus = (taskId: string) => {
        dispatch.patients.getPreparedFile(taskId).then((file) => {
          if (file.taskStatus === 'PENDING') {
            getSuccessStatus(taskId);
          } else {
            setDownloadUrl(file.file);
          }
        });
      };

      dispatch.patients.preparedFile(updatedParameters).then((taskId) => getSuccessStatus(taskId));
    }
  }, [downloadUrl]);

  useEffect(() => {
    const queryData = {
      pageNumber,
      pageSize,
    };

    loadData(queryData, parameters);
  }, [parameters]);

  return (
    <PageWrapper>
      <Header>
        <TitleWrapper onClick={goBack}>
          <Arrow src={Icons.ArrowLeftWithoutCircle} />
          <Title>Query data</Title>
        </TitleWrapper>

        <Block>
          <FilterButton
            title={errors ? 'Error in the selected filters' : ''}
            error={!!errors}
            onClick={() => setFiltersIsOpen(true)}
          >
            Selected filter ({pageOperator.length})
          </FilterButton>

          <ExportButton onClick={() => getFile(downloadUrl)} disabled={!downloadUrl}>
            Export
          </ExportButton>
        </Block>
      </Header>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Table data={data} columns={columns} />

          <Pagination
            dataTable={pagination}
            pageSize={pageSize}
            activePage={pageNumber}
            onPageChange={handlePageClick}
          />
        </>
      )}

      <ModalQueryData
        modalIsOpen={filtersIsOpen}
        closeModal={() => setFiltersIsOpen(false)}
        filterParameters={dataList}
        date={date}
        prevPage={prevPage || undefined}
        errors={errors}
      />

      <Notification />
    </PageWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Arrow = styled(Icon)`
  position: relative;
  top: 1px;
  margin-right: 10px;
`;

const Block = styled.div`
  display: flex;
`;

const FilterButton = styled(Button)`
  width: 200px;
  margin-right: 15px;
`;

const ExportButton = styled(Button)`
  width: 100px;
`;

export default QueryData;
