import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { Icon } from '.';
import Icons from '../../assets/icons';
import { QueryType } from '../../types/general';

type Props = {
  previous: string;
  current: string;
  previousURL: string;
  previousQuery?: QueryType;
};

const RouteBlock: FC<Props> = ({ previous, current, previousURL, previousQuery }) => {
  const history = useHistory();

  const goBack = () => {
    if (previousQuery) {
      history.push(`${previousURL}/?page=${previousQuery.pageNumber}`);
    } else {
      history.push(`${previousURL}`);
    }
  };

  return (
    <Wrapper>
      <PreviousTab onClick={goBack}>{previous}</PreviousTab>
      <RouteArrow src={Icons.RouteArrow} />
      <CurrentTab>{current}</CurrentTab>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  font-size: 13px;
`;

const RouteArrow = styled(Icon)`
  margin: 0 6px;
  width: 14px;
  height: 14px;
`;

const PreviousTab = styled.div`
  color: var(--color-grey);
  cursor: pointer;
`;

const CurrentTab = styled.div`
  color: var(--color-black);
`;

export default RouteBlock;
