import axios from 'axios';
import {
  makeRequestValidData,
  onRequestTokenInterceptor,
  makeResponseValidData,
  makeResponseErrorData,
} from './interceptor';

const API_URL = '/api';
export const BASE_URL = 'http://207.180.193.148:8081';

const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

export const apiPublic = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

export const apiFile = axios.create({
  withCredentials: true,
  baseURL: '',
});

api.interceptors.request.use(makeRequestValidData);
api.interceptors.request.use(onRequestTokenInterceptor);
api.interceptors.response.use(makeResponseValidData, makeResponseErrorData);

apiPublic.interceptors.request.use(makeRequestValidData);
apiPublic.interceptors.response.use(makeResponseValidData, makeResponseErrorData);

export default api;
