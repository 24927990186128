import React, { FC, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Field, Error as ErrorBase, InfoBlock } from '../atoms';
import Icons from '../../assets/icons';
import { useOnClickOutside } from '../../hooks';

type OptionType = {
  name: string;
  isActive: boolean;
  id: number;
};

type Props = {
  options: OptionType[];
  value: string;
  onClick: (id: number) => void;
  additionalInfo?: string;
  errorBorder?: boolean;
  error?: string[] | null;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  className?: string;
};

const Multiselect: FC<Props> = ({
  className,
  options,
  value,
  error,
  errorBorder,
  required,
  readOnly,
  placeholder,
  additionalInfo,
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const [isOpen, setIsOpen] = useState(false);
  const isFilled = !!value;

  const onClickHandler = (id: number) => {
    if (!readOnly) {
      onClick(id);
    }
  };

  return (
    <Wrapper className={className} ref={ref}>
      <FieldWrapper error={!!error || !!errorBorder}>
        <Placeholder isFilled={isFilled}>
          {placeholder}
          {required && <Star> *</Star>}
        </Placeholder>

        <InputWrapper onClick={() => setIsOpen(!isOpen)}>
          <InputField value={value} readOnly />

          <Arrow src={isOpen ? Icons.ArrowUp : Icons.ArrowDown} />
        </InputWrapper>

        {additionalInfo && <InfoBlock info={additionalInfo} />}
      </FieldWrapper>

      {isOpen && (
        <Dropdown>
          {options.map(({ id, name, isActive }) => (
            <OptionWrapper onClick={() => onClickHandler(id)} key={name}>
              <Option>{name}</Option>
              {!readOnly && <Toggle src={isActive ? Icons.ToggleOn : Icons.ToggleOff} />}
            </OptionWrapper>
          ))}
        </Dropdown>
      )}

      {error && <Error error={error} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const FieldWrapper = styled(Field)<FieldWrapperType>`
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;

  ${({ error }) =>
    error &&
    `
    border: 1px solid var(--color-error);
  `}

  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputField = styled.input`
  padding: 0;
  padding-right: 14px;
  width: 100%;

  color: var(--color-dark);
  background-color: var(--color-light);
  border: none;
  outline: none;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  padding: 16px 16px 0;
  margin-top: 8px;
  width: 100%;
  max-height: 158px;

  background-color: var(--color-white);
  border: 1px solid var(--color-lines);

  overflow: auto;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 2px;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
`;

const Option = styled.div`
  color: var(--color-dark);
`;

const Toggle = styled.img`
  width: 22px;
  height: 12px;
`;

const Placeholder = styled.div<PlaceholderType>`
  position: absolute;
  left: 16px;

  color: var(--color-grey);
  pointer-events: none;
  cursor: text;
  z-index: 1;

  ${({ isFilled }) =>
    isFilled &&
    `
    position: relative;
    left: 0;
    padding-bottom: 6px;

    font-weight: 600;
    font-size: 12px;
  `}
`;

const Star = styled.span`
  color: var(--color-error);
`;

const Arrow = styled.img`
  position: absolute;
  right: 0;

  width: 12px;
  height: 6px;

  cursor: pointer;
`;

const Error = styled(ErrorBase)`
  position: absolute;
  top: 50px;
  padding-left: 16px;
`;

type PlaceholderType = {
  isFilled: boolean;
};

type FieldWrapperType = {
  error: boolean;
};

export default Multiselect;
