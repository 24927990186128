import React from 'react';
import styled from '@emotion/styled';

const Field = styled.div<FieldType>`
  display: flex;
  padding: 0 16px;
  height: 48px;

  color: var(--color-dark);
  background-color: var(--color-light);
  border: 1px solid var(--color-light);

  ${({ onClick }) => onClick && 'cursor: pointer'};
`;

type FieldType = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export default Field;
