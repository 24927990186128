import React, { FC } from 'react';
import styled from '@emotion/styled';
import { SectionType, ExaminationFieldType } from '../../types/procedures';
import { ProcedureDataForm } from '.';
import { ProcedureDataType } from '../../types/patients';
import { ErrorType } from '../../types/general';

type Props = {
  data: ExaminationFieldType[];
  section: SectionType;
  procedureData: ProcedureDataType;
  dataInput: (name: string, value: string, required: boolean) => void;
  className?: string;
  procedureErrors?: ErrorType | null;
};

const ProcedureDataSection: FC<Props> = ({
  className,
  data,
  section,
  procedureData,
  dataInput,
  procedureErrors,
}) => {
  const filteredData = data.filter((item) => section.fields.includes(item.id));

  return (
    <Wrapper className={className}>
      <Title>{section.name}</Title>

      <ProcedureDataForm
        fields={filteredData}
        procedureData={procedureData}
        dataInput={dataInput}
        procedureErrors={procedureErrors}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 16px;
  font-weight: 600;
`;

export default ProcedureDataSection;
