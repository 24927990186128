import React, { FC, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { useQuery } from '../../hooks';
import { Header, Heading, Button as ButtonBase } from '../atoms';
import { Loader, ModalWindowConfirm, ModalWindowInput } from '../molecules';
import { PageWrapper, Table as TableBase, Pagination } from '../features';
import { url } from '../../router/routes';
import { PaginationResponseType, QueryType, ErrorType } from '../../types/general';
import { PhysicianType } from '../../types/physicians';
import { uniqueErrors } from '../../utils';

const headerActions = {
  NAME: 'value',
  ACTIONS: 'actions',
};

const tableActions = {
  EDIT: 'edit',
  DELETE: 'delete',
};

const modals = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

const Physicians: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const physiciansData = useSelector((state: RootState) => state.physicians.data);

  const pageNumber = Number(query.get('page')) || 1;
  const pageSize = 20;
  const queryData = {
    pageNumber,
    pageSize,
  };

  const [loading, setLoading] = useState(false);
  const [buttonIsActive, setButtonIsActive] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const [selectedPhysicianId, setSelectedPhysicianId] = useState<number | null>(null);
  const [errors, setErrors] = useState<ErrorType | null>(null);

  const [name, setName] = useState<string>('');
  const [physicians, setPhysicians] = useState<PhysicianType[]>([]);
  const [pagination, setPagination] = useState<PaginationResponseType<PhysicianType>>({
    count: 0,
    results: [],
  });

  const dataTable = useMemo(() => physicians, [physicians]);
  const columns = useMemo(
    () => [
      {
        Header: 'Full name',
        accessor: headerActions.NAME,
      },
      {
        Header: 'Actions',
        accessor: headerActions.ACTIONS,
      },
    ],
    []
  );

  const handleCloseModal = () => {
    setName('');
    setActiveModal('');
    setSelectedPhysicianId(null);
    setErrors(null);
  };

  const handleModalAddPhysician = () => {
    if (!buttonIsActive) {
      setButtonIsActive(true);

      const data = { value: name };
      dispatch.physicians
        .setPhysician(data)
        .then(() => handleCloseModal())
        .catch((err) => setErrors(err.response.data))
        .finally(() => setButtonIsActive(false));
    }
  };

  const handleModalEditPhysician = () => {
    if (!buttonIsActive) {
      setButtonIsActive(true);

      if (selectedPhysicianId) {
        const data = {
          value: name,
          id: selectedPhysicianId,
        };

        dispatch.physicians
          .updatePhysician(data)
          .then(() => handleCloseModal())
          .catch((err) => setErrors(err.response.data))
          .finally(() => setButtonIsActive(false));
      }
    }
  };

  const handleModalDelete = () => {
    if (selectedPhysicianId) {
      dispatch.physicians
        .deletePhysician(selectedPhysicianId)
        .then(() => handleCloseModal())
        .catch((err) => setErrors(err.response.data));
    }
  };

  const loadData = (queryParameters: QueryType) => {
    dispatch.physicians.setQuery(queryParameters);
    return dispatch.physicians.getPhysicians();
  };

  const handleAction = (action: string) => (id: number) => {
    setSelectedPhysicianId(id);

    if (action === tableActions.EDIT) {
      const findedPhysician = physicians.find((physician) => physician.id === id);

      setName(findedPhysician?.value || '');
      setActiveModal(modals.EDIT);
    } else if (action === tableActions.DELETE) {
      setActiveModal(modals.DELETE);
    }
  };

  const handlePageClick = (selectedPage: number) => {
    const updatedQueryData = {
      pageNumber: selectedPage,
      pageSize,
    };

    history.push(`${url.PHYSICIANS}/?page=${selectedPage}`);
    loadData(updatedQueryData);
  };

  useEffect(() => {
    setPhysicians(physiciansData.results);
    setPagination(physiciansData);
  }, [physiciansData]);

  useEffect(() => {
    setLoading(true);
    loadData(queryData).finally(() => setLoading(false));
  }, []);

  return (
    <PageWrapper>
      <Header>
        <Heading path={`${url.PHYSICIANS}/?page=1`} onClick={() => loadData(queryData)}>
          Physicians
        </Heading>

        <Button onClick={() => setActiveModal(modals.ADD)}>Add physician</Button>
      </Header>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            data={dataTable}
            columns={columns}
            handleEdit={handleAction(tableActions.EDIT)}
            handleDelete={handleAction(tableActions.DELETE)}
          />

          <Pagination
            dataTable={pagination}
            activePage={pageNumber}
            pageSize={pageSize}
            onPageChange={handlePageClick}
          />
        </>
      )}

      <ModalWindowInput
        title="Add physician"
        placeholder="Full name"
        modalIsOpen={activeModal === modals.ADD}
        closeModal={handleCloseModal}
        saveInfo={handleModalAddPhysician}
        inputValue={name}
        inputOnChange={setName}
        error={uniqueErrors(errors)}
        buttonDisabled={buttonIsActive}
      />

      <ModalWindowInput
        title="Edit physician"
        placeholder="Full name"
        modalIsOpen={activeModal === modals.EDIT}
        closeModal={handleCloseModal}
        saveInfo={handleModalEditPhysician}
        inputValue={name}
        inputOnChange={setName}
        error={uniqueErrors(errors)}
        buttonDisabled={buttonIsActive}
      />

      <ModalWindowConfirm
        title="Delete user"
        modalIsOpen={activeModal === modals.DELETE}
        closeModal={handleCloseModal}
        saveInfo={handleModalDelete}
      >
        Are you sure you want to delete this physician ?
      </ModalWindowConfirm>
    </PageWrapper>
  );
};

const Table = styled(TableBase)`
  max-width: 450px;
`;

const Button = styled(ButtonBase)`
  width: 103px;
  height: 40px;
`;

export default Physicians;
