import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  ExaminationType,
  ExaminationFieldType,
  ExaminationFieldRequestType,
  SectionType,
  SwitchType,
} from '../../types/procedures';
import { QueryType } from '../../types/general';
import {
  getExaminations,
  getExamination,
  setExamination,
  updateExamination,
  deleteExamination,
  getExaminationFields,
  setExaminationField,
  updateExaminationField,
  deleteExaminationField,
  getExaminationByName,
  switchExaminationField,
} from '../../services/ProceduresServices';
import store from '../index';

type InitialStateType<ExaminationField> = {
  data: ExaminationType;
  table: ExaminationField[];
  query: QueryType;
};

const initialState: InitialStateType<ExaminationFieldType> = {
  data: {
    id: null,
    name: '',
    isActive: false,
    isCase: false,
    isStudyCase: true,
    isFileUploadAvailable: false,
    studies: [],
    order: null,
  },
  table: [],
  query: {
    pageNumber: 1,
    pageSize: 20,
  },
};

const examination = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setExamination(state, data: ExaminationType) {
      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
      };
    },
    setTable(state, table: ExaminationFieldType[]) {
      return {
        ...state,
        table,
      };
    },
    setQuery(state, query: QueryType) {
      return {
        ...state,
        query,
      };
    },
    setClearExamination() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async addExamination(name: string) {
      // clearing the page before writing new data
      dispatch.examination.setClearExamination();
      try {
        const response = await setExamination({
          name,
          isActive: false,
          isCase: false,
          isStudyCase: true,
        });

        dispatch.examination.setExamination(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getExamination({ id }: { id: number }) {
      // clearing the page before writing new data
      dispatch.examination.setClearExamination();
      try {
        const pageRequest = getExamination(id);
        const tableRequest = getExaminationFields(id);

        const [responsePage, responseTable] = await Promise.all([pageRequest, tableRequest]);

        dispatch.studies.getTable();
        dispatch.examination.setExamination(responsePage.data);
        dispatch.examination.setTable(responseTable.data);
      } catch (error) {
        throw error;
      }
    },
    async deleteExamination(id: number) {
      try {
        await deleteExamination(id);

        const { query } = store.getState().procedures;
        const response = await getExaminations(query);

        dispatch.procedures.setTable(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getTable() {
      try {
        const { id } = store.getState().examination.data;

        if (id) {
          const response = await getExaminationFields(id);
          dispatch.examination.setTable(response.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async getTableById({ id }: { id: number }) {
      try {
        const response = await getExaminationFields(id);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async addField(data: ExaminationFieldRequestType) {
      try {
        await setExaminationField(data);

        dispatch.examination.getTable();
      } catch (error) {
        throw error;
      }
    },
    async updateField(data: ExaminationFieldRequestType) {
      try {
        await updateExaminationField(data);

        dispatch.examination.getTable();
      } catch (error) {
        throw error;
      }
    },
    async deleteField(id: number) {
      try {
        await deleteExaminationField(id);

        dispatch.examination.getTable();
      } catch (error) {
        throw error;
      }
    },
    async updateSection(data: SectionType[]) {
      try {
        const { id, name, isActive, isCase, isStudyCase, isFileUploadAvailable, studies } =
          store.getState().examination.data;

        if (id) {
          const response = await updateExamination(id, {
            name,
            isActive,
            isCase,
            isStudyCase,
            isFileUploadAvailable,
            studies,
            sections: {
              data,
            },
          });

          dispatch.examination.setExamination(response.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async switchExaminationField(data: SwitchType) {
      try {
        await switchExaminationField(data);
        dispatch.examination.getTable();
      } catch (error) {
        throw error;
      }
    },
    async changeStatus(isActive: boolean) {
      try {
        const { id, name, isCase, isStudyCase, isFileUploadAvailable, studies, sections } =
          store.getState().examination.data;

        if (id) {
          const response = await updateExamination(id, {
            name,
            isActive,
            isCase,
            isStudyCase,
            isFileUploadAvailable,
            studies,
            sections,
          });

          dispatch.examination.setExamination(response.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async changeCase(isCase: boolean) {
      try {
        const { id, name, isActive, isStudyCase, isFileUploadAvailable, studies, sections } =
          store.getState().examination.data;

        if (id) {
          const response = await updateExamination(id, {
            name,
            isActive,
            isCase,
            isStudyCase,
            isFileUploadAvailable,
            studies,
            sections,
          });

          dispatch.examination.setExamination(response.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async changeName(name: string) {
      try {
        const { id, isActive, isCase, isStudyCase, isFileUploadAvailable, studies, sections } =
          store.getState().examination.data;

        if (id) {
          const response = await updateExamination(id, {
            name,
            isActive,
            isCase,
            isStudyCase,
            isFileUploadAvailable,
            studies,
            sections,
          });

          dispatch.examination.setExamination(response.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async changeFileUpload(isFileUploadAvailable: boolean) {
      try {
        const { id, name, isActive, isCase, isStudyCase, studies, sections } =
          store.getState().examination.data;

        if (id) {
          const response = await updateExamination(id, {
            name,
            isActive,
            isCase,
            isStudyCase,
            isFileUploadAvailable,
            studies,
            sections,
          });

          dispatch.examination.setExamination(response.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async assignStudies(studies: number[]) {
      try {
        const { id, name, isActive, isCase, isStudyCase, isFileUploadAvailable, sections } =
          store.getState().examination.data;

        if (id) {
          const response = await updateExamination(id, {
            name,
            isActive,
            isCase,
            isStudyCase,
            isFileUploadAvailable,
            studies,
            sections,
          });

          dispatch.examination.setExamination(response.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async getExaminationByName(name: string) {
      try {
        const response = await getExaminationByName(name);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  }),
});

export default examination;
