import React, { FC } from 'react';
import { Global, css } from '@emotion/react';
import LatoBlack from './Lato/Lato-Black.ttf';
import LatoBlackItalic from './Lato/Lato-BlackItalic.ttf';
import LatoBold from './Lato/Lato-Bold.ttf';
import LatoBoldItalic from './Lato/Lato-BoldItalic.ttf';
import LatoItalic from './Lato/Lato-Italic.ttf';
import LatoLight from './Lato/Lato-Light.ttf';
import LatoLightItalic from './Lato/Lato-LightItalic.ttf';
import LatoRegular from './Lato/Lato-Regular.ttf';
import LatoThin from './Lato/Lato-Thin.ttf';
import LatoThinItalic from './Lato/Lato-ThinItalic.ttf';

const globalFonts = css`
  @font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url(${LatoBlack}) format('ttf'),
      url(${LatoBlackItalic}) format('ttf'), url(${LatoBold}) format('ttf'),
      url(${LatoBoldItalic}) format('ttf'), url(${LatoItalic}) format('ttf'),
      url(${LatoLight}) format('ttf'), url(${LatoLightItalic}) format('ttf'),
      url(${LatoRegular}) format('ttf'), url(${LatoThin}) format('ttf'),
      url(${LatoThinItalic}) format('ttf');
  }
`;

const FontsVariables: FC = () => (
  <>
    <Global styles={globalFonts} />
  </>
);

export default FontsVariables;
