import React, { FC, useState, useRef } from 'react';
import styled from '@emotion/styled';
import Icons from '../../assets/icons';
import { useOnClickOutside } from '../../hooks';

type OptionType = {
  id: number;
  name: string;
  isActive: boolean;
};

type Props = {
  options: OptionType[];
  description: string;
  onClick: (arg: number) => void;
  className?: string;
};

const Dropdown: FC<Props> = ({ className, onClick, options, description }) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper className={className} ref={ref}>
      <ButtonWrapper onClick={() => setIsOpen(!isOpen)}>
        {description}
        <Arrow src={isOpen ? Icons.ArrowUp : Icons.ArrowDown} />
      </ButtonWrapper>

      {isOpen && (
        <DropdownWrapper>
          {options.map(({ id, name, isActive }) => (
            <OptionWrapper onClick={() => onClick(id)} key={name}>
              <Option>{name}</Option>
              <Toggle src={isActive ? Icons.ToggleOn : Icons.ToggleOff} />
            </OptionWrapper>
          ))}
        </DropdownWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 50px 0 24px;
  height: 40px;

  white-space: nowrap;
  color: var(--color-black);
  border: 1px solid var(--color-black);
  cursor: pointer;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  padding: 8px 12px 0 8px;
  margin-top: 12px;
  width: 100%;
  max-height: 158px;

  background-color: var(--color-white);
  border: 1px solid var(--color-lines);

  overflow: auto;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 2px;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
`;

const Option = styled.div`
  color: var(--color-dark);
`;

const Arrow = styled.img`
  position: absolute;
  right: 24px;

  width: 12px;
  height: 6px;
`;

const Toggle = styled.img`
  width: 22px;
  height: 12px;
`;

export default Dropdown;
