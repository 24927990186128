import { getAccessToken } from '../services/AuthServices';
import { apiFile } from '../api/api';
import { notify, notifitationVariants } from '../components/molecules/Notification';

const linkHandler = (src: string) => {
  const updatedSrc = src.slice(src.indexOf('/media/'));

  if (updatedSrc) {
    apiFile
      .get(updatedSrc, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.rel = 'noreferrer';
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => notify('File could not be loaded', notifitationVariants.ERROR));
  }
};

export default linkHandler;
