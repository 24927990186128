import React, { FC } from 'react';
import GeneralVariables from './general';
import FontsVariables from './fonts';
import ColorVariables from './colors';

const GlobalStyles: FC = () => (
  <>
    <GeneralVariables />
    <FontsVariables />
    <ColorVariables />
  </>
);

export default GlobalStyles;
