import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Sidebar } from '.';

type Props = {
  children?: React.ReactNode;
};

const PageWrapper: FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <SidebarWrapper>
        <Sidebar />
      </SidebarWrapper>

      <BodyWrapper>{children}</BodyWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const SidebarWrapper = styled.div`
  margin-right: 79px;
`;

const BodyWrapper = styled.div`
  padding: 40px 48px 0 17px;
  width: 100%;
`;

export default PageWrapper;
