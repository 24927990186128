const isJson = (str: any): boolean => {
  let value = typeof str !== 'string' ? JSON.stringify(str) : str;

  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  if (typeof value === 'object' && value !== null) {
    return true;
  }

  return false;
};

export default isJson;
