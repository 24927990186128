/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Icons from '../../assets/icons';
import { Icon } from '../atoms';
import { PatientType, FileType, ProcedureDataType } from '../../types/patients';
import { Loader } from '../molecules';
import { getNameByUrl } from '../molecules/ServerFiles';
import { linkHandler } from '../../utils';

type Props = {
  title: string;
  modal: string;
  masterData?: PatientType | null;
  procedure?: ProcedureDataType | null;
  pictures?: FileType[];
  className?: string;
};

export const viewModeModals = {
  PATIENT: 'patient',
  PROCEDURE: 'procedure',
  PICTURES: 'pictures',
};

const stringWithoutUnderline = (string: string) => string.replaceAll('_', ' ');
const stringToUpperCase = (string: string) => string[0].toUpperCase() + string.slice(1);
const checkBoolType = (arg: any) => {
  if (typeof arg === 'boolean') {
    return arg ? 'True' : 'False';
  }

  return arg;
};

const ViewMode: FC<Props> = ({ className, modal, title, masterData, procedure, pictures }) => {
  const [procedureData, setProcedureData] = useState({});

  useEffect(() => {
    if (procedure) {
      const {
        type,
        physician,
        six_month_success,
        year_success,
        create_time,
        create_user,
        change_time,
        change_user,
        date,
        ...rest
      } = procedure;

      setProcedureData(rest);
    }
  }, [procedure]);

  return (
    <Wrapper className={className} isData={viewModeModals.PICTURES !== modal}>
      <Header>{title}</Header>

      {viewModeModals.PATIENT === modal && (
        <DataBorder>
          {masterData && (
            <>
              <Line>
                Date of Birth <Date>{masterData.bornAt}</Date>
              </Line>
              <Line>
                Gender <Date>{masterData.gender}</Date>
              </Line>
              <Line>
                Comments <Date>{masterData.comments}</Date>
              </Line>
              <Line>
                Patient Name <Date>{masterData.fullName}</Date>
              </Line>
              <Line>
                Arterial Hypertension <Date>{masterData.arterialHypertension}</Date>
              </Line>
              <Line>
                Diabetes <Date>{masterData.diabetes}</Date>
              </Line>
              <Line>
                Previous Stroke / TIA <Date>{masterData.previousStroke}</Date>
              </Line>
              <Line>
                CAD <Date>{masterData.coronaryArteryDiseaseValue}</Date>
              </Line>
              <Line>
                CABG
                <Date>{masterData.coronaryArteryBypassGraft}</Date>
              </Line>
              <Line>
                EF [%] <Date>{masterData.ef}</Date>
              </Line>
              <Line>
                LA Diameter [mm] <Date>{masterData.laDiameter}</Date>
              </Line>
              <Line>
                LA area [cm²] <Date>{masterData.laArea}</Date>
              </Line>
              <Line>
                Mitral reguritation <Date>{masterData.mitralReguritationValue}</Date>
              </Line>
              <Line>
                Vascular disease <Date>{masterData.vascularDisease}</Date>
              </Line>
              <Line>
                Betablocker <Date>{masterData.betablocker}</Date>
              </Line>
              <Line>
                Antiarrhythmic drugs <Date>{masterData.antiarrhytmicDrugsValue}</Date>
              </Line>
              <Line>
                Marcumar <Date>{masterData.marcumar}</Date>
              </Line>
              <Line>
                Aspirin <Date>{masterData.aspirin}</Date>
              </Line>
              <Line>
                Create Date <Date>{masterData.createTime}</Date>
              </Line>
              <Line>
                Create User <Date>{masterData.createUser}</Date>
              </Line>
            </>
          )}
        </DataBorder>
      )}

      {viewModeModals.PROCEDURE === modal && (
        <DataBorder>
          {procedure ? (
            <>
              <Line>
                Procedure type <Date>{procedure.type}</Date>
              </Line>

              <Line>
                Procedure date <Date>{procedure.date}</Date>
              </Line>

              <Line>
                Physician <Date>{procedure.physician}</Date>
              </Line>

              <Line>
                Six month success <Date>{procedure.six_month_success ? 'True' : 'False'}</Date>
              </Line>

              <Line>
                Year success <Date>{procedure.year_success ? 'True' : 'False'}</Date>
              </Line>

              {Object.entries(procedureData).map((field) => (
                <Line key={field[0]}>
                  {stringWithoutUnderline(stringToUpperCase(field[0]))}{' '}
                  <Date>{checkBoolType(field[1])}</Date>
                </Line>
              ))}

              <Line>
                Change date <Date>{procedure.change_time}</Date>
              </Line>

              <Line>
                Change user <Date>{procedure.change_user}</Date>
              </Line>

              <Line>
                Create date <Date>{procedure.create_time}</Date>
              </Line>

              <Line>
                Create user <Date>{procedure.create_user}</Date>
              </Line>
            </>
          ) : (
            <CenteringWrapper>
              <Loader />
            </CenteringWrapper>
          )}
        </DataBorder>
      )}

      {viewModeModals.PICTURES === modal && (
        <Pictures>
          {pictures?.map((file) => (
            <Picture key={file.id} onClick={() => linkHandler(file.file)}>
              {file.thumbnail ? <Image src={file.thumbnail} /> : <Icon src={Icons.File} />}

              <ImageTitle>{getNameByUrl(file.file || '')}</ImageTitle>
            </Picture>
          ))}
        </Pictures>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<TitleType>`
  max-width: 818px;
  max-height: 700px;

  ${({ isData }) =>
    isData &&
    `
    min-width: 400px;
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  height: 31px;

  font-weight: 600;
  text-align: start;

  color: var(--color-black);
  background-color: var(--color-light);
  border: 1px solid var(--color-lines);
`;

const DataBorder = styled.div`
  border-left: 1px solid var(--color-lines);
  border-right: 1px solid var(--color-lines);
  border-bottom: 1px solid var(--color-lines);
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 24px;
  min-height: 30px;
  border-bottom: 1px solid var(--color-lines);

  &:last-of-type {
    border: none;
  }
`;

const Date = styled.div`
  width: 150px;
`;

const Pictures = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px 24px;
  width: auto;
  max-height: inherit;

  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 2px;
  }

  border-left: 1px solid var(--color-lines);
  border-right: 1px solid var(--color-lines);
  border-bottom: 1px solid var(--color-lines);
`;

const Picture = styled.div`
  width: 240px;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
`;

const Image = styled.img`
  width: inherit;
  height: 240px;
`;

const ImageTitle = styled.div`
  word-wrap: break-word;
  text-align: center;
`;

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type TitleType = {
  isData?: boolean;
};

export default ViewMode;
