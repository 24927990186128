import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { Button } from '../atoms';
import { Input as InputBase } from '../molecules';
import { userIsAuth } from '../../services/AuthServices';
import { ErrorName as ErrorNameBase } from '../atoms/Error';
import { ErrorType } from '../../types/general';

const Login: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const [error, setError] = useState<ErrorType | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    dispatch.user
      .LogIn({
        email: email.trim(),
        password: password.trim(),
      })
      .catch((e) => {
        if (e.response?.data) {
          setError(e.response.data);
        }
      });
  };

  useEffect(() => {
    if (userIsAuth()) {
      dispatch.user.checkAuth();
    }
  }, []);

  return (
    <Wrapper>
      <Content>
        <Title>Welcome</Title>

        <Form onSubmit={submitHandler}>
          <Input value={email} onChange={setEmail} error={error?.email} placeholder="Username" />

          <InputPassword
            password
            value={password}
            onChange={setPassword}
            error={error?.password}
            placeholder="Password"
          />

          <ErrorName>{error?.detail}</ErrorName>

          <Button type="submit">Login</Button>
        </Form>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Content = styled.div`
  width: 340px;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  text-align: center;

  font-weight: 500;
  font-size: 36px;
`;

const Form = styled.form``;

const Input = styled(InputBase)`
  margin-bottom: 24px;
`;

const InputPassword = styled(InputBase)`
  margin-bottom: 32px;
`;

const ErrorName = styled(ErrorNameBase)`
  padding-left: 16px;
`;

export default Login;
