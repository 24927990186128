import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { ModalWindow, FieldsTab, Input, Select } from '../molecules';
import { SelectionType, PatientType } from '../../types/patients';
import { ExaminationType } from '../../types/procedures';
import { ErrorType } from '../../types/general';
import { StartExaminationIds } from '../pages/Patients';
import { userRoles } from '../pages/Profile';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  startExamination?: (arg: StartExaminationIds) => void;
  title: string;
  notify?: (arg: string) => void;
  selectedPatient?: PatientType | null;
  setSelectedPatient?: (arg: PatientType | null) => void;
  updateSelectedPatient?: boolean;
};

export const convertToListOfStringsFromSelections = (list: SelectionType[]): string[] => {
  return list.map((item) => item.value);
};

export const convertToListOfStringsFromExamination = (list: ExaminationType[]): string[] => {
  return list.map((item) => item.name);
};

const uniqueValues = (list: string[]) => {
  return list.filter((item, index) => list.indexOf(item) === index);
};

const ModalPatientTable: FC<Props> = ({
  updateSelectedPatient,
  selectedPatient,
  setSelectedPatient,
  startExamination,
  closeModal,
  notify,
  ...props
}) => {
  const dispatch = useDispatch<Dispatch>();
  const procedures = useSelector((state: RootState) => state.procedures.data);
  const user = useSelector((state: RootState) => state.user.data?.group);
  const viewer = user === userRoles.viewer;

  const [coronaryArteryDiseaseOptions, setCoronaryArteryDiseaseOptions] = useState<SelectionType[]>(
    []
  );
  const [mitralReguritationOptions, setMitralReguritationOptions] = useState<SelectionType[]>([]);
  const [antiarrhytmicDrugsOptions, setAntiarrhytmicDrugsOptions] = useState<SelectionType[]>([]);
  const [procedureTypeOptions, setProcedureTypeOptions] = useState<ExaminationType[]>([]);
  const [errors, setErrors] = useState<ErrorType | null>(null);
  const errorsToListOfString = Object.entries(errors || {}).map((item) => item[1][0]);

  const [procedureTypeIsOpen, setProcedureTypeIsOpen] = useState(false);
  const [masterDataIsOpen, setMasterDataIsOpen] = useState(false);
  const [buttonIsActive, setButtonIsActive] = useState(false);

  const [id, setId] = useState('');
  const [bornAt, setBornAt] = useState('');
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [gender, setGender] = useState('');
  const [comments, setComments] = useState('');
  const [arterialHypertension, setArterialHypertension] = useState('');
  const [diabetes, setDiabetes] = useState('');
  const [previousStroke, setPreviousStroke] = useState('');
  const [coronaryArteryDisease, setCoronaryArteryDisease] = useState('');
  const [coronaryArteryBypassGraft, setCoronaryArteryBypassGraft] = useState('');
  const [ef, setEf] = useState('');
  const [laDiameter, setLaDiameter] = useState('');
  const [laArea, setLaArea] = useState('');
  const [mitralReguritation, setMitralReguritation] = useState('');
  const [vascularDisease, setVascularDisease] = useState('');
  const [betablocker, setBetablocker] = useState('');
  const [antiarrhytmicDrugs, setAntiarrhytmicDrugs] = useState('');
  const [marcumar, setMarcumar] = useState('');
  const [aspirin, setAspirin] = useState('');
  const [procedureType, setProcedureType] = useState('');
  const [createUser, setCreateUser] = useState('');
  const [createDate, setCreateDate] = useState('');

  const findIdByValue = (fieldName: string, value: string) => {
    if (fieldName === 'coronaryArteryDisease') {
      const field = coronaryArteryDiseaseOptions.find((item) => item.value === value);
      return field ? field.id : 0;
    }

    if (fieldName === 'mitralReguritation') {
      const field = mitralReguritationOptions.find((item) => item.value === value);
      return field ? field.id : 0;
    }

    if (fieldName === 'antiarrhytmicDrugs') {
      const field = antiarrhytmicDrugsOptions.find((item) => item.value === value);
      return field ? field.id : 0;
    }

    return 0;
  };

  const findValueById = (fieldName: string, fieldId: number) => {
    if (fieldName === 'coronaryArteryDisease') {
      const field = coronaryArteryDiseaseOptions.find((item) => item.id === fieldId);
      return field ? field.value : '';
    }

    if (fieldName === 'mitralReguritation') {
      const field = mitralReguritationOptions.find((item) => item.id === fieldId);
      return field ? field.value : '';
    }

    if (fieldName === 'antiarrhytmicDrugs') {
      const field = antiarrhytmicDrugsOptions.find((item) => item.id === fieldId);
      return field ? field.value : '';
    }

    return '';
  };

  const masterData: PatientType = {
    id: id ? Number(id) : undefined,
    bornAt,
    name,
    firstName,
    gender,
    comments: comments || undefined,
    arterialHypertension,
    diabetes,
    previousStroke,
    coronaryArteryDisease: findIdByValue('coronaryArteryDisease', coronaryArteryDisease),
    coronaryArteryBypassGraft,
    ef: Number(ef),
    laDiameter: Number(laDiameter),
    laArea: Number(laArea),
    mitralReguritation: findIdByValue('mitralReguritation', mitralReguritation),
    vascularDisease,
    betablocker,
    antiarrhytmicDrugs: findIdByValue('antiarrhytmicDrugs', antiarrhytmicDrugs),
    marcumar,
    aspirin,
  };

  const handleSaveInfo = () => {
    handleRequiredFields();

    if (procedureTypeIsOpen && !procedureType) {
      setErrors(
        errors
          ? { ...errors, procedureType: ['Procedure type is required'] }
          : { procedureType: ['Procedure type is required'] }
      );
      return;
    }

    if (errors) {
      delete errors.procedureType;
    }

    if (masterDataIsFilled(masterData)) {
      handleSaveMasterData(masterData);
    }
  };

  const handleCloseModal = () => {
    clearData();
    closeModal();
  };

  const masterDataIsFilled = (fields: PatientType) => {
    const fieldsList = Object.entries(fields);

    const result = fieldsList.every((field) => {
      if (field[0] === 'id' || field[0] === 'comments') {
        return true;
      }

      if (field[0] === 'ef' && field[1] === 0) {
        return true;
      }

      if (field[0] === 'laDiameter' && field[1] === 0) {
        return true;
      }

      if (field[0] === 'laArea' && field[1] === 0) {
        return true;
      }

      if (!field[1]) {
        return false;
      }

      return true;
    });

    return result;
  };

  const handleRequiredFields = () => {
    const fieldsList = Object.entries(masterData);
    const requiredFields: ErrorType = {};

    fieldsList.map((field) => {
      if (field[0] === 'id' || field[0] === 'comments') {
        return;
      }

      if (!field[1]) {
        requiredFields[field[0]] = ['Please fill all mandatory fields'];
        return;
      }

      if (errors) {
        delete requiredFields[field[0]];
      }
    });

    if (Object.keys(requiredFields).length !== 0) {
      setErrors(requiredFields);
    }
  };

  const allFieldsAreFilled = procedureType && masterDataIsFilled(masterData);

  const handleSaveMasterData = (fields: PatientType) => {
    if (!buttonIsActive) {
      setButtonIsActive(true);

      if (selectedPatient) {
        dispatch.patients
          .updatePatient(fields)
          .then((response) => {
            handleCloseModal();

            if (updateSelectedPatient && setSelectedPatient) {
              setSelectedPatient(response);
            }

            if (notify) {
              notify('The patient has been updated');
            }
          })
          .catch((e) => {
            if (e.response?.data) {
              setErrors(e.response.data);
            }
          })
          .finally(() => setButtonIsActive(false));
      } else {
        dispatch.patients
          .addPatient(fields)
          .then((response) => {
            handleCloseModal();

            if (response.id) {
              handleSaveProcedureType(response.id);
            }

            if (notify) {
              notify('The patient has been saved');
            }
          })
          .catch((e) => {
            if (e.response?.data) {
              setErrors(e.response.data);
            }
          })
          .finally(() => setButtonIsActive(false));
      }
    }
  };

  const handleSaveProcedureType = (patientId: number) => {
    if (startExamination) {
      const foundId = procedureTypeOptions.find((item) => item.name === procedureType)?.id;

      if (foundId) {
        const data = {
          procedure: foundId,
          patient: patientId,
        };

        startExamination(data);
      }
    }
  };

  const clearData = () => {
    setErrors(null);
    setProcedureTypeIsOpen(false);

    if (selectedPatient) {
      setMasterDataIsOpen(true);
    } else {
      setMasterDataIsOpen(false);

      setId('');
      setBornAt('');
      setName('');
      setFirstName('');
      setGender('');
      setComments('');
      setArterialHypertension('');
      setDiabetes('');
      setPreviousStroke('');
      setCoronaryArteryDisease('');
      setCoronaryArteryBypassGraft('');
      setEf('');
      setLaDiameter('');
      setLaArea('');
      setMitralReguritation('');
      setVascularDisease('');
      setBetablocker('');
      setAntiarrhytmicDrugs('');
      setMarcumar('');
      setAspirin('');
      setProcedureType('');
    }

    if (setSelectedPatient && !updateSelectedPatient) {
      setSelectedPatient(null);
    }
  };

  useEffect(() => {
    dispatch.patients.getSelections('coronary_artery_disease').then((response) => {
      setCoronaryArteryDiseaseOptions(response.results);
    });

    dispatch.patients.getSelections('mitral_reguritation').then((response) => {
      setMitralReguritationOptions(response.results);
    });

    dispatch.patients.getSelections('antiarrhytmic_drugs').then((response) => {
      setAntiarrhytmicDrugsOptions(response.results);
    });

    dispatch.procedures.getTable();
  }, []);

  useEffect(() => {
    setProcedureTypeOptions(procedures.results);
  }, [procedures]);

  useEffect(() => {
    if (selectedPatient && selectedPatient.id) {
      setMasterDataIsOpen(true);

      setId(String(selectedPatient.id));
      setBornAt(selectedPatient.bornAt);
      setName(selectedPatient.name);
      setFirstName(selectedPatient.firstName);
      setGender(selectedPatient.gender);
      setComments(selectedPatient.comments ? selectedPatient.comments : '');
      setArterialHypertension(selectedPatient.arterialHypertension);
      setDiabetes(selectedPatient.diabetes);
      setPreviousStroke(selectedPatient.previousStroke);
      setCoronaryArteryDisease(
        findValueById('coronaryArteryDisease', selectedPatient.coronaryArteryDisease)
      );
      setCoronaryArteryBypassGraft(selectedPatient.coronaryArteryBypassGraft);
      setEf(String(selectedPatient.ef));
      setLaDiameter(String(selectedPatient.laDiameter));
      setLaArea(String(selectedPatient.laArea));
      setMitralReguritation(
        findValueById('mitralReguritation', selectedPatient.mitralReguritation)
      );
      setVascularDisease(selectedPatient.vascularDisease);
      setBetablocker(selectedPatient.betablocker);
      setAntiarrhytmicDrugs(
        findValueById('antiarrhytmicDrugs', selectedPatient.antiarrhytmicDrugs)
      );
      setMarcumar(selectedPatient.marcumar);
      setAspirin(selectedPatient.aspirin);
      setCreateUser(selectedPatient?.createUser || '');
      setCreateDate(selectedPatient.createTime || '');
    }
  }, [selectedPatient]);

  return (
    <ModalWindow
      saveInfo={handleSaveInfo}
      closeModal={handleCloseModal}
      buttonTitle={allFieldsAreFilled ? 'Start examination' : 'Save'}
      error={uniqueValues(errorsToListOfString)}
      buttonDisabled={buttonIsActive || viewer}
      {...props}
    >
      <FieldsTab isOpen={masterDataIsOpen} setIsOpen={setMasterDataIsOpen} title="Master data">
        <CenteringWrapper>
          <Content>
            {selectedPatient && (
              <Input
                value={id}
                onChange={setId}
                placeholder="Patient ID"
                additionalInfo="ID provided by the system if a new patient is created"
                readOnly
                required
              />
            )}

            <Input
              value={name}
              onChange={setName}
              placeholder="Name"
              errorBorder={!!errors?.name}
              required
            />

            <Input
              value={firstName}
              onChange={setFirstName}
              placeholder="First Name"
              errorBorder={!!errors?.firstName}
              required
            />

            <Input
              value={bornAt}
              onChange={setBornAt}
              placeholder="Date of Birth"
              errorBorder={!!errors?.bornAt}
              readOnly={viewer}
              additionalInfo="format: dd.mm.YYYY"
              required
            />

            <Select
              value={gender}
              onChange={setGender}
              options={['Male', 'Female']}
              placeholder="Gender"
              errorBorder={!!errors?.gender}
              readOnly={viewer}
              required
            />

            <Input
              value={comments}
              onChange={setComments}
              placeholder="Comments"
              readOnly={viewer}
            />

            <Select
              value={arterialHypertension}
              onChange={setArterialHypertension}
              options={['Yes', 'No', 'NA']}
              placeholder="Arterial Hypertension"
              errorBorder={!!errors?.arterialHypertension}
              readOnly={viewer}
              required
            />

            <Select
              value={diabetes}
              onChange={setDiabetes}
              options={['Yes', 'No', 'NA']}
              placeholder="Diabetes"
              errorBorder={!!errors?.diabetes}
              readOnly={viewer}
              required
            />

            <Select
              value={previousStroke}
              onChange={setPreviousStroke}
              options={['Yes', 'No', 'NA']}
              placeholder="Previous Stroke / TIA"
              errorBorder={!!errors?.previousStroke}
              readOnly={viewer}
              required
            />

            <Select
              value={coronaryArteryDisease}
              onChange={setCoronaryArteryDisease}
              options={convertToListOfStringsFromSelections(coronaryArteryDiseaseOptions)}
              placeholder="CAD"
              errorBorder={!!errors?.coronaryArteryDisease}
              readOnly={viewer}
              required
            />

            <Select
              value={coronaryArteryBypassGraft}
              onChange={setCoronaryArteryBypassGraft}
              options={['Yes', 'No', 'NA']}
              placeholder="CABG (coronary artery bypass graft)"
              errorBorder={!!errors?.coronaryArteryBypassGraft}
              readOnly={viewer}
              required
            />

            <Input
              value={ef}
              onChange={setEf}
              placeholder="EF [%]"
              additionalInfo="if NA enter '0'"
              errorBorder={!!errors?.ef}
              readOnly={viewer}
              required
            />

            <Input
              value={laDiameter}
              onChange={setLaDiameter}
              placeholder="LA Diameter [mm]"
              additionalInfo="if NA enter '0'"
              errorBorder={!!errors?.laDiameter}
              readOnly={viewer}
              required
            />

            <Input
              value={laArea}
              onChange={setLaArea}
              placeholder="LA area [cm²]"
              additionalInfo="if NA enter '0'"
              errorBorder={!!errors?.laArea}
              readOnly={viewer}
              required
            />

            <Select
              value={mitralReguritation}
              onChange={setMitralReguritation}
              options={convertToListOfStringsFromSelections(mitralReguritationOptions)}
              placeholder="Mitral reguritation"
              errorBorder={!!errors?.mitralReguritation}
              readOnly={viewer}
              required
            />

            <Select
              value={vascularDisease}
              onChange={setVascularDisease}
              options={['Yes', 'No', 'NA']}
              placeholder="Vascular disease"
              errorBorder={!!errors?.vascularDisease}
              readOnly={viewer}
              required
            />

            <Select
              value={betablocker}
              onChange={setBetablocker}
              options={['Yes', 'No', 'NA']}
              placeholder="Betablocker"
              errorBorder={!!errors?.betablocker}
              readOnly={viewer}
              required
            />

            <Select
              value={antiarrhytmicDrugs}
              onChange={setAntiarrhytmicDrugs}
              options={convertToListOfStringsFromSelections(antiarrhytmicDrugsOptions)}
              placeholder="Antiarrhythmic drugs"
              errorBorder={!!errors?.antiarrhytmicDrugs}
              readOnly={viewer}
              required
            />

            <Select
              value={marcumar}
              onChange={setMarcumar}
              options={['Yes', 'No', 'NA']}
              placeholder="Marcumar"
              errorBorder={!!errors?.marcumar}
              readOnly={viewer}
              required
            />

            <Select
              value={aspirin}
              onChange={setAspirin}
              options={['Yes', 'No', 'NA']}
              placeholder="Aspirin"
              errorBorder={!!errors?.aspirin}
              readOnly={viewer}
              required
            />

            {selectedPatient && (
              <>
                {
                  <Input
                    value={createDate.trim()}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChange={() => {}}
                    placeholder="Create Date"
                    readOnly
                  />
                }
                {
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  <Input
                    value={createUser.trim()}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChange={() => {}}
                    placeholder="Create User"
                    readOnly
                  />
                }
              </>
            )}
          </Content>
        </CenteringWrapper>
      </FieldsTab>

      {!selectedPatient && (
        <FieldsTabStyled
          patient={!!selectedPatient}
          title="Add  procedure data"
          isOpen={procedureTypeIsOpen}
          setIsOpen={setProcedureTypeIsOpen}
        >
          <CenteringWrapper>
            <Content>
              <Select
                value={procedureType}
                onChange={setProcedureType}
                options={convertToListOfStringsFromExamination(procedureTypeOptions)}
                placeholder="Procedure type"
                additionalInfo="The type of procedure that is performed"
                errorBorder={!!errors?.procedureType}
                required
              />
            </Content>
          </CenteringWrapper>
        </FieldsTabStyled>
      )}
    </ModalWindow>
  );
};

const FieldsTabStyled = styled(FieldsTab)<FieldsTabStyledType>`
  ${({ patient }) =>
    !patient &&
    `
    margin-top: 40px;
  `}
`;

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

const Content = styled.div`
  width: 340px;

  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

type FieldsTabStyledType = {
  patient: boolean;
};

export default ModalPatientTable;
