import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '.';
import Icons from '../../assets/icons';

type Props = {
  primary?: boolean;
  secondary?: boolean;
  children?: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const AddButton: FC<Props> = ({ className, primary, secondary, children, onClick }) => {
  return (
    <Wrapper
      type="button"
      onClick={onClick}
      className={className}
      primary={primary}
      secondary={secondary}
    >
      <Plus src={Icons.Plus} />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(Button)<WrapperType>`
  display: flex;
  align-items: center;
  height: 48px;

  ${({ primary }) =>
    primary &&
    `
      padding: 0 16px;
      border-color: var(--color-lines);
  `}

  ${({ secondary }) =>
    secondary &&
    `
      padding: 0;
      color: var(--color-dark);
      background-color: transparent;
      border-color: transparent;
  `}
`;

const Plus = styled.img`
  margin-right: 9px;
`;

type WrapperType = {
  primary?: boolean;
  secondary?: boolean;
};

export default AddButton;
