import React, { FC } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { userRoles } from '../components/pages/Profile';
import { url } from './routes';
import { PublicRoute, PrivateRoute } from '../components/features';
import {
  Login,
  Procedures,
  Studies,
  Users,
  LandingPage,
  Patients,
  Profile,
  Logout,
  Examination,
  Patient,
  LandingPageQueryResults,
  Search,
  QueryData,
  Page404,
  Physicians,
} from '../components/pages';

const AppRouter: FC = () => {
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user.data?.group);
  const admin = user === userRoles.admin;

  if (location.pathname === '/') {
    return <Redirect to={url.LOGIN} />;
  }

  return (
    <Switch>
      <PublicRoute path={url.LOGIN} component={Login} />

      <PrivateRoute exact path={url.LANDINGPAGE} component={LandingPage} />
      <PrivateRoute
        exact
        path={url.LANDINGPAGE_QUERY_RESULTS}
        component={LandingPageQueryResults}
      />
      <PrivateRoute exact path={url.SEARCH} component={Search} />
      <PrivateRoute exact path={url.QUERY_DATA} component={QueryData} />
      <PrivateRoute exact path={url.PATIENTS} component={Patients} />
      <PrivateRoute exact path={url.PATIENT} component={Patient} />

      <PrivateRoute exact path={url.PROFILE} component={Profile} />
      <PrivateRoute exact path={url.LOGOUT} component={Logout} />
      <PrivateRoute exact path={url.PAGE_NOT_FOUND} component={Page404} />

      {admin && (
        <Switch>
          <PrivateRoute exact path={url.PHYSICIANS} component={Physicians} />
          <PrivateRoute exact path={url.PROCEDURES} component={Procedures} />
          <PrivateRoute exact path={url.EXAMINATION} component={Examination} />
          <PrivateRoute exact path={url.STUDIES} component={Studies} />
          <PrivateRoute exact path={url.USERS} component={Users} />
        </Switch>
      )}
    </Switch>
  );
};

export default AppRouter;
