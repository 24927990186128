import React, { FC } from 'react';
import styled from '@emotion/styled';

type Props = {
  value: string;
  onClick: (arg: string) => void;
  className?: string;
};

export const operatorVariants = {
  AND: 'and',
  OR: 'or',
};

const AndOrButton: FC<Props> = ({ className, value, onClick }) => {
  return (
    <Wrapper className={className}>
      <Button active={operatorVariants.AND === value} onClick={() => onClick(operatorVariants.AND)}>
        And
      </Button>

      <Button active={operatorVariants.OR === value} onClick={() => onClick(operatorVariants.OR)}>
        Or
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const Button = styled.div<ButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 38px;
  height: 24px;

  font-size: 12px;
  border: 1px solid black;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    color: var(--color-white);
    background-color: var(--color-black);
  `}
`;

type ButtonType = {
  active: boolean;
};

export default AndOrButton;
