import React from 'react';
import styled from '@emotion/styled';
import { useTable, Column } from 'react-table';
import { Thead, Tbody } from '../molecules';

type Props<T> = {
  columns: Column[];
  data: T[];
  handleEdit?: (id: number) => void;
  handleDelete?: (id: number) => void;
  setCurrentFieldId?: (id: number) => void;
  setHoverFieldId?: (id: number) => void;
  handleClickField?: (id: number) => void;
  handleViewButton?: (id: number) => void;
  handleAttachedFiles?: (id: number) => void;
  isActiveVariant?: string;
  dragAndDrop?: boolean;
  className?: string;
  search?: string;
};

const Table = <T extends {}>({
  columns,
  data,
  handleEdit,
  handleDelete,
  setCurrentFieldId,
  setHoverFieldId,
  handleClickField,
  handleViewButton,
  handleAttachedFiles,
  isActiveVariant,
  dragAndDrop,
  className,
  search,
}: Props<T>): JSX.Element => {
  // @ts-ignore
  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <TableWrapper className={className} {...getTableProps()}>
      <Thead headerGroups={headerGroups} />
      <Tbody
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        prepareRow={prepareRow}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        setCurrentFieldId={setCurrentFieldId}
        setHoverFieldId={setHoverFieldId}
        handleClickField={handleClickField}
        handleViewButton={handleViewButton}
        handleAttachedFiles={handleAttachedFiles}
        isActiveVariant={isActiveVariant}
        dragAndDrop={dragAndDrop}
        search={search}
      />
    </TableWrapper>
  );
};

export const TableWrapper = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

export default Table;
