import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { ModalWindow, Input } from '../molecules';
import { ErrorType } from '../../types/general';
import { uniqueErrors } from '../../utils';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  userId: number | null;
  title: string;
  goBack: () => void;
};

const ModalResetPassword: FC<Props> = ({ closeModal, userId, ...props }) => {
  const dispatch = useDispatch<Dispatch>();

  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<ErrorType | null>(null);
  const [buttonIsActive, setButtonIsActive] = useState(false);

  const handleSaveInfo = () => {
    if (userId && !buttonIsActive) {
      setButtonIsActive(true);

      const data = {
        profile: {
          password,
        },
        id: userId,
      };

      dispatch.user
        .updateUser(data)
        .then(() => clearAndCloseModal())
        .catch((e) => {
          if (e.response?.data) {
            setErrors(e.response.data);
          }
        })
        .finally(() => setButtonIsActive(false));
    }
  };

  const clearAndCloseModal = () => {
    setErrors(null);
    setPassword('');
    closeModal();
  };

  return (
    <ModalWindow
      saveInfo={handleSaveInfo}
      closeModal={clearAndCloseModal}
      error={uniqueErrors(errors)}
      buttonDisabled={buttonIsActive}
      {...props}
    >
      <CenteringWrapper>
        <Content>
          <Input
            value={password}
            onChange={setPassword}
            placeholder="New initial password"
            errorBorder={!!errors?.password}
            required
          />

          <Prompt>Use 8 or more characters with a mix of letters, numbers & symbols</Prompt>
        </Content>
      </CenteringWrapper>
    </ModalWindow>
  );
};

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 340px;
`;

const Prompt = styled.div`
  margin-top: 8px;
  font-size: 10px;
  color: var(--color-dark);
`;

export default ModalResetPassword;
