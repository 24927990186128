import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTable } from 'react-table';
import { Tbody, TheadSection } from '.';
import { SectionType } from '../../types/procedures';
import { headerActions } from '../pages/Examination';
import { Icon } from '../atoms';
import Icons from '../../assets/icons';

type Props<T> = {
  data: T[];
  section: SectionType;
  handleEdit?: (id: number) => void;
  handleDelete?: (id: number) => void;
  sectionActions: (action: string, name: string) => void;
  setCurrentFieldId: (id: number) => void;
  setHoverFieldId: (id: number) => void;
  setCurrentSection: (arg: SectionType) => void;
  setHoverSection: (arg: SectionType) => void;
  handleClickField?: (id: number) => void;
  handleViewButton?: (id: number) => void;
  isActiveVariant?: string;
  search?: string;
};

const Tsection = <T extends {}>({
  data,
  section,
  handleEdit,
  handleDelete,
  sectionActions,
  setCurrentFieldId,
  setHoverFieldId,
  setCurrentSection,
  setHoverSection,
  handleClickField,
  handleViewButton,
  isActiveVariant,
  search,
}: Props<T>): JSX.Element => {
  const columns = useMemo(
    () => [
      {
        Header: section.name,
        accessor: headerActions.LABEL,
      },
      {
        Header: '',
        accessor: headerActions.DATA_TYPE,
      },
      {
        Header: '',
        accessor: headerActions.NULL,
      },
      {
        Header: '',
        accessor: headerActions.HELP_TEXT,
      },
      {
        Header: '',
        accessor: headerActions.CHOICES,
      },
      {
        Header: (
          <Buttons>
            <Edit onClick={() => sectionActions('edit', section.name)} src={Icons.Edit} />

            <Trash src={Icons.Trash} onClick={() => sectionActions('delete', section.name)} />
          </Buttons>
        ),
        accessor: headerActions.ACTIONS,
      },
    ],
    [section]
  );

  // @ts-ignore
  const filteredData = data.filter((item) => section.fields.includes(item.id));

  // @ts-ignore
  const tableInstance = useTable({ columns, data: filteredData });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const dragOverHandler = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
  };

  const dragStartHandler = () => {
    setCurrentSection(section);
  };

  const dropHandler = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    setHoverSection(section);
  };

  return (
    <TableWrapper {...getTableProps()}>
      <TheadSection
        headerGroups={headerGroups}
        dragOverHandler={dragOverHandler}
        dragStartHandler={dragStartHandler}
        dropHandler={dropHandler}
      />

      <Tbody
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        prepareRow={prepareRow}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        setCurrentFieldId={setCurrentFieldId}
        setHoverFieldId={setHoverFieldId}
        handleClickField={handleClickField}
        handleViewButton={handleViewButton}
        isActiveVariant={isActiveVariant}
        dragAndDrop
        search={search}
      />
    </TableWrapper>
  );
};

const TableWrapper = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
`;

const Edit = styled(Icon)`
  &:hover {
    opacity: 0.7;
  }
`;

const Trash = styled(Icon)`
  svg path {
    fill: var(--color-error);
  }

  &:hover {
    opacity: 0.7;
  }
`;

export default Tsection;
