import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { Field, Icon } from '../atoms';
import Icons from '../../assets/icons';

type Props = {
  index: number;
  option: string;
  onClick: (arg: string) => void;
  onChange: (arg: string, index: number) => void;
  onDragOver: React.DragEventHandler<HTMLDivElement>;
  onDragStart: React.DragEventHandler<HTMLDivElement>;
  onDrop: React.DragEventHandler<HTMLDivElement>;
  className?: string;
};

const Option: FC<Props> = ({ className, option, index, onClick, onChange, ...props }) => {
  const [isDraggable, setIsDraggable] = useState(false);

  return (
    <Wrapper className={className} draggable={isDraggable} {...props}>
      <DragAndDrop
        className="dragndrop"
        onMouseDown={() => setIsDraggable(true)}
        onMouseOut={() => setIsDraggable(false)}
        src={Icons.DragAndDrop}
      />

      <Circle draggable={isDraggable} />

      <InputField
        value={option}
        onChange={(e) => onChange(e.target.value, index)}
        placeholder="Option"
        draggable={isDraggable}
      />

      <Cross src={Icons.CrossToClose} onClick={() => onClick(option)} />
    </Wrapper>
  );
};

const Wrapper = styled(Field)<FieldType>`
  position: relative;
  align-items: center;

  svg path {
    fill: var(--color-grey);
  }

  &:hover .dragndrop {
    display: block;
  }

  ${({ draggable }) =>
    draggable &&
    `
    background-color: var(--color-lines);
  `}
`;

const InputField = styled.input<FieldType>`
  padding: 0 11px;
  width: 100%;

  color: var(--color-dark);
  background-color: var(--color-light);
  border: none;
  outline: none;

  &::placeholder {
    color: var(--color-grey);
  }

  ${({ draggable }) =>
    draggable &&
    `
    background-color: var(--color-lines);
  `}
`;

const Circle = styled.div<FieldType>`
  width: 15px;
  height: 15px;

  background-color: var(--color-white);
  border: 2px solid var(--color-grey);
  border-radius: 50%;

  ${({ draggable }) =>
    draggable &&
    `
    background-color: var(--color-lines);
  `}
`;

const Cross = styled(Icon)`
  position: absolute;
  right: 16px;
`;

const DragAndDrop = styled.img`
  display: none;
  position: absolute;
  left: -35px;
  padding: 15px;

  cursor: pointer;

  &:hover {
    display: block;
  }
`;

type FieldType = {
  draggable?: boolean;
};

export default Option;
