import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';
import { getAccessToken, checkAuth, setAccessToken, removeTokens } from '../services/AuthServices';
import api from './api';
import history from '../history';
import { url } from '../router/routes';
import { isJson } from '../utils';

let isRefreshing = false;
let requestsQueue: ((token: string) => void)[] = [];

const onGetRefreshToken = (token: string) => {
  requestsQueue.map((callback) => callback(token));
};

const addToRequestQueue = (callback: (token: string) => void) => {
  requestsQueue.push(callback);
};

export const onRequestTokenInterceptor = (request: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getAccessToken();

  if (!token) {
    return request;
  }

  request.headers.Authorization = `Bearer ${token}`;

  return request;
};

export const makeRequestValidData = (request: AxiosRequestConfig): AxiosRequestConfig => {
  if (request.headers['Content-Type'] === 'multipart/form-data') return request;

  // do not convert 'procedure' object to patient page
  if (
    request.method !== 'get' &&
    request.url?.includes('procedure_types') &&
    request.url.includes('records') &&
    !request.url.includes('zip_files') &&
    !request.url.includes('get_files')
  ) {
    return request;
  }

  if (request.data) {
    request.data = snakeCaseKeys(request.data, { deep: true });
  }

  return request;
};

export const makeResponseValidData = (response: AxiosResponse): AxiosResponse => {
  // do not convert 'procedure' object to patient page
  if (
    response.config.method === 'get' &&
    response.config.url?.includes('procedure_types') &&
    response.config.url.includes('records') &&
    !response.config.url.includes('zip_files') &&
    !response.config.url.includes('get_files')
  ) {
    return response;
  }

  if (response.data) {
    response.data = camelCaseKeys(response.data, { deep: true });
  }

  return response;
};

export const makeResponseErrorData = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.data) {
    error.response.data = camelCaseKeys(error.response.data, { deep: true });
  }

  const { config: originalRequest } = error;

  if (error.response?.status === 401) {
    if (originalRequest.url === '/token/refresh/') {
      removeTokens();
      history.push(url.LOGIN);
      return Promise.reject(error);
    }

    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const token = await checkAuth();
        isRefreshing = false;
        onGetRefreshToken(token.data.access);
        setAccessToken(token.data.access);
        requestsQueue = [];
        originalRequest.headers.Authorization = `Bearer ${token.data.access}`;
        if (originalRequest.data && isJson(originalRequest.data)) {
          originalRequest.data = JSON.parse(originalRequest.data);
        }
        return api.request(originalRequest);
      } catch (e) {
        removeTokens();
        history.push(url.LOGIN);
        return Promise.reject(error);
      }
    }

    return new Promise((resolve) => {
      addToRequestQueue((token: string) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(api.request(originalRequest));
      });
    });
  }

  return Promise.reject(error);
};
