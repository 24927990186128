import React, { FC } from 'react';
import styled from '@emotion/styled';

type Props = {
  title: string;
  onClick: () => void;
  isCurrent: boolean;
  className?: string;
};

const SidebarButton: FC<Props> = ({ className, title, onClick, isCurrent }) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      <Logo isCurrent={isCurrent} />
      <Title isCurrent={isCurrent}>{title}</Title>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  &.active {
    color: var(--color-black) !important;
  }
`;

const Logo = styled.div<LogoType>`
  margin-bottom: 8px;

  width: 18px;
  height: 18px;

  border-radius: 50%;
  background-color: ${({ isCurrent }) => (isCurrent ? 'var(--color-black)' : 'var(--color-grey)')};
`;

const Title = styled.span<TitleType>`
  color: ${({ isCurrent }) => (isCurrent ? 'var(--color-black)' : 'var(--color-grey)')};
  font-size: 10px;
`;

type LogoType = {
  isCurrent: boolean;
};

type TitleType = {
  isCurrent: boolean;
};

export default SidebarButton;
