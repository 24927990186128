import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { AddButton, Error as ErrorBase, Field as FieldBase } from '../atoms';
import Icons from '../../assets/icons';

type Props = {
  setLocalFiles: (arg: FormData | null) => void;
  procedure?: number | null;
  error: string[] | null;
  setFileError: (arg: string[] | null) => void;
  className?: string;
};

const File: FC<Props> = ({ className, procedure, error, setFileError, setLocalFiles }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = async (event: React.SyntheticEvent) => {
    const formData = new FormData();
    const fileUploaded = (event.target as HTMLInputElement).files;

    let fileSize = 0;

    if (fileUploaded?.[0] && procedure) {
      for (let i = 0; i < fileUploaded?.length; i += 1) {
        const fileSizeInMb = fileUploaded[i].size / 1000000;
        fileSize += fileSizeInMb;

        formData.append('file', fileUploaded[i]);
      }

      if (fileSize > 10) {
        const discriptionError = 'Maximum upload file size: 10MB';
        setFileError([discriptionError]);
        return;
      }

      setLocalFiles(formData);
      setFileError(null);
    }
  };

  return (
    <Wrapper className={className}>
      <InputFile type="file" multiple ref={hiddenFileInput} onChange={handleChange} />

      {error ? (
        <Field error={!!error}>
          <Alert src={Icons.Alert} />
          Attachment failed
          <SelectFile type="button" onClick={handleClick}>
            Select file
          </SelectFile>
        </Field>
      ) : (
        <AddButton primary onClick={handleClick}>
          Add file
        </AddButton>
      )}

      {error && <Error error={error} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const InputFile = styled.input`
  display: none;
`;

const Field = styled(FieldBase)<ErrorPropsType>`
  display: flex;
  align-items: center;
  padding-left: 19px;

  ${({ error }) =>
    error &&
    `
    border: 1px solid var(--color-error);
  `}
`;

const Alert = styled.img`
  margin-right: 11px;
  width: 18px;
  height: 18px;
`;

const SelectFile = styled.button`
  margin-left: 11px;
  cursor: pointer;
`;

const Error = styled(ErrorBase)`
  margin-top: 8px;
`;

type ErrorPropsType = {
  error: boolean;
};

export default File;
