import styled from '@emotion/styled';

const Button = styled.button<ButtonType>`
  width: 100%;
  height: 40px;

  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  color: var(--color-black);

  cursor: pointer;

  &:active {
    background-color: var(--color-lines);
  }

  ${({ secondary }) =>
    secondary &&
    `
    color: var(--color-white);
    background-color: var(--color-black);
    border-color: var(--color-black);

    &:active {
      border-color: var(--color-lines);
    }
  `}

  ${({ confirm }) =>
    confirm &&
    `
    color: var(--color-error);
    background-color: var(--color-red);
    border-color: var(--color-red);

    &:active {
      border-color: var(--color-lines);
    }
  `}

  ${({ error }) =>
    error &&
    `
    border: 1.5px solid var(--color-error);
    color: var(--color-error);

    &:active {
      background-color: var(--color-red);
    }
  `}

  ${({ noFrames }) =>
    noFrames &&
    `
      border-color: transparent;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background-color: var(--color-lines);
    border-color: var(--color-lines);
    opacity: 0.5;
  `}
`;

type ButtonType = {
  secondary?: boolean;
  confirm?: boolean;
  noFrames?: boolean;
  disabled?: boolean;
  error?: boolean;
};

export default Button;
