import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { ModalWindow, Input, Select, Loader } from '../molecules';
import { ErrorType } from '../../types/general';
import { uniqueErrors } from '../../utils';

type Props = {
  modalIsOpen: boolean;
  closeModal: () => void;
  title: string;
  error?: string[] | null;
  buttonTitle?: string;
  buttonDisabled?: boolean;
  onAfterOpen?: () => void;
  goBack?: () => void;
  studyId?: number | null;
};

const statusVariants = ['Activate', 'Deactivate'];
const generalDescriptionError = 'This field may not be blank.';
const targetDescriptionError = 'This field must be a number.';

const ModalStudyTable: FC<Props> = ({ closeModal, studyId, ...props }) => {
  const dispatch = useDispatch<Dispatch>();

  const formatDate = 'yyyy-MM-dd';
  const createdOn = format(new Date(), formatDate);

  const [name, setName] = useState('');
  const [target, setTarget] = useState('');
  const [isActive, setIsActive] = useState('');
  const [errors, setErrors] = useState<ErrorType | null>(null);
  const [loadingStudy, setLoadingStudy] = useState(false);
  const [buttonIsActive, setButtonIsActive] = useState(false);

  const loading = loadingStudy && studyId;

  const handleSaveInfo = () => {
    if (errorHandler()) {
      return;
    }

    const data = {
      name,
      target: Number(target),
      isActive: isActive === 'Activate',
      createdOn,
    };

    if (!buttonIsActive) {
      setButtonIsActive(true);

      if (studyId) {
        const dataForSending = {
          id: studyId,
          data,
        };

        dispatch.studies
          .updateStudy(dataForSending)
          .then(() => {
            dispatch.studies.getTable();
            clearData();
            closeModal();
          })
          .catch((error) => setErrors(error))
          .finally(() => setButtonIsActive(false));
      } else {
        dispatch.studies
          .addStudy(data)
          .then(() => {
            dispatch.studies.getTable();
            clearData();
            closeModal();
          })
          .catch((error) => setErrors(error))
          .finally(() => setButtonIsActive(false));
      }
    }
  };

  const clearData = () => {
    setName('');
    setTarget('');
    setIsActive('');
    setErrors(null);
  };

  const errorHandler = () => {
    const unfilledFields: ErrorType = {};

    if (name === '') {
      unfilledFields.name = [generalDescriptionError];
    } else {
      delete unfilledFields.name;
    }

    if (target === '') {
      unfilledFields.target = [generalDescriptionError];
    } else if (Number.isNaN(Number(target))) {
      unfilledFields.target = [targetDescriptionError];
    } else {
      delete unfilledFields.target;
    }

    if (isActive === '') {
      unfilledFields.isActive = [generalDescriptionError];
    } else {
      delete unfilledFields.isActive;
    }

    setErrors(unfilledFields);

    return Object.keys(unfilledFields).length !== 0;
  };

  useEffect(() => {
    if (studyId) {
      setLoadingStudy(true);

      dispatch.studies
        .getStudy(studyId)
        .then((response) => {
          setName(response.name);
          setTarget(String(response.target));
          setIsActive(response.isActive ? 'Activate' : 'Deactivate');
          setLoadingStudy(false);
        })
        .catch(() => setLoadingStudy(false));
    }
  }, [studyId]);

  return (
    <ModalWindow
      saveInfo={handleSaveInfo}
      closeModal={() => {
        clearData();
        closeModal();
      }}
      error={uniqueErrors(errors)}
      buttonDisabled={buttonIsActive}
      {...props}
    >
      <CenteringContent>
        {loading ? (
          <Loader />
        ) : (
          <Content>
            <Input
              value={name}
              onChange={setName}
              placeholder="Name"
              errorBorder={!!errors?.name}
              required
            />

            <Input
              value={target}
              onChange={setTarget}
              placeholder="Target"
              errorBorder={!!errors?.target}
              required
            />

            <Select
              value={isActive}
              onChange={setIsActive}
              options={statusVariants}
              placeholder="Status"
              errorBorder={!!errors?.isActive}
              required
            />
          </Content>
        )}
      </CenteringContent>
    </ModalWindow>
  );
};

const Content = styled.div`
  width: 340px;

  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CenteringContent = styled.div`
  display: flex;
  justify-content: center;
`;

export default ModalStudyTable;
