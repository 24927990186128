import React, { FC } from 'react';
import Spinner from 'react-loader-spinner';

type Props = {
  small?: boolean;
};

const Loader: FC<Props> = ({ small }) => {
  return (
    <Spinner
      type="ThreeDots"
      color="#ACB0BA"
      height={small ? 30 : 100}
      width={small ? 30 : 100}
      timeout={99000} // 99 secs
    />
  );
};

export default Loader;
