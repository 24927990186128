import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  getPatients,
  setPatient,
  updatePatient,
  deletePatient,
  searchPatient,
  searchProcedure,
  getSelections,
  getProceduresData,
  setProcedureData,
  getAllProceduresForPatient,
  getPatient,
  getAllProcedureData,
  updateProcedureData,
  deleteProcedureData,
  getProcedureData,
  getFiles,
  getPatientFiles,
  getTaskIdForDownloadZip,
  getZipWithProcedureFiles,
  prepareFile,
  getPreparedFile,
  getFile,
  setFile,
  deleteFile,
  getQuery,
} from '../../services/PatientsServices';
import { PatientType, ProcedureDataType } from '../../types/patients';
import { QueryType, PaginationResponseType } from '../../types/general';
import store from '../index';

type InitialStateType<Patients> = {
  data: PaginationResponseType<Patients>;
  query: QueryType;
};

const initialState: InitialStateType<PatientType> = {
  data: {
    count: 0,
    results: [],
  },
  query: {
    pageNumber: 1,
    pageSize: 20,
    search: '',
  },
};

const patients = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setTable(state, data: PaginationResponseType<PatientType>) {
      return {
        ...state,
        data,
      };
    },
    setQuery(state, query: QueryType) {
      return {
        ...state,
        query,
      };
    },
    setClearPatients() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async getPatients() {
      try {
        const { query } = store.getState().patients;
        const response = await getPatients(query);

        dispatch.patients.setTable(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getPatient(id: number) {
      try {
        const response = await getPatient(id);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async addPatient(data: PatientType) {
      try {
        const response = await setPatient(data);

        dispatch.patients.getTable();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updatePatient(data: PatientType) {
      try {
        const response = await updatePatient(data);

        dispatch.patients.getTable();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async deletePatient(id: number) {
      try {
        await deletePatient(id);
      } catch (error) {
        throw error;
      }
    },
    async searchPatient() {
      try {
        const { query } = store.getState().patients;
        const response = await searchPatient(query);

        dispatch.patients.setTable(response.data);
      } catch (error) {
        throw error;
      }
    },
    async searchProcedure(query: QueryType) {
      try {
        const response = await searchProcedure(query);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getTable() {
      try {
        const { query } = store.getState().patients;
        const response = await getPatients(query);

        dispatch.patients.setTable(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getSelections(selection: string) {
      try {
        const response = await getSelections(selection);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getFiles() {
      try {
        const response = await getFiles();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getPatientFiles(id: number) {
      try {
        const response = await getPatientFiles(id);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async preparedFile(parameters: string) {
      try {
        const responseWithTaskId = await prepareFile(parameters);
        const { taskId } = responseWithTaskId.data;
        return taskId;
      } catch (error) {
        throw error;
      }
    },
    async getPreparedFile(taskId: string) {
      try {
        const responseWithFile = await getPreparedFile(taskId);
        return responseWithFile.data;
      } catch (error) {
        throw error;
      }
    },
    async getZipWithPrucedureFiles({
      stringWithFilesIds,
      procedureTypeId,
      procedureId,
    }: {
      stringWithFilesIds?: string;
      procedureTypeId: number;
      procedureId: number;
    }) {
      try {
        const responseWithTaskId = await getTaskIdForDownloadZip({
          stringWithFilesIds,
          procedureTypeId,
          procedureId,
        });

        const { taskId } = responseWithTaskId.data;

        const responseWithZipFile = await getZipWithProcedureFiles(
          procedureTypeId,
          procedureId,
          taskId
        );

        return responseWithZipFile.data;
      } catch (error) {
        throw error;
      }
    },
    async getFile(id: number) {
      try {
        const response = await getFile(id);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async addFile({
      procedureTypeId,
      procedureId,
      file,
    }: {
      procedureTypeId: number;
      procedureId: number;
      file: FormData;
    }) {
      try {
        const response = await setFile(procedureTypeId, procedureId, file);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async deleteFile(id: number) {
      try {
        await deleteFile(id);
      } catch (error) {
        throw error;
      }
    },
    async getProceduresData(procedureTypeId: number) {
      try {
        const response = await getProceduresData(procedureTypeId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getAllProcedureData(data: {
      procedureTypeId: number;
      pageNumber?: number;
      pageSize?: number;
    }) {
      try {
        const response = await getAllProcedureData(data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getAllProceduresForPatient({
      patientId,
      query,
    }: {
      patientId: number;
      query: QueryType;
    }) {
      try {
        const response = await getAllProceduresForPatient(patientId, query);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getProcedureData({
      procedureId,
      procedureTypeId,
    }: {
      procedureTypeId: number;
      procedureId: number;
    }) {
      try {
        const response = await getProcedureData(procedureTypeId, procedureId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async addProcedureData({
      procedureTypeId,
      data,
    }: {
      procedureTypeId: number;
      data: ProcedureDataType;
    }) {
      try {
        const response = await setProcedureData(procedureTypeId, data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateProcedureData({
      procedureId,
      procedureTypeId,
      data,
    }: {
      procedureTypeId: number;
      procedureId: number;
      data: ProcedureDataType;
    }) {
      try {
        const response = await updateProcedureData(procedureTypeId, procedureId, data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async deleteProcedureData({
      procedureId,
      procedureTypeId,
    }: {
      procedureTypeId: number;
      procedureId: number;
    }) {
      try {
        await deleteProcedureData(procedureTypeId, procedureId);
      } catch (error) {
        throw error;
      }
    },
    async getQuery({ query, parameters }: { query: QueryType; parameters: string }) {
      try {
        const response = await getQuery(query, parameters);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  }),
});

export default patients;
