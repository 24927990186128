import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Field } from '../atoms';
import Icons from '../../assets/icons';

type Props = {
  title: string;
  checked: boolean;
  setChecked: (arg: boolean) => void;
  disabled?: boolean;
  className?: string;
};

const CheckboxField: FC<Props> = ({ className, title, checked, setChecked, disabled }) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setChecked(disabled ? checked : !checked);
    }
  };

  return (
    <Wrapper
      disabled={disabled}
      className={className}
      onClick={() => setChecked(disabled ? checked : !checked)}
      tabIndex={0.9}
      onKeyDown={onKeyDown}
    >
      <CheckboxLogo src={checked ? Icons.CheckboxOn : Icons.CheckboxOff} />
      {title}
    </Wrapper>
  );
};

const Wrapper = styled(Field)<WrapperTyp>`
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.7;
  `}
`;

const CheckboxLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

type WrapperTyp = {
  disabled?: boolean;
};

export default CheckboxField;
