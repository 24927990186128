import React, { FC } from 'react';
import styled from '@emotion/styled';
import Modal from 'react-modal';
import Icons from '../../assets/icons';
import { Button as ButtonBase, Error } from '../atoms';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0',
    padding: '0',
    width: '752px',
  },
  overlay: {
    backgroundColor: 'rgba(44, 44, 44, 0.6)',
    zIndex: 2,
  },
};

type Props = {
  modalIsOpen: boolean;
  clearModal?: () => void;
  closeModal: () => void;
  saveInfo: () => void;
  title: string;
  error?: string[] | null;
  buttonTitle?: string;
  buttonDisabled?: boolean;
  onAfterOpen?: () => void;
  goBack?: () => void;
  children?: React.ReactNode;
  className?: string;
};

const ModalWindow: FC<Props> = ({
  className,
  modalIsOpen,
  closeModal,
  clearModal,
  saveInfo,
  title,
  error,
  buttonTitle = 'Save',
  buttonDisabled,
  onAfterOpen,
  goBack,
  children,
}) => {
  const saveButtonHandler = (event: React.FormEvent<HTMLFormElement>) => {
    if (!buttonDisabled) {
      event.preventDefault();
      saveInfo();
    }
  };

  return (
    <Wrapper className={className}>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onAfterOpen={onAfterOpen}
        style={customStyles}
      >
        <Form onSubmit={saveButtonHandler}>
          <TitleBlock>
            {goBack && <ArrowLeft src={Icons.ArrowLeft} onClick={goBack} />}
            <Title>{title}</Title>
            <Cross src={Icons.CrossToClose} onClick={closeModal} />
          </TitleBlock>

          <Content>{children}</Content>

          {!clearModal && (
            <ButtonBlock clear={!!clearModal}>
              <ErrorBlock>{error && <Error error={error} />}</ErrorBlock>

              <BigButton disabled={buttonDisabled} secondary type="submit">
                {buttonTitle}
              </BigButton>
            </ButtonBlock>
          )}

          {clearModal && (
            <ButtonBlock clear={!!clearModal}>
              <SmallButton confirm type="button" onClick={clearModal}>
                Clear
              </SmallButton>

              <ErrorBlock clear>{error && <Error error={error} />}</ErrorBlock>

              <SmallButton secondary type="submit" onClick={saveInfo}>
                Apply
              </SmallButton>
            </ButtonBlock>
          )}
        </Form>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Form = styled.form``;

const TitleBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 46px;
  background-color: var(--color-light);
`;

const Title = styled.div`
  color: var(--color-black);
  font-weight: 600;
`;

const ArrowLeft = styled.img`
  position: absolute;
  left: 0;
  cursor: pointer;
`;

const Cross = styled.img`
  position: absolute;
  right: 19px;

  width: 8px;
  height: 8px;

  cursor: pointer;
`;

const Content = styled.div`
  padding: 24px 0 40px;

  max-height: 752px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 2px;
  }
`;

const ButtonBlock = styled.div<ButtonBlockType>`
  display: flex;
  align-items: center;

  border-top: 1px solid var(--color-lines);

  ${({ clear }) =>
    clear &&
    `
    padding: 16px 24px;
    justify-content: space-between;
  `}

  ${({ clear }) =>
    !clear &&
    `
    padding: 16px 0;
    flex-direction: column;
    justify-content: center;
  `}
`;

const BigButton = styled(ButtonBase)`
  width: 340px;
`;

const SmallButton = styled(ButtonBase)`
  width: 120px;
`;

const ErrorBlock = styled.div<ButtonBlockType>`
  width: 340px;
  margin-bottom: 8px;

  ${({ clear }) =>
    clear &&
    `
    text-align: center;
  `}
`;

type ButtonBlockType = {
  clear?: boolean;
};

export default ModalWindow;
