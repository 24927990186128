import React, { FC, useMemo, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { PageWrapper, Table, Pagination } from '../features';
import { Heading, Button as ButtonBase, Header } from '../atoms';
import { ModalWindowConfirm, ModalWindowInput, Loader } from '../molecules';
import { url } from '../../router/routes';
import { useQuery } from '../../hooks';
import { ErrorType } from '../../types/general';
import { SwitchType } from '../../types/procedures';

const headerActions = {
  NAME: 'name',
  IS_ACTIVE: 'isActive',
  CHANGE_DATE: 'changeTime',
  CHANGE_USER: 'changeUser',
  CREATE_DATE: 'createTime',
  CREATE_USER: 'createUser',
  ACTIONS: 'actions',
};

const Procedures: FC = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch<Dispatch>();
  const procedures = useSelector((state: RootState) => state.procedures);
  const pageNumber = Number(query.get('page')) || 1;

  const [modalForDelete, setModalForDelete] = useState(false);
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [buttonIsActive, setButtonIsActive] = useState(false);

  const [currentFieldId, setCurrentFieldId] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [error, setError] = useState<ErrorType | null>(null);
  const [loading, setLoading] = useState(false);

  const [removalProcedures, setRemovalProcedures] = useState<number>(0);
  const [tableName, setTableName] = useState('');
  const [dataTable, setDataTable] = useState(procedures.data.results);

  const data = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(
    () => [
      {
        Header: 'Procedure type',
        accessor: headerActions.NAME,
      },
      {
        Header: 'IsActive',
        accessor: headerActions.IS_ACTIVE,
      },
      {
        Header: 'Change date',
        accessor: headerActions.CHANGE_DATE,
      },
      {
        Header: 'Change user',
        accessor: headerActions.CHANGE_USER,
      },
      {
        Header: 'Create date',
        accessor: headerActions.CREATE_DATE,
      },
      {
        Header: 'Create user',
        accessor: headerActions.CREATE_USER,
      },
      {
        Header: 'Actions',
        accessor: headerActions.ACTIONS,
      },
    ],
    []
  );

  const handleSaveInfo = () => {
    if (!buttonIsActive) {
      setButtonIsActive(true);

      dispatch.examination
        .addExamination(tableName)
        .then(() => {
          history.push(url.EXAMINATION);
        })
        .catch((e) => {
          if (e.response?.data) {
            setError(e.response.data);
          }
        })
        .finally(() => setButtonIsActive(false));
    }
  };

  const handleAction = (action: string) => (id: number) => {
    if (action === 'edit') {
      history.push(`${url.EXAMINATION}/?page=1&id=${id}`);
    } else if (action === 'delete') {
      dispatch.patients
        .getProceduresData(id)
        .then((response) => {
          setRemovalProcedures(response.count);
        })
        .catch(() => setError({ removalProcedures: ['an unknown number of'] }));

      setSelectedId(id);
      setModalForDelete(true);
    }
  };

  const handleModalDelete = () => {
    if (selectedId) {
      dispatch.examination.deleteExamination(selectedId);
    }
  };

  const dragAndDropField = (hoverFieldId: number) => {
    if (currentFieldId && hoverFieldId) {
      const switchFields: SwitchType = {
        object1Id: currentFieldId,
        object2Id: hoverFieldId,
      };

      dispatch.procedures.switchExamination(switchFields);
    }
  };

  const handlePageClick = (selectedPage: number) => {
    dispatch.procedures.setQuery({ ...procedures.query, pageNumber: selectedPage });
    dispatch.procedures.getTable();

    history.push(`${url.PROCEDURES}/?page=${selectedPage}`);
  };

  useEffect(() => {
    setLoading(true);

    dispatch.procedures.setQuery({ ...procedures.query, pageNumber });
    dispatch.procedures.getTable().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setDataTable(procedures.data.results);
  }, [procedures]);

  return (
    <PageWrapper>
      <Header>
        <Heading
          path={`${url.PROCEDURES}/?page=1`}
          onClick={() => {
            dispatch.procedures.setClearProcedures();
            dispatch.procedures.getTable();
          }}
        >
          Procedures
        </Heading>

        <Button onClick={() => setIsModalOpen(true)}>Add examination table</Button>
      </Header>

      {loading ? (
        <Loader />
      ) : (
        <>
          <TableWrapper>
            <Table
              data={data}
              columns={columns}
              handleEdit={handleAction('edit')}
              handleDelete={handleAction('delete')}
              setCurrentFieldId={setCurrentFieldId}
              setHoverFieldId={dragAndDropField}
              dragAndDrop
            />
          </TableWrapper>

          <Pagination
            dataTable={procedures.data}
            pageSize={procedures.query.pageSize}
            onPageChange={handlePageClick}
            activePage={pageNumber}
          />
        </>
      )}

      <ModalWindowInput
        title="Add examination table"
        saveInfo={handleSaveInfo}
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsModalOpen(false)}
        inputValue={tableName}
        inputOnChange={setTableName}
        inputRequired
        placeholder="Name"
        error={error?.name}
        buttonDisabled={buttonIsActive}
      />

      <ModalWindowConfirm
        title="Delete table"
        modalIsOpen={modalForDelete}
        saveInfo={handleModalDelete}
        closeModal={() => {
          setModalForDelete(false);
          setRemovalProcedures(0);
          setError(null);
        }}
      >
        Are you sure you want to delete this table and{' '}
        {error?.removalProcedures[0] || removalProcedures} procedures?
      </ModalWindowConfirm>
    </PageWrapper>
  );
};

const Button = styled(ButtonBase)`
  width: 186px;
`;

const TableWrapper = styled.div`
  margin-right: 200px;
`;

export default Procedures;
