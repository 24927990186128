import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notifitationVariants = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const notify = (
  text: string,
  status: string = notifitationVariants.SUCCESS
): React.ReactText =>
  status === notifitationVariants.SUCCESS ? toast.success(text) : toast.error(text);

export default ToastContainer;
