import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PageWrapper } from '../features';

const Page404: FC = () => {
  return (
    <PageWrapper>
      <Content>
        <Error>404</Error>
        <Title>page is not found</Title>
      </Content>
    </PageWrapper>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
`;

const Error = styled.div`
  margin-bottom: 44px;
  font-size: 148px;
`;

const Title = styled.div`
  font-size: 32;
`;

export default Page404;
