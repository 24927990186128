import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { url } from '../../router/routes';
import { SidebarButton } from '../atoms';
import { userRoles } from '../pages/Profile';

const Sidebar: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user.data?.group);

  const admin = user === userRoles.admin;

  const createNavClickHandler = (path: string) => () => history.push(path);
  const checkIsCurrentPage = (path: string) =>
    location.pathname.split('/')[1] === path.split('/')[1];

  return (
    <Wrapper>
      <TopBar>
        <SidebarButton
          onClick={createNavClickHandler(url.LANDINGPAGE)}
          isCurrent={checkIsCurrentPage(url.LANDINGPAGE)}
          title="Landing page"
        />

        <SidebarButton
          onClick={createNavClickHandler(url.PATIENTS)}
          isCurrent={checkIsCurrentPage(url.PATIENTS)}
          title="Patients"
        />

        {admin && (
          <>
            <SidebarButton
              onClick={createNavClickHandler(url.PHYSICIANS)}
              isCurrent={checkIsCurrentPage(url.PHYSICIANS)}
              title="Physicians"
            />
            <SidebarButton
              onClick={createNavClickHandler(url.PROCEDURES)}
              isCurrent={checkIsCurrentPage(url.PROCEDURES)}
              title="Procedures"
            />
            <SidebarButton
              onClick={createNavClickHandler(url.STUDIES)}
              isCurrent={checkIsCurrentPage(url.STUDIES)}
              title="Studies"
            />
            <SidebarButton
              onClick={createNavClickHandler(url.USERS)}
              isCurrent={checkIsCurrentPage(url.USERS)}
              title="Users"
            />
          </>
        )}
      </TopBar>

      <BottomBar>
        <SidebarButton
          onClick={createNavClickHandler(url.PROFILE)}
          isCurrent={checkIsCurrentPage(url.PROFILE)}
          title="Profile"
        />
        <SidebarButton
          onClick={createNavClickHandler(url.LOGOUT)}
          isCurrent={checkIsCurrentPage(url.LOGOUT)}
          title="Logout"
        />
      </BottomBar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;

  width: 79px;
  height: 100vh;

  border-right: 1px solid var(--color-lines);
  background-color: var(--color-white);
  z-index: 1;
`;

const TopBar = styled.div``;

const BottomBar = styled.div``;

export default Sidebar;
