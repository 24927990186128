import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ExaminationFieldType } from '../../types/procedures';
import { Input, Select } from '.';
import { conversionOptions } from '../organisms/ModalExaminationTable';
import { ProcedureDataType } from '../../types/patients';
import { ErrorType } from '../../types/general';
import { userRoles } from '../pages/Profile';

type Props = {
  fields: ExaminationFieldType[];
  procedureData: ProcedureDataType;
  dataInput: (name: string, value: string, required: boolean) => void;
  procedureErrors?: ErrorType | null;
};

const ProcedureDataForm: FC<Props> = ({ fields, procedureData, dataInput, procedureErrors }) => {
  const user = useSelector((state: RootState) => state.user.data?.group);
  const viewer = user === userRoles.viewer;

  return (
    <>
      {fields.map((field) => {
        switch (field.dataType) {
          case conversionOptions.String: {
            return (
              <Input
                key={field.name}
                value={procedureData[field.name] || ''}
                onChange={(value) => {
                  dataInput(field.name, value, !field.null);
                }}
                placeholder={field.label}
                required={!field.null}
                additionalInfo={field.helpText}
                errorBorder={!!procedureErrors?.[field.name]}
                readOnly={viewer}
              />
            );
          }

          case conversionOptions.Decimal: {
            return (
              <Input
                key={field.name}
                value={procedureData[field.name] || ''}
                onChange={(value) => {
                  dataInput(field.name, value, !field.null);
                }}
                placeholder={field.label}
                required={!field.null}
                additionalInfo={field.helpText}
                errorBorder={!!procedureErrors?.[field.name]}
                readOnly={viewer}
              />
            );
          }

          case conversionOptions.Int: {
            return (
              <Input
                key={field.name}
                value={procedureData[field.name] || ''}
                onChange={(value) => {
                  dataInput(field.name, value, !field.null);
                }}
                placeholder={field.label}
                required={!field.null}
                additionalInfo={field.helpText}
                errorBorder={!!procedureErrors?.[field.name]}
                readOnly={viewer}
              />
            );
          }

          case conversionOptions.DateTime: {
            return (
              <Input
                key={field.name}
                value={procedureData[field.name] || ''}
                onChange={(value) => {
                  dataInput(field.name, value, !field.null);
                }}
                placeholder={field.label}
                required={!field.null}
                additionalInfo={field.helpText}
                errorBorder={!!procedureErrors?.[field.name]}
                readOnly={viewer}
              />
            );
          }

          case conversionOptions.Date: {
            return (
              <Input
                key={field.name}
                value={procedureData[field.name] || ''}
                onChange={(value) => {
                  dataInput(field.name, value, !field.null);
                }}
                placeholder={field.label}
                required={!field.null}
                additionalInfo={field.helpText}
                errorBorder={!!procedureErrors?.[field.name]}
                readOnly={viewer}
              />
            );
          }

          case conversionOptions.Time: {
            return (
              <Input
                key={field.name}
                value={procedureData[field.name] || ''}
                onChange={(value) => {
                  dataInput(field.name, value, !field.null);
                }}
                placeholder={field.label}
                required={!field.null}
                additionalInfo={field.helpText}
                errorBorder={!!procedureErrors?.[field.name]}
                readOnly={viewer}
              />
            );
          }

          case conversionOptions.Bool: {
            const convertFromBooleanToString = procedureData[field.name] ? 'True' : 'False';
            const fieldIsEmpty = procedureData[field.name] === '';
            const fieldIsBoolean =
              typeof procedureData[field.name] === 'boolean'
                ? convertFromBooleanToString
                : procedureData[field.name];

            const result = fieldIsEmpty ? '' : fieldIsBoolean;

            return (
              <Select
                key={field.name}
                value={result || ''}
                onChange={(value) => {
                  dataInput(field.name, value, !field.null);
                }}
                placeholder={field.label}
                options={['True', 'False']}
                required={!field.null}
                additionalInfo={field.helpText}
                errorBorder={!!procedureErrors?.[field.name]}
                readOnly={viewer}
              />
            );
          }

          case conversionOptions['Selection list']: {
            return (
              <Select
                key={field.name}
                value={procedureData[field.name] || ''}
                onChange={(value) => {
                  dataInput(field.name, value, !field.null);
                }}
                placeholder={field.label}
                options={field.choices || []}
                required={!field.null}
                additionalInfo={field.helpText}
                errorBorder={!!procedureErrors?.[field.name]}
                readOnly={viewer}
              />
            );
          }

          default:
            return '';
        }
      })}
    </>
  );
};

export default ProcedureDataForm;
