import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { PageWrapper, Table, Pagination } from '../features';
import { Header, Heading, Button as ButtonBase } from '../atoms';
import { url } from '../../router/routes';
import { PaginationResponseType, QueryType } from '../../types/general';
import { Loader, ModalWindowConfirm } from '../molecules';
import { UserType } from '../../types/user';
import { useQuery } from '../../hooks';
import { ModalUser, ModalResetPassword } from '../organisms';

const headerActions = {
  NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  ROLE: 'group',
  ACTIONS: 'actions',
};

const tableActions = {
  EDIT: 'edit',
  DELETE: 'delete',
};

const modals = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  RESET_PASSWORD: 'resetPassword',
};

const Users: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const pageNumber = Number(query.get('page')) || 1;
  const pageSize = 20;
  const queryData = {
    pageNumber,
    pageSize,
  };

  const [loading, setLoading] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const [dataTable, setDataTable] = useState<UserType[]>([]);
  const [pagination, setPagination] = useState<PaginationResponseType<UserType>>({
    count: 0,
    results: [],
  });

  const data = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: headerActions.NAME,
      },
      {
        Header: 'Last name',
        accessor: headerActions.LAST_NAME,
      },
      {
        Header: 'Email',
        accessor: headerActions.EMAIL,
      },
      {
        Header: 'Role',
        accessor: headerActions.ROLE,
      },
      {
        Header: 'Actions',
        accessor: headerActions.ACTIONS,
      },
    ],
    []
  );

  const loadData = (queryParameters: QueryType) => {
    dispatch.user.getUsers(queryParameters).then((response) => {
      setDataTable(response.results);
      setPagination(response);
    });
  };

  const handleCloseModal = () => {
    setActiveModal('');
    setSelectedUserId(null);
  };

  const handleModalDelete = () => {
    if (selectedUserId) {
      dispatch.user.deleteUser(selectedUserId).then(() => loadData(queryData));
    }
  };

  const handleAction = (action: string) => (id: number) => {
    setSelectedUserId(id);

    if (action === tableActions.EDIT) {
      setActiveModal(modals.EDIT);
    } else if (action === tableActions.DELETE) {
      setActiveModal(modals.DELETE);
    }
  };

  const handlePageClick = (selectedPage: number) => {
    const updatedQueryData = {
      pageNumber: selectedPage,
      pageSize,
    };

    history.push(`${url.USERS}/?page=${selectedPage}`);
    loadData(updatedQueryData);
  };

  useEffect(() => {
    setLoading(true);

    dispatch.user
      .getUsers(queryData)
      .then((response) => {
        setDataTable(response.results);
        setPagination(response);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <PageWrapper>
      <Header>
        <Heading path={`${url.USERS}/?page=1`} onClick={() => loadData(queryData)}>
          Users
        </Heading>

        <Button onClick={() => setActiveModal(modals.ADD)}>Add user</Button>
      </Header>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            data={data}
            columns={columns}
            handleEdit={handleAction(tableActions.EDIT)}
            handleDelete={handleAction(tableActions.DELETE)}
          />

          <Pagination
            dataTable={pagination}
            activePage={pageNumber}
            pageSize={pageSize}
            onPageChange={handlePageClick}
          />
        </>
      )}

      <ModalResetPassword
        title="Reset password"
        modalIsOpen={activeModal === modals.RESET_PASSWORD}
        closeModal={handleCloseModal}
        userId={selectedUserId}
        goBack={() => setActiveModal(modals.EDIT)}
      />

      <ModalUser
        title="Add user"
        modalIsOpen={activeModal === modals.ADD}
        closeModal={handleCloseModal}
        updateTable={() => loadData(queryData)}
      />

      <ModalUser
        title="Edit user"
        modalIsOpen={activeModal === modals.EDIT}
        closeModal={handleCloseModal}
        updateTable={() => loadData(queryData)}
        openModalResetPassword={() => setActiveModal(modals.RESET_PASSWORD)}
        userId={selectedUserId}
      />

      <ModalWindowConfirm
        title="Delete user"
        modalIsOpen={activeModal === modals.DELETE}
        closeModal={handleCloseModal}
        saveInfo={handleModalDelete}
      >
        Are you sure you want to delete this user ?
      </ModalWindowConfirm>
    </PageWrapper>
  );
};

const Button = styled(ButtonBase)`
  width: 103px;
  height: 40px;
`;

export default Users;
