import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button as ButtonBase } from '../atoms';

type Props = {
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  placeholder?: string;
  className?: string;
};

const Search: FC<Props> = ({ className, value, onChange, onClick, placeholder }) => {
  return (
    <Wrapper className={className}>
      <Input placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />

      <Button secondary onClick={onClick}>
        Search
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const Input = styled.input`
  height: 40px;
  padding: 0 106px 0 16px;
  width: 100%;
  outline: none;

  &::placeholder {
    color: var(--color-grey);
  }
`;

const Button = styled(ButtonBase)`
  position: absolute;
  right: 0;
  top: 1px;
  width: 90px;
  height: 38px;

  &:active {
    border-right: 1px solid var(--color-black);
  }
`;

export default Search;
