import React, { FC } from 'react';
import styled from '@emotion/styled';
import Icons from '../../assets/icons';

type Props = {
  title: string;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  children?: React.ReactNode;
  className?: string;
};

const FieldsTab: FC<Props> = ({ className, children, title, isOpen, setIsOpen }) => {
  return (
    <Wrapper>
      <TitleWrapper className={className}>
        <Title onClick={() => setIsOpen(!isOpen)}>
          {title}
          <Arrow src={isOpen ? Icons.ArrowUp : Icons.ArrowDown} />
        </Title>
      </TitleWrapper>

      {isOpen && children}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 48px;

  color: var(--color-black);
  background-color: var(--color-light);
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 340px;

  cursor: pointer;
`;

const Arrow = styled.img``;

export default FieldsTab;
