import React, { FC, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { PageWrapper, Table, Pagination } from '../features';
import { Title, Icon, Header } from '../atoms';
import { useQuery } from '../../hooks';
import Icons from '../../assets/icons';
import { url } from '../../router/routes';
import { Search as SearchBase, ModalWindowConfirm, Loader } from '../molecules';
import { PatientType, ProcedureSearchDataType } from '../../types/patients';
import { PaginationResponseType, QueryType } from '../../types/general';

const patientActions = {
  PATIENT_ID: 'id',
  DATE_OF_BIRTH: 'bornAt',
  CHANGE_DATE: 'changeTime',
  CHANGE_USER: 'changeUser',
  ACTIONS: 'actions',
};

const procedureActions = {
  DATE: 'date',
  PATIENT_ID: 'patientId',
  PHYSICIAN: 'physician',
  PROCEDURE_ID: 'procedureId',
  SEARCH_RESULT: 'searchResult',
};

const tabs = {
  PATIENT: 'patient',
  PROCEDURE: 'procedure',
};

const Search: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const patients = useSelector((state: RootState) => state.patients);

  const previousPage = query.get('prev_page');
  const pageTab = query.get('tab') || tabs.PATIENT;
  const pageSearch = query.get('search') || '';
  const pageNumber = Number(query.get('page')) || 1;
  const pageSize = 20;

  const queryData = {
    pageNumber,
    pageSize,
    search: pageSearch,
  };

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [idOfSelectedPatient, setIdOfSelectedPatient] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState(pageSearch);
  const [procedureData, setProcedureData] = useState<ProcedureSearchDataType[]>([]);
  const [patientData, setPatientData] = useState<PatientType[]>([]);
  const [procedurePagination, setProcedurePagination] = useState<PaginationResponseType<Object>>({
    count: 0,
    results: [],
  });
  const [patientPagination, setPatientPagination] = useState<PaginationResponseType<Object>>({
    count: 0,
    results: [],
  });

  const patientDataTable = useMemo(() => patientData, [patientData]);
  const patientColumnsTable = useMemo(
    () => [
      {
        Header: 'Patient ID',
        accessor: patientActions.PATIENT_ID,
      },
      {
        Header: 'Date of birth ',
        accessor: patientActions.DATE_OF_BIRTH,
      },
      {
        Header: 'Change date',
        accessor: patientActions.CHANGE_DATE,
      },
      {
        Header: 'Change user',
        accessor: patientActions.CHANGE_USER,
      },
      {
        Header: 'Actions',
        accessor: patientActions.ACTIONS,
      },
    ],
    []
  );

  const procedureDataTable = useMemo(() => procedureData, [procedureData]);
  const procedureColumnsTable = useMemo(
    () => [
      {
        Header: 'Search results',
        accessor: procedureActions.SEARCH_RESULT,
      },
      {
        Header: 'Procedure ID',
        accessor: procedureActions.PROCEDURE_ID,
      },
      {
        Header: 'Date',
        accessor: procedureActions.DATE,
      },
      {
        Header: 'Physician',
        accessor: procedureActions.PHYSICIAN,
      },
      {
        Header: 'Patient ID',
        accessor: procedureActions.PATIENT_ID,
      },
    ],
    []
  );

  const goBack = () => {
    if (previousPage === 'patients') {
      history.push(`${url.PATIENTS}`);
    } else {
      history.push(`${url.LANDINGPAGE}`);
    }
  };

  const handleSearch = () => {
    if (search !== '') {
      const updatedQuery = {
        ...queryData,
        search,
      };

      history.push(
        `${url.SEARCH}/?page=1&prev_page=${previousPage}&tab=${pageTab}&search=${search}`
      );
      loadPatients(updatedQuery);
      loadProcedures(updatedQuery);
    }
  };

  const handleTabClick = (tab: string) => {
    const initialQuery = {
      ...queryData,
      pageNumber: 1,
    };

    if (tab === tabs.PATIENT) {
      history.push(
        `${url.SEARCH}/?page=1&prev_page=${previousPage}&tab=${tabs.PATIENT}&search=${pageSearch}`
      );

      loadPatients(initialQuery);
    }

    if (tab === tabs.PROCEDURE) {
      history.push(
        `${url.SEARCH}/?page=1&prev_page=${previousPage}&tab=${tabs.PROCEDURE}&search=${pageSearch}`
      );

      loadProcedures(initialQuery);
    }
  };

  const handlePageClick = (tab: string) => (selectedPage: number) => {
    const updatedQuery = {
      ...queryData,
      pageNumber: selectedPage,
    };

    if (tab === tabs.PATIENT) {
      history.push(
        `${url.SEARCH}/?page=${selectedPage}&prev_page=${previousPage}&tab=${tabs.PATIENT}&search=${pageSearch}`
      );

      loadPatients(updatedQuery);
    }

    if (tab === tabs.PROCEDURE) {
      history.push(
        `${url.SEARCH}/?page=${selectedPage}&prev_page=${previousPage}&tab=${tabs.PROCEDURE}&search=${pageSearch}`
      );

      loadProcedures(updatedQuery);
    }
  };

  const handleAction = (action: string) => (id: number) => {
    if (action === 'edit') {
      history.push(`${url.PATIENT}/?page=1&id=${id}`);
    } else if (action === 'delete') {
      setIdOfSelectedPatient(id);
      setIsOpenModalDelete(true);
    }
  };

  const handleModalDelete = () => {
    if (idOfSelectedPatient) {
      dispatch.patients.deletePatient(idOfSelectedPatient).then(() => loadPatients(queryData));
    }
  };

  const loadPatients = (patientQuery: QueryType) => {
    if (patientData.length === 0) {
      setLoading(true);
    }

    dispatch.patients.setQuery(patientQuery);
    dispatch.patients.searchPatient().finally(() => setLoading(false));
  };

  const loadProcedures = (procedureQuery: QueryType) => {
    if (procedureData.length === 0) {
      setLoading(true);
    }

    dispatch.patients
      .searchProcedure(procedureQuery)
      .then((response) => {
        setProcedureData(response.results);
        setProcedurePagination(response);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setPatientData(patients.data.results);
    setPatientPagination(patients.data);
  }, [patients]);

  useEffect(() => {
    loadPatients(queryData);
    loadProcedures(queryData);
  }, []);

  return (
    <PageWrapper>
      <Header>
        <TitleWrapper onClick={goBack}>
          <Arrow src={Icons.ArrowLeftWithoutCircle} />
          <Title>Search results: {pageSearch}</Title>
        </TitleWrapper>

        <SearchResults value={search} onChange={setSearch} onClick={handleSearch} />
      </Header>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs>
            <Tab active={pageTab === tabs.PATIENT} onClick={() => handleTabClick(tabs.PATIENT)}>
              Patients <Count>{patientPagination.count}</Count>
            </Tab>

            <Tab active={pageTab === tabs.PROCEDURE} onClick={() => handleTabClick(tabs.PROCEDURE)}>
              Procedures <Count>{procedurePagination.count}</Count>
            </Tab>
          </Tabs>

          {pageTab === tabs.PATIENT && (
            <>
              <Table
                data={patientDataTable}
                columns={patientColumnsTable}
                handleEdit={handleAction('edit')}
                handleDelete={handleAction('delete')}
              />

              <Pagination
                dataTable={patientPagination}
                pageSize={queryData.pageSize}
                activePage={queryData.pageNumber}
                onPageChange={handlePageClick(tabs.PATIENT)}
              />
            </>
          )}

          {pageTab === tabs.PROCEDURE && (
            <>
              <Table
                data={procedureDataTable}
                columns={procedureColumnsTable}
                search={pageSearch}
              />

              <Pagination
                dataTable={procedurePagination}
                pageSize={queryData.pageSize}
                activePage={queryData.pageNumber}
                onPageChange={handlePageClick(tabs.PROCEDURE)}
              />
            </>
          )}
        </>
      )}

      <ModalWindowConfirm
        title="Delete patient"
        modalIsOpen={isOpenModalDelete}
        closeModal={() => setIsOpenModalDelete(false)}
        saveInfo={handleModalDelete}
      >
        Are you sure you want to delete these patient data and all related procedure data
      </ModalWindowConfirm>
    </PageWrapper>
  );
};

const SearchResults = styled(SearchBase)`
  width: 340px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const Tab = styled.div<TabType>`
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-right: 20px;
  }

  ${({ active }) =>
    active &&
    `
    text-decoration: underline;
  `}

  cursor: pointer;
`;

const Arrow = styled(Icon)`
  position: relative;
  top: 1px;
  margin-right: 10px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  width: 50px;
  height: 25px;

  color: var(--color-white);
  background-color: var(--color-black);
`;

type TabType = {
  active: boolean;
};

export default Search;
