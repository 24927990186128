import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Icons from '../../assets/icons';

type Props = {
  localFiles: FormData;
  setLocalFiles: (arg: FormData | null) => void;
};

type FileType = {
  name: string;
  size: number;
  type: string;
};

const getData = (formData: FormData): FileType[] => {
  // @ts-ignore
  return formData.getAll('file');
};

const LocalFiles: FC<Props> = ({ localFiles, setLocalFiles }) => {
  const [data, setData] = useState<FileType[]>(getData(localFiles));

  const allNamesInOneLine = data.reduce((acc, item) => `${acc + item.name}, `, '').slice(0, -2);

  const handleClickDelete = () => {
    setLocalFiles(null);
  };

  useEffect(() => {
    setData(getData(localFiles));
  }, [localFiles]);

  return (
    <Wrapper>
      <File>
        {allNamesInOneLine}
        <Cross src={Icons.CrossToClose} onClick={handleClickDelete} />
      </File>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 16px;
  min-height: 24px;

  background-color: var(--color-light);
  color: var(--color-dark);
`;

const Cross = styled.img`
  cursor: pointer;
`;

export default LocalFiles;
